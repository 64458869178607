<template>
    <div class="betting-limit">
        <div class="betting-limit-header">
            <div class="betting-limit-title">
                <p>{{ $t('global.common.player_bet_limit') }}</p>
                <a-button v-if="data.editable && updatePermission" type="primary" icon="edit" @click="showModal">
                    {{ $t('global.action.edit') }}
                </a-button>
            </div>
            <div class="betting-limit-subtitle">{{ $t('global.message.setting_player_bet_max') }}</div>
        </div>
        <div class="betting-limit-content">
            <table>
                <thead>
                    <tr>
                        <td></td>
                        <td>{{ $t('global.common.min') }}</td>
                        <td>{{ $t('global.common.max') }}</td>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item,key) in betLimit">
                      <tr :key="key" v-if="key !== 'draw'">
                        <td>
                          <span :class="getCurrentSide('meron')">{{ $t(`global.fight_result.${getCurrentSide('meron')}`) }}</span>
                          <span> / </span>
                          <span :class="getCurrentSide('wala')">{{ $t(`global.fight_result.${getCurrentSide('wala')}`) }}</span>
                        </td>
                        <td>{{ item.per_round.min | formatNumber }}</td>
                        <td>{{ item.per_bet | formatNumber }}</td>
                      </tr>
                      <tr v-else>
                        <template v-if="data.game_type === 'apple'">
                          <td>
                            <span :class="getCurrentSide('red3')">{{ $t(`global.fight_result.${getCurrentSide('red3')}`) }}</span>
                            <span> / </span>
                            <span :class="getCurrentSide('white3')">{{ $t(`global.fight_result.${getCurrentSide('white3')}`) }}</span>
                          </td>
                        </template>
                        <template v-else>
                          <td :class="getCurrentSide('draw')">{{ $t(`global.fight_result.${getCurrentSide('draw')}`) }}</td>
                        </template>
                        <td>{{ item.per_round.min | formatNumber }}</td>
                        <td>{{ item.per_bet | formatNumber }}</td>
                      </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <a-modal v-model="visible" :title="$t('global.common.player_bet_limit')" :footer="null" width='480px' class="betting-limit-dialog">
            <a-form-model-item prop="content">
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td>{{ $t('global.common.min') }}</td>
                            <td></td>
                            <td>{{ $t('global.common.max') }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                              <span :class="getCurrentSide('meron')">{{ $t(`global.fight_result.${getCurrentSide('meron')}`) }}</span>
                              <span> / </span>
                              <span :class="getCurrentSide('wala')">{{ $t(`global.fight_result.${getCurrentSide('wala')}`) }}</span>
                            </td>
                            <td><a-input-number v-model="perBetLimit.meron_wala.min" /></td>
                            <td>-</td>
                            <td><a-input-number v-model="perBetLimit.meron_wala.per_bet" /></td>
                        </tr>
                        <tr>
                          <template v-if="data.game_type === 'apple'">
                            <td>
                              <span :class="getCurrentSide('red3')">{{ $t(`global.fight_result.${getCurrentSide('red3')}`) }}</span>
                              <span> / </span>
                              <span :class="getCurrentSide('white3')">{{ $t(`global.fight_result.${getCurrentSide('white3')}`) }}</span>
                            </td>
                          </template>
                          <template v-else>
                            <td :class="getCurrentSide('draw')">{{ $t(`global.fight_result.${getCurrentSide('draw')}`) }}</td>
                          </template>
                            <td><a-input-number v-model="perBetLimit.draw.min" /></td>
                            <td>-</td>
                            <td><a-input-number v-model="perBetLimit.draw.per_bet" /></td>
                        </tr>
                    </tbody>
                </table>
            </a-form-model-item>
            <a-form-model-item>
                <a-button type="primary" @click="editLimit" block>
                {{ $t('global.action.edit') }}
                </a-button>
            </a-form-model-item>
        </a-modal>
    </div>
</template>
<script>
import { GAME_TYPE_SIDE } from '@/../tools/config';

export default {
  props: {
    betLimit: Object,
    updateLimit: Function,
    data: Object,
    updatePermission: Boolean
  },
  data() {
    return {
      GAME_TYPE_SIDE,
      visible: false,
      perBetLimit: {
        meron_wala: {
          per_bet: null,
          min: null
        },
        draw: {
          per_bet: null,
          min: null
        },
      },
      editable: false
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal(){
      this.visible = false;
    },
    editLimit() {
      this.updateLimit('bet_limit',this.perBetLimit, this.closeModal);
    },
    getCurrentSide(side){
      return this.GAME_TYPE_SIDE[this.data.game_type][side];
    },
  },
  watch:{
    betLimit:{
      handler(newValue, oldValue){
        if(newValue){
          const { meron_wala, draw } = newValue;
          this.perBetLimit.meron_wala.per_bet = meron_wala.per_bet;
          this.perBetLimit.draw.per_bet = draw.per_bet;
          this.perBetLimit.meron_wala.min = meron_wala.per_round.min;
          this.perBetLimit.draw.min = draw.per_round.min;
        }
      },
      deep: true
    }
  },
};
</script>
