<template>
  <div class="frame transfer-page">
    <a-page-header
        :title="$t('global.menu.Transfer')"
        @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <div class="search-group">
            <a-form-model-item ref="type" :label="$t('global.common.type')" prop="type">
              <a-select
                  mode="multiple"
                  placeholder=""
                  :default-value="[]"
                  v-model="form.type"
              >
                <a-select-option v-for="type in TransfersType" :value="type" :key="type">
                  {{ $t(`global.common.bet_type_list.${type}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="status" :label="$t('global.common.status')" prop="status">
              <a-select
                  mode="multiple"
                  placeholder=""
                  :default-value="[]"
                  v-model="form.status"
              >
                <a-select-option v-for="status in TransfersStatus" :value="status" :key="status">
                  {{ $t(`global.common.order_status_list.${status}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item ref="account" :label="$t('global.common.account')" prop="account">
              <a-input
                  v-model="form.account"
              />
            </a-form-model-item>

            <a-form-model-item ref="bet_num" :label="$t('TransferPage.bet_num')" prop="bet_num">
              <a-input
                  v-model="form.bet_num"
              />
            </a-form-model-item>
          </div>
          <div class="search-group">
            <Datetime :input.sync="form.date"
                      :periodDefault.sync="periodDefault"/>
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>

      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control">
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox class="table-filter-check"
                              v-for="(item, index) in columns"
                              :key="item.dataIndex"
                              :defaultChecked="item.filterType"
                              :disabled="item.disabledFilter"
                              @click="columnsChange(index, item)"
                              :value="item.dataIndex">
                    {{ item.singleI18n ? $t(`TransferPage.${item.slots.title}`) : $t(`global.common.${item.slots.title}`) }}
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>
          <a-table
              bordered
              size="small"
              :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
              :data-source="results"
              :pagination="pagination"
              :loading="loading"
              :row-key="results => results.id"
              @change="handleTableChange"
          >
            <template v-for="(column,index) in columns">
              <span :slot="column.slots.title" :key="index">{{ column.singleI18n ? $t(`TransferPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</span>
            </template>

            <template slot="userData" slot-scope="data">
              <div v-for="dataItem in data" :key="dataItem.num">
                <span>{{$t('TransferPage.num')}}：{{dataItem.num | formatString}}</span>
                <span>, </span>
                <span>{{$t('global.common.amount')}}：{{dataItem.amount | formatString}}</span>
                <span>, </span>
                <span>{{$t('global.common.user')}}：{{dataItem.player | formatString}}</span>
              </div>
            </template>

            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>

            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>

            <template slot="slug" slot-scope="slug, row">
              <div v-if="row.status === 'fail' || row.status === 'error' || row.status === 'success'" class="slug" @click="showModal(slug)">{{ slug | formatString }}</div>
              <div v-else>{{ slug | formatString }}</div>
            </template>

            <template slot="action" slot-scope="id, row">
              <a-button type="primary" :loading="row.resendLoading" @click="sendTransfer(row)" v-if="row.showButton && actionBtn" :disabled="row.resendLoading">
                <!-- <a-icon v-if="row.resendLoading" type="loading" /> -->
                <span>{{$t('TransferPage.resend')}}</span>
              </a-button>
            </template>

            <template slot="resend_mark" slot-scope="id, row">
              <a-button type="primary" :loading="row.resendLoading" @click="sendNotResendMark(row)" v-if="row.showButton && actionBtn" :disabled="row.resendLoading">
                <!-- <a-icon v-if="row.resendLoading" type="loading" /> -->
                <span>{{$t('TransferPage.resend_mark')}}</span>
              </a-button>
              <div class="table-status" data-status="success" v-if="row.showNote">
                <a-icon type="check-circle" theme="filled" />
              </div>
            </template>

            <template slot="resend_note" slot-scope="id, row">
              <a-textarea v-model="row.resend_note" :placeholder="$t('TransferPage.resend_mark')" :rows="3" :disabled="row.resendLoading" v-if="row.showButton && actionBtn" :ref="`markRef-${row.id}`"/>
              <div v-if="row.showNote">{{row.resend_note}}</div>
            </template>
          </a-table>
          <TransferLog :modalVisible="modalVisible" 
                       :currentSlug="currentSlug" 
                       :closeModal="closeModal"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTransfer, resendTransfer, getMerchantRole, transferMark } from '@/../api';
import Datetime from '@/../src/components/datetime_picker';
import TransferLog from '@/../src/components/records/transfer_log';
import moment from "moment";

const columns = [
  {
    slots: { title: 'id' },
    dataIndex: 'id',
    filterType: true,
    disabledFilter: true,
  },
  {
    slots: { title: 'type' },
    dataIndex: 'type',
    filterType: true,
  },
  {
    slots: { title: 'status' },
    dataIndex: 'status',
    filterType: true,
  },
  {
    slots: { title: 'data' },
    dataIndex: 'data',
    scopedSlots: { customRender: 'userData' },
    width: 250,
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'slug' },
    dataIndex: 'slug',
    scopedSlots: { customRender: 'slug' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'res' },
    dataIndex: 'res',
    scopedSlots: { customRender: 'formatString' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'round_id' },
    dataIndex: 'round_id',
    scopedSlots: { customRender: 'formatString' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'setting_master_id' },
    dataIndex: 'setting_master_id',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'created_at' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
  {
    slots: { title: 'updated_at' },
    dataIndex: 'updated_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
  {
    slots: { title: 'action' },
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    filterType: true,
    align: 'center',
    width: '100px',
  },
  {
    slots: { title: 'resend_mark' },
    dataIndex: 'resend_mark',
    scopedSlots: { customRender: 'resend_mark' },
    filterType: true,
    align: 'center',
    width: '85px',
    singleI18n: true,
  },
  {
    slots: { title: 'resend_note' },
    dataIndex: 'resend_note',
    scopedSlots: { customRender: 'resend_note' },
    filterType: true,
    width: 300,
    singleI18n: true,
  },
];

export default {
  components: {
    Datetime,
    TransferLog
  },
  data() {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      periodDefault: 'today',
      expand: false,
      loading: false,
      columns,
      form: {
        date: [new Date(), new Date()],
        status: [],
        type: [],
        bet_num: '',
        account: ''
      },
      rules: {
      },
      results: [],
      TransfersStatus: [
        'init',
        'process',
        'success',
        'pending',
        'fail',
        'error'
      ],
      actionBtn: false,
      TransfersType: [
        'bet',
        'settle',
        'cancel',
        'resettle',
      ],
      errorRes : [],
      modalVisible: false,
      currentSlug: ''
    };
  },
  mounted() {
    getMerchantRole()
        .then((data)=>{
          this.actionBtn = data.data.role.Transfer.edit || false;
        })
  },
  methods: {
    sendNotResendMark(row){
      const { id, resend_note } = row;
      //判斷resend_note是否有值且不能只有空白
      if (!resend_note || (typeof(resend_note) === 'string' && resend_note.trim().length === 0)) {
        const ref = `markRef-${id}`;
        this.$refs[ref].focus();
        return false;
      }
      row.resendLoading = true;
      transferMark({id, note: resend_note})
          .then((data) => {
            const transfer_id = data.data.data.id;
            const transfer_resend_note = data.data.data.resend_note;
            const transfer_resend_mark = data.data.data.resend_mark;

            this.results.forEach(transferItem=>{
              if(transferItem.id === transfer_id){
                transferItem.resend_note = transfer_resend_note;
                transferItem.resend_mark = transfer_resend_mark;
              }
            })

            if(transfer_resend_mark){
              this.$message.success(this.$t('global.message.success_to_remark', {value: transfer_id}), 5);
              row.showNote = true;
              row.showButton = false;
            }else{
              this.$message.error(this.$t('global.message.fail_to_remark', {value: transfer_id}), 5);
            }

          })          
          .finally(()=>{
            row.resendLoading = false;
          });
    },
    sendTransfer(row){
      const {id, type} = row;
      row.resendLoading = true;
      resendTransfer({id, type})
          .then((data)=>{
            const transfer_id = data.data.data.data.id;
            const transfer_status = data.data.data.data.status;
            const transfer_res = data.data.data.data.res;
            const transfer_updated_at = data.data.data.data.updated_at;

            this.results.forEach(transferItem=>{
              if(transferItem.id === transfer_id){
                transferItem.status = transfer_status;
                transferItem.res = transfer_res;
                // API 回來給 utc 要轉 +8
                transferItem.updated_at = moment(transfer_updated_at).utc(8);
              }
            })

            if(transfer_status === 'success'){
              this.$message.success(this.$t('global.message.success_to_resend', {value: transfer_id}), 5);
              row.showButton = false;
            } else {
              this.$message.error(this.$t('global.message.fail_to_resend', {value: transfer_id}), 5);
            }
          })
          .catch((err)=>{
            this.$message.error(this.$t('global.message.fail_to_resend'), 5);
          })
          .finally(()=>{
            row.resendLoading = false;
          });
    },
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      // const { date, status, type, slug, account } = this.form;
      this.fetchTransfer({
        results: pagination.pageSize,
        page: currentPage,
        // date,
        // status,
        // type,
        // slug,
        // account,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.fetchTransfer({...params});
    },
    fetchTransfer(params = {}) {
      if(this.loading) return;
      this.loading = true;
      getTransfer({
        ...this.form,
        ...params,
      }).then(({ data }) => {
        this.pagination.total = data.total;
        const transfer = JSON.parse(JSON.stringify(data.transfer));
        this.errorRes = data.errorRes;
        transfer.forEach(transferItem=>{
          transferItem.showNote = false;
          if(transferItem.status === 'fail' && transferItem.type !== 'bet'){
            // if (this.errorRes.includes(transferItem.res)) {
            //   transferItem.showButton = false;
            //   transferItem.resendLoading = false;
            // } else {
            //   if(transferItem.resend_mark === true){
            //     transferItem.showNote = true;
            //     transferItem.showButton = false;
            //     transferItem.resendLoading = false;
            //     // transferItem.resend_note
            //   } else {
            //     transferItem.showButton = true;
            //     transferItem.resendLoading = false;
            //   }
            // }
            if(transferItem.resend_mark === true){
              transferItem.showNote = true;
              transferItem.showButton = false;
              transferItem.resendLoading = false;
              // transferItem.resend_note
            } else {
              transferItem.showButton = true;
              transferItem.resendLoading = false;
            }
          }

          if(transferItem.resend_note != null || transferItem.resend_note != undefined){
            transferItem.showNote = true;
          }
        })
        this.results = transfer;
      })
      .finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      const { pageSize } = this.pagination;
      this.pagination.current = 1;
      // const { date, status, type, slug, account } = this.form;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.fetch({ results: pageSize, page: 1 });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      this.periodDefault = 'today';
      setTimeout(() => {
        this.onSearch();
      }, 100);
    },
    dateChange(value, mode) {
      this.fetch({ datetime: mode })
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
    showModal(slug){
      this.modalVisible = true;
      this.currentSlug = slug;
    },
    closeModal(){
      this.modalVisible = false;
      this.currentSlug = '';
    }
  },
};
</script>
