<template>
  <div class="fight-items-inner">
    <div class="fight-item" data-right-content="fight">{{ data.round_id }}</div>
    <div class="fight-item" data-right-content="fight">{{ data.fight_no }}</div>
    <div class="fight-item" data-right-content="winner" :data-result="GAME_TYPE_SIDE[gameType][data.result]">{{ data.result ? $t(`global.fight_result.${GAME_TYPE_SIDE[gameType][data.result]}`) : '' | formatString }}</div>
    <div class="fight-item" data-right-content="bet_amount">{{ totalBetAmount | formatNumberWithPoint }}</div>
    <div class="fight-item" data-right-content="bet_count">{{ totalBetCount | formatNumber }}</div>
    <div class="fight-item" data-right-content="status">
      <a-tag class="status-item" :data-status="status">{{ $t(`global.common.round_status_list.${status}`) }}</a-tag>
    </div>
    <div class="fight-item" data-right-content="action">
      <!-- <a-button icon="lock" class="btn control-item-btn" data-btn="lock" @click="$emit('lockEarning', data.round_id)" v-if="data.sent_result && !data.locked">
        <span class="text">{{ $t('ControlPanelPage.lock_earning') }}</span>
      </a-button> -->
      <div>-</div>
    </div>
  </div>
</template>
<script>
import { GAME_TYPE_SIDE } from '@/../tools/config';

export default {
  props: {
    data: Object,
    totalBetAmount: [String, Number],
    totalBetCount: [String, Number],
    gameType: String
  },
  data() {
    return {
      GAME_TYPE_SIDE,
      betAmount: null,
      betCount: null
    };
  },
  mounted(){
  },
  computed: {
    status() {
      if(this.data.open_bet) {
        if(this.data.last_call){
          return 'last_call';
        }else{
          return 'open';
        }
      }else {
        if (this.data.opened) {
          if (this.data.locked) {
            return 'locked';
          }else {
            return 'close';
          }
        }
        return 'idle';
      }
    },
  },
};
</script>
