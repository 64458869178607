<template>
  <a-modal 
    :visible="formShow" 
    :title="formTypeShow" 
    :footer="null" 
    :width="450" 
    @cancel="closeModal" 
    :afterClose="clearForm" 
    :destroyOnClose="true">
    <a-form-model :layout="'vertical'" ref='ipForm' :model="form" :rules="rules">
      <a-form-model-item :label="$t('global.common.ip')" prop="ip">
        <a-input v-model="form.ip" />
      </a-form-model-item>
      <a-form-model-item ref="type" :label="$t('global.common.type')" prop="type">
        <a-radio-group v-model="form.type">
          <a-radio value="bo">BO</a-radio>
          <a-radio value="api">API</a-radio>
          <a-radio value="game">GAME</a-radio>
          <a-radio value="block_game">BLOCK GAME</a-radio>
          <a-radio value="block_provider">BLOCK PROVIDER</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="role" :label="$t('global.common.role')" prop="role" class="m-0">
        <a-radio-group @change="changeRole" :value="form.role">
          <a-radio value="master">{{ $t('global.common.master') }}</a-radio>
          <a-radio value="agent">{{ $t('global.common.agent') }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="account" prop="account">
        <a-select 
          v-model="form.account" 
          show-search 
          :filter-option="filterOption"
          :placeholder="$t('global.message.select_value', { value: $t('global.common.account') })"
        >
          <a-select-option v-for="(listItem, i) in showList" :value="listItem.id" :key="i">
            {{ listItem.account }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('global.common.description')" prop="description">
            <a-textarea
              v-model="form.description"
              :auto-size="{ minRows: 3 }"
            />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" @click="ipFormSubmit" block>
          {{ formTypeShow }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { createIpList, updateIpList } from '@/../api';

export default {
  props:{
    closeModal: Function,
    formShow: Boolean,
    ipFormData: Object,
    formType: String,
    fetchData: Function,
    pagination: Object,
    masterList: Array,
    agentList: Array,
  },
  data() {
    const checkIp = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.ip')})));
      }
      else {
        callback();
      }
    }
    const checkIpType = (rule, value, callback) => {
      if ((value === '') || value === null) {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.type')})));
      }
      else {
        callback();
      }
    }
    const checkAccount = (rule, value, callback) => {
      if (!this.form.account) {
        callback(new Error(this.$t('global.message.select_value', { value: this.$t('global.common.account') })));
      }
      else {
        callback();
      }
    }
    return {
      form: {
        ip:'',
        description: '',
        type: 'bo',
        role: 'master',
        account: undefined,
      },
      rules: {
        ip: [{ validator: checkIp, trigger: 'submit' }],
        type: [{ validator: checkIpType, trigger: 'submit' }],
        account: [{ validator: checkAccount, trigger: 'submit' }],
      },
      currency: 'PHP',
    };
  },
  watch:{
    formShow: {
      handler(newValue){
        if(newValue){
          const role = this.ipFormData.agent ? 'agent' : 'master';
          this.form.ip = this.ipFormData.ip;
          this.form.description = this.ipFormData.description;
          this.form.role = role;
          this.form.account = this.ipFormData.id 
            ? role === 'master' ? this.ipFormData.master : this.ipFormData.agent
            : undefined;
          this.form.type = this.ipFormData.type || 'bo';
        }
      }
    }
  },
  computed:{
    formTypeShow(){
      if(this.formType === 'create'){
        return this.$t('MerchantIpPage.create_ip');;
      }else{
        return this.$t('global.action.edit');
      }
    },
    showList() {
      const { role } = this.form;
      let arrayList = role === 'master' ? this.masterList : this.agentList;
      let uniqueArray = [...new Map(arrayList.map(item => [item.id, item])).values()];
      // return role === 'master' ? this.masterList : this.agentList;
      return uniqueArray;
    },
  },
  methods: {
    changeRole(e){
      this.form.role = e.target.value;
      this.form.account = undefined;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    clearForm(){
      this.form = {
        ip:'',
        description: '',
        type: 'bo',
        role: 'master',
        account: undefined,
      }
    },
    ipFormSubmit() {
      this.$refs.ipForm.validate((valid) => {
        if (!valid) {
          if( this.formType === 'edit') {
            this.$message.error(this.$t('global.message.failed_to_update', {value: this.$t('global.common.ip')}));
          } else {
            this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.ip')}));
          }
          return false;
        } else {
          const { ip, description, type, role, account } = this.form;
          const agent = role === 'agent' ? account : "";
          const master = role === 'master' ? account : "";
          const { id } = this.ipFormData;

          if( this.formType === 'edit') {
            updateIpList({ip_record_id: id, ip, description, agent, master, type})
              .then((data) => {
                this.closeModal();
                this.fetchData({
                  results: this.pagination.pageSize,
                  page: this.pagination.current,
                });
                if (data.data.code == 'OK') {
                  this.form.ip = data.data.ip_record.ip
                  this.form.type = data.data.ip_record.ip_type
                }
                this.$message.success(this.$t('global.message.success_to_edit', {value: this.$t('global.common.ip')}));
              })
              .catch((err) => {
                this.$message.error(this.$t('global.message.failed_to_update', {value: err.response.data.message}));
              });
          } else {
            createIpList({ip, description, agent, master, type})
              .then((data) => {
                this.closeModal();
                this.fetchData({
                  results: this.pagination.pageSize,
                  page: this.pagination.current,
                });
                this.$message.success(this.$t('global.message.success_to_create', {value: this.$t('global.common.ip')}));
              }).catch((err) => {
              this.$message.error(this.$t('global.message.failed_to_create', {value: err.response.data.message}));
            });
          };
        }
      });
    },
  },
};
</script>
