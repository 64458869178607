import Vue from 'vue';
import VueRouter from 'vue-router';
import * as Pages from '../src/pages';
import store from '../store';
import storage, { BACK_URL, PREVIOUS_URL } from '../storage';
import { getMenuPermissions } from '@/../api';

Vue.use(VueRouter);

const backURL = () => storage.getItem(BACK_URL) || { name: 'Home' };

const saveBackURL = (to, from, next) => {
  const { name, query, params } = to;
  storage.setItem(BACK_URL, { name, query, params });
  next();
};

const authenticateWhenAuth = (to, from, next) => {
  const { name } = from;
  if (name === to.name) { return next(false); }
  if (store.getters['auth/token'] !== undefined && store.getters['auth/token'] !== '') {
    next(backURL());
  } else {
    next();
  }
};

const authenticate = (to, from, next) => {
  getMenuPermissions()
    .then((data)=>{
      let menus = data.data.menus;
      if(menus[to.name] !== true) {
        next({ name: 'Home' });
      }
    }).catch(err=>{
      if (err.response.status === 401) {
        next({ name: 'Login' });
      }
    })

  const { name } = from;
  if (name === to.name) { return next(false); }
  if (store.getters['auth/token'] !== undefined && store.getters['auth/token'] !== '') {
    // console.log(`Have Auth Token: ${store.getters['auth/token']}`);
    if (to.name === 'Login'){
      // console.log(`Have Auth Token Can't Go to Login Page`);
      next(backURL());
    } else {
      next();
    }
  } else {
    next({ name: 'Login' });
  }
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    beforeEnter: authenticateWhenAuth,
    component: Pages.LoginPage,
  },
  {
    path: '/entry',
    name: 'Entry',
    //beforeEnter: authenticateWhenAuth,
    component: Pages.EntryPage,
  },
  {
    path: '/',
    component: Pages.LayoutPage,
    beforeEnter: authenticate,
    children: [
      {
        path: '',
        name: 'Home',
        component: Pages.HomePage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Pages.ProfilePage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'video_source',
        name: 'VideoSource',
        component: Pages.VideoSourcePage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'single_moderator',
        name: 'SingleModerator',
        component: Pages.ArenaModeratorPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'single_moderator/panel',
        name: 'ControlPanel',
        component: Pages.ControlPanelPage,
        meta: {
          keepAlive: false,
        },
        beforeEnter:(to, from, next) => {
          const { query } = to;
          const { main_pool_id, merchant_provider_id } = query;
          if(!main_pool_id || !merchant_provider_id){
            next({ name: 'Login' });
          }else{
            next();
          }
        }
      },
      {
        path: 'multi_moderator',
        name: 'MultiModerator',
        component: Pages.MultiModeratorPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'multi_moderator/current_arena',
        name: 'CurrentArena',
        component: Pages.CurrentArenaPage,
        meta: {
          keepAlive: false,
        },
        beforeEnter:(to, from, next) => {
          const { query } = to;
          const { merchant_provider_id } = query;
          if(!merchant_provider_id){
            next({ name: 'Login' });
          }else{
            next();
          }
        }
      },
      {
        path: 'players',
        name: 'Players',
        component: Pages.PlayersPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'player_credits',
        name: 'PlayerCredits',
        component: Pages.PlayerCreditsPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'order',
        name: 'Order',
        component: Pages.OrderPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'admin_agents',
        name: 'AdminAgents',
        component: Pages.AdminAgentsPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent/agent_list',
        name: 'AgentList',
        component: Pages.AgentsPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent/source',
        name: 'AgentSource',
        component: Pages.AgentsSourcePage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent/agent_list/:id',
        name: 'AgentShow',
        component: Pages.AgentShowPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent_sub_account',
        name: 'AgentSubAccount',
        component: Pages.AgentSubAccountPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'agent_sub_account/:id',
        name: 'AdminAgentSubAccount',
        component: Pages.AdminAgentSubAccountPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchants',
        name: 'Merchants',
        component: Pages.MerchantsPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchants/:id/admin',
        name: 'AdminMerchantAdmin',
        component: Pages.AdminMerchantAdminPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchants/:id/roles',
        name: 'AdminMerchantRoles',
        component: Pages.AdminMerchantRolesPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchant_roles',
        name: 'MerchantRoles',
        component: Pages.MerchantRolesPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchant_admin',
        name: 'MerchantAdmin',
        component: Pages.MerchantAdminPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'merchant_ip_list',
        name: 'MerchantIpList',
        component: Pages.MerchantIpListPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'bet_log',
        name: 'BetLog',
        component: Pages.BetLogPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'game_results',
        name: 'GameResults',
        component: Pages.GameResultsPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'win_lose',
        name: 'WinLose',
        component: Pages.WinLosePage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'ggr',
        name: 'GGR',
        component: Pages.GGRPage,
        meta: {
          keepAlive: false,
        },
      },
      // {
      //   path: 'monthly_profit',
      //   name: 'MonthlyProfit',
      //   component: Pages.MonthlyProfitPage,
      //   meta: {
      //     keepAlive: false,
      //   },
      // },
      {
        path: 'daily_betting_statistic',
        name: 'DailyBettingStatistic',
        component: Pages.DailyBettingStatisticPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'transfer',
        name: 'Transfer',
        component: Pages.TransferPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'exchange_rate',
        name: 'ExchangeRate',
        component: Pages.ExchangeRatePage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'create_agent',
        name: 'CreateAgent',
        component: Pages.CreateAgentPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'pool_setting',
        name: 'PoolSetting',
        component: Pages.PoolSettingPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'malay_odds_setting',
        name: 'MalayOddsSetting',
        component: Pages.MalayOddsSettingPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'change_password',
        name: 'ChangePassword',
        component: Pages.ChangePasswordPage,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'error',
        name: 'Error',
        component: Pages.ErrorPage,
        meta: {
          keepAlive: false,
        },
      },
    ]
  },
  { path: '*', name: 'NotFound', component: Pages.NotFoundPage },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.afterEach((to, from, failure) => {
  const { name, query, params } = to;
  storage.setItem(PREVIOUS_URL, { name, query, params });
});

export default router;
