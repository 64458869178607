export default {
  lang: '繁體中文',
  global: {
    game_type: {
      cockfight: '鬥雞',
      spider: '鬥蜘蛛',
      color_game: '顏色遊戲',
      apple: '蘋果遊戲',
      up_down: 'Up&Down遊戲',
      event: '活動',
    },
    fight_result: {
      meron: "龍",
      wala: "鳳",
      draw: "和",
      up_down_draw: "和",
      cancel: "取消",
      red: '紅',
      blue: '藍',
      gold: '金',
      white: '白',
      red3: '紅*3',
      white3: '白*3',
      up: '上',
      down: '下',
    },
    action: {
      yes: "確認執行",
      no: "不要執行",
      go: "前往",
      search: "搜尋",
      reset: "重置",
      create: "創建",
      logout: "登出",
      submit: "確認送出",
      edit: "編輯",
      risk_edit: '風控設定',
      lock_earning: "派彩",
      decision_on_hold: "暫停比賽",
      cancel_on_hold: "開始比賽",
      not_allow_bet: "不允許下注",
      allow_bet: "允許下注",
      next: "下一步",
      prev: "上一步",
      step: "步驟",
      ok: "確定",
      collapse: "收合",
      mask_video: "遮罩視訊",
      cancel_mask_video: "關閉遮罩視訊",
      records: "紀錄",
      cancel: "取消",
      export: "匯出",
      select_file: "選擇檔案",
      upload_image: "上傳圖片",
      cancel_upload: "取消上傳",
      update: '更新',
      update_all: "全部更新",
      upload_all_images: "上傳所有圖片",
      cancel_update_all: "取消上傳所有圖片",
      refresh_fights: "刷新比賽場次",
      open_bet: "開放下注",
      close_bet: "關閉下注",
      re_declare: "重新宣布贏家",
      locking: "派彩中",
      open_arena: "開始營運場地",
      close_arena: "關閉水池所有賽局",
      refresh_cache: '刷新快取',
      cancel_all: '取消上傳所有圖片',
      delete: "刪除",
      monitor: '監測',
      monitor_video: '開啟視訊監控',
      retry: '重試',
    },
    common: {
      account: "帳號",
      account_id: "編號",
      active_status_list: {
        all: "全部",
        active: "開啟",
        close: "關閉",
        enable: '啟用',
        disable: '停用'
      },
      action: "操作",
      add_settle_bet_info: '結算API增加注單資訊',
      agent: "代理",
      agent_account: "代理帳號",
      agent_count: "代理數",
      arena: "場地",
      arena_suspend: "場地暫停",
      all: "全部",
      amount: "金額",
      announcement: "公告",
      average_percentage: "場均投注",
      bet_amount: "投注金額",
      bet_count: "投注數量",
      bet_type_list: {
        all: "全部",
        deposit: "入金",
        withdraw: "出金",
        OrderDeposit: "入金",
        OrderWithdraw: "出金",
        bet: "下注",
        settle: "結算",
        cancel: "取消",
        resettle: "重新結算",
        fail: "失敗",
        init: "初始"
      },
      callback_url: '回調網址',
      change_password: "修改密碼",
      confirm: "確認",
      confirm_password: "確認密碼",
      create_agent: "新增代理",
      create_setting: '新增設定',
      created_at: "建立時間",
      currency: "幣別",
      custom_logo: '客製化logo',
      custom_logo_list: {
        // odin: '',
        // gleague: '',
        // fiesta: '',
        // fiestafruit: '',
      },
      date: "日期",
      date_type: "時間選項",
      date_type_list: {
        BetAt: "下注時間",
        GameDate: "賽事日期",
        SettleAt: "結算時間",
        OpenRound: "開局時間"
      },
      declare_fight_winner: "宣布贏家是",
      defaultLanguage: "預設語言",
      description: "敘述",
      email: "E-mail",
      fight_count: "場數",
      fight_id: "場次代號",
      game_date: "賽事日期",
      game_type: "遊戲類別",
      ip: "IP",
      id: "編號",
      kill_rate: "殺率",
      language: "語言",
      last_login: "最後登入",
      link_master: "主池總代",
      lock_earning: "派彩",
      log: "日誌",
      main_arena: '主水池名稱',
      main_pool: '主水池',
      manager: "管理",
      master: "總代",
      max: "最大值",
      merchant: "商號",
      merchant_account: "商戶帳號",
      meron_wala: "龍 鳳",
      min: "最小值",
      month: "月份",
      multi_arena: '多場投注',
      multi_arena_status: {
        open: "開啟",
        close: "關閉"
      },
      name: "名稱",
      odd: "賠率",
      odds_type: "賠率種類",
      // odds_type_list: {
      //   rake: "",
      //   malay: "",
      //   static: ""
      // },
      operator: "操作員",
      options: '選項',
      order_num: "訂單編號",
      order_status_list: {
        init: "接到訂單",
        process: "處理中",
        success: "成功",
        failed: "失敗",
        pending: "擱置中",
        fail: "失敗",
        error: "錯誤",
      },
      password: "密碼",
      payout: "賠率",
      platform_profit: "平台盈利",
      player_account: "玩家帳號",
      player_name: "玩家名稱",
      player_bet_limit: "玩家單次投注上限",
      player_count: "玩家數",
      player_round_limit: "玩家回合投注上限",
      pool_status: '水池狀態',
      profile: "代理資訊",
      provider_name: "提供者名稱",
      rate: "抽水 (%)",
      rate_amount: "抽水金額",
      risk_odd: "成局賠率",
      role: "角色",
      risk_control: '風控處置',
      risk_type_list: {
        bet_limit: '投注上限減低',
        no_login: '禁止登入',
      },
      round_id: "賽事編號",
      round_limit: "回合投注上限",
      round_status: '賽局狀態',
      round_status_list: {
        idle: "閒置",
        open: "可下注",
        close: "禁止下注",
        locked: "已結算",
        last_call: "最後下注",
        arena_close: "場地關閉",
        unsettled: '未結算',
        settled: '已結算',
      },
      status: "狀態",
      setting: '設定',
      settled_at: "結算時間",
      settle_zero: '當金額為0時產生結算訂單',
      side: "下注於",
      skin: '主題顏色',
      skin_list: {
        default: '預設',
        brown: '棕色',
        white: '白色',
        black: '黑色',
        green: '綠色',
        dark_and_light: '日夜模式',
      },
      slug: "單號",
      theme: "前台主題",
      total: "加總",
      total_bet_amount: "總投注金額",
      total_bet_count: "總下注數量",
      total_platform_profit: "平台總盈利",
      total_rate_amount: "總抽水金額",
      total_user_bet: "總下注人數",
      total_valid_bet_amount: "總有效投注",
      type: "類型",
      update_setting: '更新設定',
      updated_at: "更新時間",
      usable_language: "可使用的語言",
      user: "使用者",
      user_count: "投注會員數",
      video_url: "視訊源網址",
      icp_video_url: "ICP網址",
      valid_bet_amount: "有效投注",
      winner: "賽果"
    },
    times: {
      unset: "未設置",
      today: "今天",
      yesterday: "昨天",
      this_week: "本週",
      last_week: "上週",
      this_month: "本月",
      last_month: "上月",
      custom: "自訂",
      tooltip: "記錄保存：3個月 (今天日期 - 100天)"
    },
    menu: {
      group: {
        player: "玩家",
        record: "報表",
        manager: "管理",
        control: "水池主控台",
        Agent: "代理管理",
        MasterAgent: "總代管理",
        ArenaModerator: "場地",
        Pool: "水池",
      },
      SingleModerator: "主水池控台",
      MultiModerator: "賽局監控",
      ControlPanel: "主水池控台",
      CurrentArena: "當前投注",
      Players: "玩家管理",
      PlayerCredits: "玩家額度紀錄",
      BetLog: "投注紀錄",
      GameResults: "遊戲結果",
      Order: "轉賬記錄",
      AgentList: "代理列表",
      AgentSource: "總代共池清單",
      AdminAgents: "代理管理",
      AgentShow: "代理資訊",
      AgentRoles: "代理權限",
      AgentSubAccount: "管理員",
      MasterAgentList: "總代列表",
      MasterAgentSource: "總代共池清單",
      Merchants: "商號管理",
      MerchantRoles: "商號角色管理",
      MerchantAdmin: "商號帳號管理",
      MerchantIpList: "IP 限制設定",
      ChangePassword: "修改密碼",
      GGR: "GGR 報表",
      Profile: "個人資料",
      VideoSource: "視訊源",
      Transfer: "交易訂單查詢",
      MonthlyProfit: "月盈利總表",
      DailyBettingStatistic: "每日總投注統計",
      ExchangeRate: "匯率管理",
      CreateAgent: "新增代理",
      PoolSetting: '水池設定',
      MalayOddsSetting: '馬來盤設定'
    },
    message: {
      fill_input: "請輸入{value}",
      select_value: "請選擇{value}",
      must_be_at_4_characters: "{value}最少輸入4個字元",
      must_be_at_6_characters: "{value}最少輸入6個字元",
      must_be_between_characters: "{value}要在{min} 到 {max} 字元之間",
      must_be_between_number: "{value}要在{min}到{max}之間",
      must_be_alphanumeric: "{value}須為英文字母或數字",
      must_be_number: "須為數字",
      must_be_number_value: "{value}必須為數字",
      two_no_match: "兩個輸入框的內容不同",
      failed_to_create: "{value}創建失敗",
      failed_to_edit: "{value}編輯失敗",
      success_to_create: "{value}創建成功",
      success_to_edit: "{value}編輯成功",
      success_to_change: "{value}修改成功",
      failed_to_update: "{value}更新失敗",
      decision_on_hold: "點擊上方按鈕，場地將會暫停所有下注、轉帳功能",
      cancel_on_hold: "點擊上方按鈕，場地遊戲進行",
      close_bet: "點擊上方按鈕，場地將會暫停下注功能",
      open_bet: "點擊上方按鈕，場地將會開啟下注功能",
      mask_video: "點擊上方按鈕，前台將會遮罩視訊區塊",
      cancel_mask_video: "點擊上方按鈕，前台將會關閉遮罩視訊區塊",
      delete: "刪除 {value} ?",
      choose_arena: "選擇鬥雞場",
      enter_fight_number: "輸入場次號碼",
      select_date: "請選擇日期",
      select_one: "請選擇至少一項{value}",
      browser_not_supported: "不支援此瀏覽器。請按確定按鈕以打開 google chrome 或 safari。",
      network_error_title: "網路錯誤",
      network_error_info: "請確認您的網路並重新整理頁面",
      must_be_greater_than_zero: "金額須為大於或等於 0 的數字",
      must_be_greater_than_min: "最大金額需大於等於最小金額",
      incorrect_ip: "不正確的IP格式",
      criteria_be_selected: "必須選擇一個搜尋條件",
      success_to_delete: "{value}刪除成功",
      failed_to_delete: "{value}刪除失敗",
      failed_to_delete_with_reason: "{value}更新失敗: {reason}",
      account_placeholder: "請輸入4-16英文、數字",
      password_placeholder: "請輸入6-20英文、數字、符號",
      confirm_password_placeholder: "請再次輸入相同的密碼",
      date_type_hint: "報表以「{dateType}」為基準，每日上午10:00(GMT+8)統計昨日的資料，不包含今日資料。",
      please_create_role: "在創建管理員前，請先建立角色",
      max_length: '{value}最大長度為{length}',
      whole_url: '請輸入完整的網址',
      setting_player_round_max: "設定玩家每回合所投注的最大及最小值",
      setting_player_bet_max: "設定玩家單次所投注的最大值",
      A_greater_tham_B: '{value_a}必須大於{value_b}',
      reset_data: '重置資料嗎？',
      monitor: '點擊上方按鈕，會開啟前台監控',
      failed_to_monitor: '開啟監控頁面失敗',
    }
  },
  AgentsPage: {
    master_account: "總代帳號",
    languageList: "語言列表",
    create_master_agent: "新增總代理",
    rate: "抽水 (%)",
    rate_note: "若下線代理的抽水，有高於上線代理的抽水時，系統會同步降低為新的抽水",
    max: "最大值: {value}",
    abbr: "縮寫",
    abbr_placeholder: "請輸入1-6英文、數字",
    edit_agent: "編輯代理"
  },
  AgentSubAccountPage: {
    authority: "權限",
    authority_list: {
      read: "閱讀",
      editor: "編輯"
    },
    create_sub_account: "新增管理員"
  },
  BetLogPage: {
    win_lose: "輸贏",
    bet_return: "返回金額",
    is_settled: "是否已結算",
    device: "裝置",
    amount_type: "金額類型",
    amount_range: "金額區間"
  },
  ChangePasswordPage: {
    current_password: "當前密碼",
    current_password_placeholder: "請輸入當前密碼",
    new_password: "新密碼"
  },
  ArenaModeratorPage: {
    video_rate: "視訊源抽水",
    online_users: "在線人數"
  },
  ControlPanelPage: {
    arena_on: "開",
    arena_off: "關",
    no_announce: "尚無公告，請點擊編輯新增",
    edit_announce: "編輯公告",
    open_arena_title: "開始營運鬥雞場?",
    close_arena_title: "確定要關閉今天鬥雞場(所有決鬥將結束)?",
    start_fight: "開始場次代號",
    start_time: "開始時間",
    open_betting: "接受下注?",
    close_betting: "關閉下注?",
    re_declare_title: "重新宣布贏家?",
    re_declare_content: "重新宣布贏家，場次代號 {id} 贏家是",
    lock_earing_title: "派彩?",
    lock_earing_content: "派彩，場次代號 {id}",
    click_again_to: "點擊以",
    file_upload: "上傳檔案",
    arena_cover: "遊戲入口圖",
    mask_file: "遮罩圖",
    schedule: "賽程表",
    edit_provider_url: "視訊網址",
    img_path: "檔案路徑",
    below_px_value: "{value}px以下",
    img_format: "格式",
    img_recommended_size: "尺寸",
    file_size: "檔案尺寸",
    image: "圖片",
    file_no_selected: "未選擇任何檔案",
    no_image: "沒有圖片",
    edit_video_url: "編輯視訊源",
    pool_fight_number: '水池/場次編號',
    round_master: '賽局總代',
    message: {
      input_fight: "請輸入開始場次數字",
      max_image_size: "圖片最大尺寸是{value}kb",
      error_image_type: "錯誤的圖片格式，只接受: jpg, jpeg, png",
      delete_image_placeholder: "您確定要刪除{value}?",
      success_to_earn_locked: "場次代號 {value} 派彩成功",
      fail_to_earn_locked: "場次代號 {value} 派彩失敗，原因: {reason}",
      success_to_cancel_bet: "成功取消場次代號 {value} 的所有注單",
      fail_to_cancel_bet: "取消場次代號 {value} 的所有注單失敗，原因: {reason}",
      affect_all_round: "將影響{value}底下所有賽局"
    }
  },
  CurrentArenaPage: {
    count: "數量",
    unsettlement: "未結算",
    fight_action: "賽事操作",
  },
  GameResultsPage: {
    open_bet_at: "收注單開始時間",
    close_bet_at: "收注單結束時間",
    declare_at: "賽果宣布時間",
    reload_at: "重置時間",
    cancel_at: "取消時間",
    total_rate: "總抽水",
    total_meron: "龍-總下注",
    total_wala: "鳳-總下注",
    total_draw: "和-總下注",
    status_list: {
      initial: "初始",
      betting: "下注中",
      fighting: "戰鬥中",
      finished: "結算中",
      reloaded: "重新判定",
      earn_locked: "派彩",
      last_call: "最後下注",
      cancel: "取消"
    },
    time: "時間",
    reason: "取消原因",
    cancel_reasons: {
      '3000': "單邊下注",
      '3001': "賠率不成局",
      canceled_manual: "手動取消",
      canceled_ws: "系統取消"
    },
    open_round: '開局時間',
  },
  LoginPage: {
    log_in: "登入",
    otp: "動態密碼",
    message: {
      success: "登入成功！",
      failed: "帳號或密碼輸入錯誤"
    }
  },
  MerchantsPage: {
    admin: "管理員管理",
    role: "角色管理",
    create_merchant: "新增商號",
    name: "商號名稱",
    message: {
      master_agent_only_select_one: "總代跟代理只能選擇一個填寫"
    }
  },
  MerchantRolesPage: {
    current_arena: '當前投注',
    can_permission_pages: "可使用頁面",
    use_account: "使用帳號",
    create_role: "創建角色",
    edit_role: "編輯角色",
    role_name: "角色名稱",
    color: "顏色",
    can_use_pages: "可使用的頁面",
    arena_on_off: "賽局開關",
    fight_control: "場次控制",
    lock_earning: "派彩",
    round_lock_earning: "賽局結算",
    announcement: "公告",
    decision_on_hold: "停止比賽",
    monitor: '視訊監控',
    no_bets_allowed: "不允許下注",
    mask_video: "視訊遮罩",
    read: "閱讀",
    update: "更新",
    create: "創建",
    delete: "刪除",
    edit: "編輯",
    dashboard_card: "顯示加總金額",
    confirm: "確認",
    approve: "批准",
    arena_cover: "遊戲入口圖",
    mask_file: "遮罩圖",
    schedule: "賽程表",
    export: "匯出",
    edit_provider_url: "視訊網址",
    display_rate: "顯示抽水%",
    view_schedule: '查看排程',
    view_log: '查看修改紀錄',
    account_to_use: "尚有帳號在使用這個角色",
    risk_edit: '風控設定',
    monitor_video: '視訊監控',
  },
  MerchantAdminPage: {
    edit_manager: "編輯管理員",
    create_manager: "創建管理員"
  },
  MerchantIpPage: {
    master_agent: "總代或代理",
    create_ip: "新增IP"
  },
  DailyBettingStatisticPage: {
    yesterday_percentage: "投注昨日相比 %",
    last_week_percentage: "投注上週相比 %",
    user_count: "投注人數",
    ave_per_fight_percentage: "場均昨日相比 %",
    last_week_ave_per_fight_percentage: "場均上週相比 %"
  },
  OrderPage: {
    after_balance: "轉帳後餘額"
  },
  PlayersPage: {
    balance: "餘額",
    fee: "手續費",
    current_sign_in_ip: "登入 IP",
    total_deposit: "總存款",
    total_withdraw: "總取款",
    total_win_lose: "總輸贏"
  },
  PlayerCreditsPage: {
    id: "交易流水號",
    type: {
      deposit: "入金",
      withdraw: "出金",
      settle: "贏",
      cancel: "取消",
      bet: "下注"
    },
    before_amount: "操作前金額",
    after_amount: "操作後金額"
  },
  ProfilePage: {
    setting_round_total_max: "設定每回合的總投注上限金額",
    setting_round_draw_max_part1: "設定每回合",
    setting_round_draw_max_part2: "的投注上限金額",
    player_limit: "玩家投注上限",
    setting_player_max: "設定玩家每回合所投注的最大及最小值",
    basic_info_title: "基本資訊",
    basic_info_text: "關於您的代理的基本資訊",
    integration_info: '對接資訊',
    auth: '身份',
    secret: '密鑰',
    api_doc_url: 'API 文件網址',
    api_url: 'API 網址',
    video_traffic: '視訊流量',
    remain_video_data: '剩餘視訊流量',
    update_video_data: '更新視訊流量',
  },
  VideoSourcePage: {
    source: "視訊源",
    agent_source: "總代理視訊源",
    accessable_merchants: "可使用的商號"
  },
  TransferPage: {
    slug: "交易流水號",
    data: "資料",
    res: "回應",
    round_id: "回合",
    setting_master_id: "設定",
    num: "單號",
    resend: "重發",
    bet_num: "單號",
    resend_note: "備註",
    resend_mark: "標記",
    message: {
      success_to_resend: "重新傳送成功, No:{value}",
      fail_to_resend: "重新傳送失敗, No:{value}",
      success_to_remark: "備註成功, No:{value}",
      fail_to_remark: "備註失敗, No:{value}"
    }
  },
  ExchangeRatePage: {
    currency: "幣別ID",
    currency_name: "幣別名稱",
    last_approved_rate: "前次匯率",
    today_rate: "今日匯率",
    ref_rate: "參考匯率",
    ref_rate_time: "參考匯率時間",
    tbd_confirm_rate: "確認匯率",
    tbd_modify_rate: "修改匯率",
    record: "異動紀錄",
    current_time: "現在時間",
    last_approved_time: "前次批准時間",
    base_currency: "基準幣別",
    fetch_exchange_rate: "獲取匯率",
    approve_rate: "批准匯率",
    rate_warning: "＊請注意：批准確認後匯率將立即生效！",
    log: "匯率異動紀錄",
    confirmed_by: "確認人員",
    confirmed_time: "確認時間",
    approved_by: "批准人員",
    approved_time: "批准時間",
    exchange_rate: "匯率"
  },
  CreateAgentPage: {
    parent_agent: '父層代理',
    environment: '環境',
    staging: '測試站',
    production: '正式站',
    brand: '品牌',
    trade_mode: '交易模式',
    transfer_wallet: '轉帳錢包',
    single_wallet: '單一錢包',
    level_sub_agent_with_value: '第 {value} 層子代理',
    delete_agent_with_value: '刪除第 {value} 層子代理',
    add_agent_with_value: '新增第 {value} 層子代理',
    agent_info: '代理資訊',
    message: {
      select_master_first: '請先選擇一個總代',
      agent_same_master: '父層代理與總代相同',
      please_refresh_cache: '請刷新快取以取得新的代理列表',
      log_saved_time: '每一項日誌最多保存{value}小時',
      success_refresh_cache: '刷新快取成功',
      fail_refresh_cache: '刷新快取失敗',
    }
  },
  PoolSettingPage: {
    pool_name: '水池名稱',
    odds_refresh_frequency: '跳動頻率(s)',
    last_call_percentage: '最後下注(%)',
    close_bet_percentage: '關閉投注(%)',
    per_bet: '單筆',
    per_round: '單局',
    risk_threshold: '風控金額',
    current_schedule: '當前排程',
    new_schedule: '新排程',
    update_schedule: '更新排程',
    schedule: '排程',
    view: '查看',
    start_time: '起始時間',
    end_time: '結束時間',
    pool_configuration_options: '水池設定選項',
    expect_date: '預期生效日期',
    expect_time: '預期生效時間',
    add_new_pool_configuration: '新增水池設定',
    exceed_time: '現在時間已經超過預期生效時間',
    effective_time: '生效時間',
    import_current_schedule: '載入當前排程',
  }
}
