<template>
  <div class="new-merchant">
    <a-button class="add-btn" icon="plus" @click="showModal">
      {{ $t('MerchantsPage.create_merchant') }}
    </a-button>
    <a-modal v-model="visible" :title="$t('MerchantsPage.create_merchant')" :footer="null" :width="630">
      <a-form-model :layout="'vertical'" ref="newMerchant" :model="newMerchant" :rules="rules">
        <a-row :gutter="[16,16]">
          <a-col :md="12">
            <a-form-model-item :label="$t('global.common.account')" prop="account">
              <a-input v-model="newMerchant.account" :placeholder="$t('global.message.account_placeholder')" autocomplete="off" />
            </a-form-model-item>

            <a-form-model-item has-feedback :label="$t('global.common.password')" prop="password">
              <a-input v-model="newMerchant.password" type="password" :placeholder="$t('global.message.password_placeholder')" autocomplete="off" />
            </a-form-model-item>

            <a-form-model-item has-feedback :label="$t('global.common.confirm_password')" prop="confirm_password">
              <a-input v-model="newMerchant.confirm_password" type="password" :placeholder="$t('global.message.confirm_password_placeholder')" autocomplete="off" />
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item :label="$t('global.common.name')" prop="name">
              <a-input v-model="newMerchant.name" />
            </a-form-model-item>

            <a-form-model-item :label="$t('global.common.status')" prop="status">
              <a-select v-model="newMerchant.status">
                <a-select-option value="active">{{$t('global.common.active_status_list.active')}}</a-select-option>
                <a-select-option value="stop">{{$t('global.common.active_status_list.close')}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item>
          <a-button type="primary" @click="newMerchantSubmit(newMerchant)" block>
            {{ $t('global.action.create') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { createMerchant } from '@/../api';

export default {
  data() {
    const checkAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.account')})));
      }else if (value.length < 4) {
        callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.account')})));
      } else if (!/^[a-zA-Z0-9_\.]*$/.test(value)) {
        callback(new Error(this.$t('global.message.must_be_alphanumeric', {value: this.$t('global.common.account')})));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.password')})));
      }else if (value.length < 4) {
        callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.password')})));
      } else {
        callback();
      }
    };
    const confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.confirm_password')})));
      }else if (value !== this.newMerchant.password) {
        callback(new Error(this.$t('global.message.two_no_match')));
      } else {
        callback();
      }
    };
    const checkName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.name')})));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      newMerchant: {
        account: '',
        password: '',
        confirm_password: '',
        name: '',
        status: 'active'
      },
      rules: {
        account: [{ validator: checkAccount, trigger: 'change' }],
        password: [{ validator: checkPassword, trigger: 'change' }],
        confirm_password: [{ validator: confirmPassword, trigger: 'change' }],
        name: [{ validator: checkName, trigger: 'change' }],
      },
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
  newMerchantSubmit() {
      const { account, password, confirm_password, status, name } = this.newMerchant;
      this.$refs.newMerchant.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.merchant')}));
          return false;
          } else {
            createMerchant({account, password, confirm_password, password, status, name})
              .then((data)=>{
                this.$message.success(this.$t('global.message.success_to_create', {value: this.$t('global.common.merchant')}));
                this.$emit('update-data');
                this.visible = false;
                this.$refs.newMerchant.resetFields();
              }).catch(()=>{});
        }
      });
    },
  },
};
</script>

