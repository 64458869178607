<template>
  <div class="profile-page">
    <div class="title">
      <span>{{ $t("global.common.profile") }}</span>
      <span v-if="agent.name && !agent.is_master_agent">{{
        ` - ${agent.name}`
      }}</span>
      <!-- <span v-if="agent.is_master_agent" class="is-master-text">{{ $t('global.common.master').toUpperCase() }}</span> -->
    </div>
    <div class="profile-container" v-if="isMounted">
      <div class="agent-data-container" v-if="agent.is_master_agent">
        <div class="data-container-item role-container">
          <div class="avatar">
            <a-avatar class="icon" :src="require('images/agent_icon.svg')" />
          </div>
          <div class="username">{{ agent.name }}</div>
          <div class="role-text-container" v-if="agent.is_master_agent">
            <p class="role-text">
              {{ $t("global.common.master").toUpperCase() }}
            </p>
          </div>
        </div>
      </div>

      <div class="info-container">
        <div class="count">
          <div class="player-count">
            <p class="name">{{ $t("global.common.player_count") }}</p>
            <p class="value">{{ memberCount.player_counts }}</p>
          </div>
          <div class="agent-count">
            <p class="name">{{ $t("global.common.agent_count") }}</p>
            <p class="value">{{ memberCount.agent_counts }}</p>
          </div>
        </div>
        <div class="info">
          <div class="header">
            <div>
              <p class="text">{{ $t("ProfilePage.basic_info_title") }}</p>
              <p class="placeholder">{{ $t("ProfilePage.basic_info_text") }}</p>
            </div>
          </div>
          <Info :updatePermission="updatePermission" :data="agent" :getAgentProfile="init" />
        </div>
      </div>
      <div class="agent-data-container">
        <template v-if="agent.game_type">
          <RoundBettingLimit 
            :updatePermission="updatePermission" 
            :data="agent" 
            :betLimit="betLimit" 
            :updateLimit="updateLimit" />
          <BetBettingLimit 
            :updatePermission="updatePermission" 
            :data="agent" 
            :betLimit="betLimit" 
            :updateLimit="updateLimit" />
          <DrawLimit 
            :updatePermission="updatePermission" 
            :data="agent" 
            :maxDraw="maxDraw" 
            :updateLimit="updateLimit" 
            v-if="this.agent.is_master_agent" />
        </template>
      </div>

      <div class="agent-data-container" v-if="agent.is_master_agent === false">
        <div class="data-container-item setting-container">
          <div class="info">
            <div class="header">
              <div>
                <p class="text">{{ $t("ProfilePage.integration_info") }}</p>
              </div>
            </div>
            <div class="info-content">
              <div class="content-row">
                <p class="row-index">{{ $t("ProfilePage.auth") }}</p>
                <p class="row-value status word-break-all">
                  {{ agent.auth | formatString }}
                </p>
              </div>

              <div class="content-row">
                <p class="row-index">{{ $t("ProfilePage.secret") }}</p>
                <p class="row-value word-break-all">
                  {{ agent.secret | formatString }}
                </p>
              </div>

              <div class="content-row">
                <p class="row-index">{{ $t("ProfilePage.api_url") }}</p>
                <p class="row-value word-break-all">
                  {{ agent.api_url | formatString }}
                </p>
              </div>

              <div class="content-row">
                <p class="row-index">{{ $t("ProfilePage.api_doc_url") }}</p>
                <p class="row-value word-break-all">
                  https://hackmd.io/KRJY2QJUTd-Jyw95S-1ptg
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="data-container-item setting-container" v-if="agent.is_master_agent === false">
          <div class="info">
            <div class="header">
              <div>
                <p class="text">{{ $t("global.common.options") }}</p>
              </div>
              <div>
                <EditAgentDialog :data="agent" :updateOptions="updateOptions" />
              </div>
            </div>
            <div class="info-content">
              <div class="content-row">
                <p class="row-index">{{ $t("global.common.settle_zero") }}</p>
                <p class="row-value status">
                  <span class="status-light" :class="agent.settle_zero ? 'success' : 'fail'"></span>
                  <span>{{
                    $t(
                      `global.common.active_status_list.${agent.settle_zero ? "enable" : "disable"
                      }`
                    )
                  }}</span>
                </p>
              </div>

              <div class="content-row" v-if="agent.trade_mode === 'transfer'">
                <p class="row-index">{{ $t("global.common.callback_url") }}</p>
                <p class="row-value">
                  {{ agent.swt_callback_url | formatString }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getAgentProfile,
  getAgentMemberCount,
  getBetLimit,
  updateAgentBetLimit,
  getMerchantRole
  // getLanguages,
} from "@/../api";

import EditAgentDialog from "@/../src/components/agents/edit_agent_dialog";
import Info from "@/../src/components/info";
import RoundBettingLimit from "@/../src/components/round_betting_limit";
import BetBettingLimit from "@/../src/components/bet_betting_limit";
import DrawLimit from "@/../src/components/draw_limit";

export default {
  components: {
    Info,
    RoundBettingLimit,
    BetBettingLimit,
    DrawLimit,
    EditAgentDialog,
  },
  data() {
    return {
      isMounted: false,
      agent: {},
      memberCount: {},
      betLimit: {
        meron_wala: {
          per_bet: null,
          per_round: {
            min: null,
            max: null,
          },
        },
        draw: {
          per_bet: null,
          per_round: {
            min: null,
            max: null,
          },
        },
      },
      maxDraw: null,
      formShow: false,
      dialogAction: "",
      updatePermission: false
    };
  },
  mounted() {
    this.init();
    this.fetchGetBetLimit();
  },
  methods: {
    showEdit(dialogAction) {
      this.formShow = true;
      this.dialogAction = dialogAction;
    },
    closeModal() {
      this.formShow = false;
    },
    setLimit(data) {
      const { meron, draw, max_draw } = data;
      this.maxDraw = max_draw;
      this.betLimit.meron_wala = meron;
      this.betLimit.draw = draw;
    },
    updateLimit(type, param, callback) {
      const i18nParam = type === "max_draw" ? "round_limit" : `player_${type}`;
      const betLimit = JSON.parse(JSON.stringify(this.betLimit));
      let drawLimit;
      const { meron_wala, draw } = param;
      const id = undefined;
      switch (type) {
        case "round_limit":
          betLimit.draw.per_round = draw;
          betLimit.meron_wala.per_round = meron_wala;
          break;
        case "bet_limit":
          betLimit.draw.per_bet = draw.per_bet;
          betLimit.meron_wala.per_bet = meron_wala.per_bet;
          betLimit.draw.per_round.min = draw.min;
          betLimit.meron_wala.per_round.min = meron_wala.min;
          break;
        case "max_draw":
          drawLimit = param;
          break;
        default:
          return;
      }
      const currentLimit = type === "max_draw" ? { drawLimit } : { betLimit };
      updateAgentBetLimit({ id, ...currentLimit })
        .then((res) => {
          this.$message.success(
            this.$t("global.message.success_to_change", {
              value: this.$t(`global.common.${i18nParam}`),
            })
          );
          callback();
          this.setLimit(res.data);
        })
        .catch(() => {
          this.$message.error(
            this.$t("global.message.failed_to_update", {
              value: this.$t(`global.common.${i18nParam}`),
            })
          );
        });
    },
    fetchGetBetLimit() {
      const id = undefined;
      getBetLimit({ id }).then(({ data }) => {
        this.setLimit(data);
      });
    },
    updateOptions(data) {
      this.agent.swt_callback_url = data.swt_callback_url;
      this.agent.settle_zero = data.settle_zero;
    },
    async init() {
      await getAgentMemberCount().then(({ data }) => {
        this.memberCount = data;
      });

      await getAgentProfile().then(({ data }) => {
        this.agent = data;
        this.isMounted = true;
      });

      await getMerchantRole().then((data) => {
        this.updatePermission = data.data.role.AgentShow?.update;
      });

      await this.fetchGetBetLimit();
    },
  },
};
</script>
