<template>
  <div class="fight-control-item-inner">
    <div class="fight-control-item-header">
      <div class="fight-item-number">
        <span class="title">{{ $t('global.common.fight_id') }}</span>
        <span class="text">{{fight_no}}</span>
        <a-button v-if="locked !== 2 && showEditIcon && can_fight_control" class="btn" type="link" @click="showEdit(pool_id, fight_no)">
          <a-icon type="edit" theme="filled" />
        </a-button>
      </div>
      <div class="fight-item-status" :data-fight-status="status">
        <span class="title">{{ $t('global.common.status') }}</span>
        <span class="text">{{ $t(`global.common.round_status_list.${status}`) }}</span>
      </div>
    </div>
    <div class="fight-control-item-body" :data-game-type="game_type">
      <div class="control-item" v-if="open_bet === true">
        <template v-if="ready_to_last_call">
          <h5 class="item-title">
            <template v-if="double_confirm">
              <span>{{ $t('ControlPanelPage.click_again_to') }}</span>
              <span class="confirm-last-call">{{ $t('global.common.round_status_list.last_call').toUpperCase() }}</span>
            </template>
          </h5>
          <a-button :disabled="!can_fight_control" v-if="!double_confirm" class="btn control-item-btn" data-btn="last_call" @click="confirmBettingStatus(pool_id)">
            <span>{{ $t('global.common.round_status_list.last_call').toUpperCase() }}</span>
          </a-button>
          <a-button 
            v-else
            :loading="controlBtnLoading"
            :disabled="!can_fight_control" 
            class="btn control-item-btn" 
            :class="{'control-item-btn-last-call':double_confirm}" 
            data-btn="last_call" 
            @click="bettingLastCall(pool_id)">
            <span>{{ $t('global.common.confirm') }}</span>
            <span class="confirm-last-call">{{ $t('global.common.round_status_list.last_call').toUpperCase() }}</span>
          </a-button>
        </template>
        <template v-else>
          <h5 class="item-title">
            <template v-if="double_confirm">
              <span>{{ $t('ControlPanelPage.click_again_to') }}</span>
              <span class="confirm-close">{{ $t('global.action.close_bet').toUpperCase() }}</span>
            </template>
          </h5>
          <a-button :disabled="!can_fight_control" v-if="!double_confirm" class="btn control-item-btn" data-btn="close" @click="confirmBettingStatus(pool_id)">
            <span>{{ $t('global.common.round_status_list.close').toUpperCase() }}</span>
          </a-button>
          <a-button 
            v-else
            :loading="controlBtnLoading"
            :disabled="!can_fight_control" 
            class="btn control-item-btn" 
            :class="{'control-item-btn-close':double_confirm}" 
            data-btn="close" 
            @click="bettingClose(pool_id)">
            <span>{{ $t('global.common.confirm') }}</span>
            <span class="confirm-close">{{ $t('global.common.round_status_list.close').toUpperCase() }}</span>
          </a-button>
        </template>
      </div>
      <template v-else>
        <div class="control-item-group" v-if="opened">
          <template v-if="result">
            <!-- && result != 'cancel' -->
            <template v-if="locked !== 2">
              <div class="control-item-inner no-padding">
                <div class="winner-show">
                  <h5 class="item-title">{{ $t('global.common.winner') }}</h5>
                  <div class="result" :data-result="GAME_TYPE_SIDE[game_type][result]">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type][result]}`) }}</div>
                  <button :disabled="!can_fight_control" class="btn control-item-btn" data-btn="re_declare" @click="$emit('reDeclare', pool_id, fight_no)">
                    <a-icon type="reload" class="btn-icon" />
                    <span class="text">{{ $t('global.action.re_declare') }}</span>
                  </button>
                </div>
                <template v-if="can_lock">
                  <div class="control-btn">
                    <button v-if="locked === 0" class="btn control-item-btn" data-btn="lock" @click="$emit('lockEarning', pool_id, fight_no)">
                      <a-icon type="lock"  class="btn-icon" /><span class="text">{{ $t('global.action.lock_earning') }}</span>
                    </button>
                    <div v-else class="btn control-item-btn disabled-btn" data-btn="lock">
                      <a-icon type="loading" class="btn-icon" /><span class="text">{{ $t('global.action.locking') }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="control-item">
                <h5 class="item-title">{{ $t('global.common.winner') }}</h5>
                <div class="result" :data-result="GAME_TYPE_SIDE[game_type][result]">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type][result]}`) }}</div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="control-item-inner">
              <h5 class="item-title">{{ $t('global.common.declare_fight_winner') }}</h5>
              <FightItemOptions 
                :pool_id="pool_id"
                :game_type="game_type"
                :can_fight_control="can_fight_control"
                :enableDraw="enableDraw"
                @checkResult="checkResult"
              />
            </div>
          </template>
        </div>
        <div class="control-item" v-else>
          <h5 class="item-title">
            <template v-if="double_confirm">
              <span>{{ $t('ControlPanelPage.click_again_to') }}</span>
              <span class="confirm-open">{{ $t('global.action.open_bet').toUpperCase() }}</span>
            </template>
          </h5>
          <a-button 
            :disabled="!can_fight_control" 
            v-if="!double_confirm" 
            class="btn control-item-btn" 
            data-btn="open" 
            @click="confirmBettingStatus(pool_id)" >
            <span>{{ $t('global.action.open_bet').toUpperCase() }}</span>
          </a-button>
          <a-button 
            v-else
            :disabled="!can_fight_control"
             class="btn control-item-btn" 
             :class="{'control-item-btn-open':double_confirm}" 
             data-btn="open" 
             @click="bettingOpen(pool_id)" 
             :loading="controlBtnLoading"
             >
            <span>{{ $t('global.common.confirm') }}</span>
            <span class="confirm-open">{{ $t('global.action.open_bet').toUpperCase() }}</span>
          </a-button>
        </div>
      </template>
    </div>
    <a-modal v-model="formShow" :footer="null" :width="380">
      <template slot="title">
        {{ $t('global.action.edit') }}
        <span class="primary-font-color"> {{ fight_no }}</span>
      </template>
      <EditFightId :fightForm="fightForm"
                   :main_pool_id="main_pool_id"
                   @update-fightId="updateFightId"
      />
    </a-modal>
  </div>
</template>
<script>
import { 
  setPoolOpenBet,
  setPoolLastCall,
  setPoolCloseBet,
  // checkRoundOneSideBet
  } from '@/../api';
import FightItemOptions from './fight_item_options';
import EditFightId from '@/../src/components/arena/modify_fight_id';
import { GAME_TYPE_SIDE } from '@/../tools/config';

const fightForm = {
  pool_id: 0,
  fight_no: 0,
}

export default {
  components: {
    EditFightId,
    FightItemOptions
  },
  props: {
    fight_no: String,
    pool_id: Number,
    main_pool_id: [String, Number],
    game_type: String,
    open_bet: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
      default: false, // 是否開過下注
    },
    result: {
      type: String,
      default: '', // meron, wala, draw, cancel
    },
    locked: {
      type: Number,
      default: false,
    },
    sent_result: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Number,
      default: 0,
    },
    can_lock: {
      type: Boolean,
      default: false,
    },
    can_fight_control: {
      type: Boolean,
      default: false,
    },
    double_confirm: {
      type: Boolean,
      default: false,
    },
    ready_to_last_call: {
      type: Boolean,
      default: false,
    },
    last_call: {
      type: Boolean,
      default: false,
    },
    canNextStep: {
      type: Boolean,
      default: false,
    },
    disableNextStep: {
      type: Function,
      default: false,
    },
    showEditIcon: {
      type: Boolean,
      default: false,
    },
    enableDraw: {
      type: Boolean,
      default: false,
    },
    controlBtnLoading: {
      type: Boolean,
      default: false,
    },
    toggleControlBtnLoading: {
      type: Function,
      default: () => {},
    }
  },
  data() {
    return {
      formShow: false,
      fightForm,
      GAME_TYPE_SIDE
    };
  },
  mounted() {},
  computed: {
    status() {
      if(this.open_bet) {
        if(this.last_call){
              return 'last_call';
            }else{
              return 'open';
            }

      }else {
        if (this.opened) {
          if (this.locked === 2) {
            return 'locked';
          }else {
            return 'close';
          }
        }
        return 'idle';
      }
    },
  },
  methods: {
    confirmBettingStatus(id){
      if(!this.canNextStep) return;
      this.disableNextStep();
      this.$emit('confirmBettingStatus',id, true);
    },
    bettingLastCall(id){
      if(!this.canNextStep) return;
      this.disableNextStep();
      this.toggleControlBtnLoading(id, true);
      // this.$emit('bettingReadyToLastCallClose', id);
      // this.$emit('confirmBettingStatus',id, false);
      setPoolLastCall({ pool_id: id, main_pool_id:this.main_pool_id })
        .then(({ data }) => {
        }).catch((err) => {
          console.log(err)
        });
    },
    bettingOpen(id) {
      if(!this.canNextStep) return;
      this.disableNextStep();
      this.toggleControlBtnLoading(id, true);
      // const self = this;
      setPoolOpenBet({  pool_id: id, main_pool_id:this.main_pool_id })
        .then(({ data }) => {
          // self.$emit('bettingOpen', id);
        }).catch((err) => {
          console.log(err)
        });
      // this.$confirm({
      //   title: this.$t('ControlPanelPage.open_betting'),
      //   okText: this.$t('global.action.yes'),
      //   cancelText: this.$t('global.action.no'),
      //   onOk() {
      //     fightOpenBet({ pool_id: id, open: 1 })
      //       .then(({ data }) => {
      //         self.$emit('bettingOpen', id);
      //       }).catch((err) => {
      //         console.log(err)
      //       });
      //   },
      //   onCancel() {},
      // });
    },
    bettingClose(id) {
      if(!this.canNextStep) return;
      this.disableNextStep();
      this.toggleControlBtnLoading(id, true);
      // const self = this;
      setPoolCloseBet({ pool_id: id, main_pool_id: this.main_pool_id })
        .then(({ data }) => {
          // checkRoundOneSideBet({ id })
            // .then((data) =>{
              // self.$emit('bettingClose', id, data.data.result);
            // })
        }).catch((err) => {
          console.log(err)
        });
      // this.$confirm({
      //   title: this.$t('ControlPanelPage.close_betting'),
      //   okText: this.$t('global.action.yes'),
      //   cancelText: this.$t('global.action.no'),
      //   onOk() {
      //     fightOpenBet({ pool_id: id, open: 0 })
      //       .then(({ data }) => {
      //         checkRoundOneSideBet({ id })
      //           .then((data) =>{
      //             self.$emit('bettingClose', id, data.data.result);
      //           })
      //       }).catch((err) => {
      //         console.log(err)
      //       });
      //   },
      //   onCancel() {},
      // });
    },
    showEdit(pool_id, fight_id) {
      this.formShow = true;
      this.fightForm.pool_id = pool_id;
      this.fightForm.fight_no = fight_id;
    },
    updateFightId(fightForm) {
      this.formShow = false;
      this.fightForm.fight_no = fightForm.fight_no;
      this.$emit('update-fightId', this.fightForm);
    },
    checkResult(result){
        this.$emit('checkResult', this.pool_id, result)
    }
  }
};
</script>
