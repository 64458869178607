<template>
  <div class="frame agents-page">
    <a-page-header
      :title="identity ? $t(`global.menu.${title}`) : ''"
      @back="() => $router.go(-1)"
    >
    <template slot="extra" v-if="(premission_action.create || premission_action.update)">
      <template v-if="isMerchant">
        <!-- Account is Merchant can create Master Agent -->
<!--        <NewMasterAgent @crated="fetch" />-->
      </template>
      <!-- <template v-else>
        <NewAgent v-if="editable" @created="fetch" :is_merchant_account="is_merchant_account"/>
      </template> -->
    </template>
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <div class="search-group">
            <a-form-model-item ref="agent_account" :label="identity ? $t(accountTitle) : ''" prop="agent_account">
              <a-input
                v-model.trim="form.agent_account"
              />
            </a-form-model-item>
            <a-form-model-item ref="status" :label="$t('global.common.status')" prop="status">
              <a-select v-model="form.status">
                <a-select-option v-for="status in statusList" :value="status.value" :key="status.value">
                  {{ $t(`global.common.active_status_list.${status.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="search-group">
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :data-source="agents"
            :pagination="pagination"
            :loading="loading"
            rowKey="account"
            @change="handleTableChange"
            :defaultExpandedRowKeys="defaultExpandedRowKeys"
            @expand="expandData"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ column.singleI18n ? $t(`AgentsPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</template>
            </template>
            <template slot="account" slot-scope="account, row">
              {{ account }} <span class="table-agent-level" :data-level="row.level" v-if="row.level === 'master'">{{ row.level }}</span>
            </template>
            <!-- <template slot="rate" slot-scope="rate">
              {{ Math.floor(Number(rate) * 1000)/10 }} ％
            </template> -->
            <template slot="active" slot-scope="active">
              <div class="table-status-item">
                <span class="status-icon" :data-status="active ? 'active' : 'close'"></span>
                <span class="status-text">{{ $t(`global.common.active_status_list.${active ? 'active' : 'close'}`) }}</span>
              </div>
            </template>
            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>
            <template slot="action" slot-scope="action, agents" v-if="(premission_action.create || premission_action.update)">
              <router-link :to="{ name: 'AgentShow', params: { id: agents.id, agentData: $data }}">
                <a-button type="primary" size="small" icon="solution">{{$t('global.common.profile')}}</a-button>
              </router-link>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getAgents, getPagePermissions } from '@/../api';
import NewAgent from '@/../src/components/agents/new';
import NewMasterAgent from '@/../src/components/agents/new_master';

const columns = [
  {
    slots: { title: 'agent_count' },
    dataIndex: 'agent_counts',
    align: 'center',
  },
  {
    slots: { title: 'player_count' },
    dataIndex: 'player_counts',
    align: 'center',
  },
  // 目前需求是要隱藏該欄位
  // {
  //   slots: { title: 'rate_title' },
  //   title_name: 'rate',
  //   dataIndex: 'rate',
  //   scopedSlots: { customRender: 'rate' },
  //   align: 'center',
  // },
  {
    slots: { title: 'currency' },
    dataIndex: 'currency',
    scopedSlots: { customRender: 'formatString' },
    align: 'center',
  },
  {
    slots: { title: 'theme' },
    dataIndex: 'theme_name',
    scopedSlots: { customRender: 'formatString' },
    align: 'center',
  },
  {
    slots: { title: 'status' },
    dataIndex: 'active',
    scopedSlots: { customRender: 'active' },
    align: 'center',
  },
  {
    slots: { title: 'action' },
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
  {
    slots: { title: 'created_at' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
  },
];

export default {
  components: {
    NewAgent,
    NewMasterAgent,
  },
  data() {
    return {
      data: [
      ],
      pagination: {},
      expand: false,
      loading: false,
      form: {
        agent_account: this.$route.query.agent,
        status: 'all'
      },
      statusList: [
        {
          label: 'all',
          value: 'all'
        },
        {
          label: 'active',
          value: 'active'
        },
        {
          label: 'close',
          value: 'close'
        }
      ],
      rules: {
      },
      agents: [],
      premission_action: {
        create: false,
        read: false,
        update: false,
        edit: false,
      },
      defaultExpandedRowKeys:[]
    };
  },
  created(){
    if(this.$route.params.updatedAgentData){
      const expandRowKeys = [];
      const { updatedAgentData } = this.$route.params;
      const { agents } = updatedAgentData;
      agents.forEach(agentItem=>{
        if(Array.isArray(agentItem.children) && agentItem.children.length > 0){
          expandRowKeys.push(agentItem.account)
        }
      })
      if(expandRowKeys.length > 0){
        updatedAgentData.defaultExpandedRowKeys = expandRowKeys;
      }
      const newData = { ...this.$data, ...updatedAgentData};
      Object.assign(this.$data, newData);
    }
  },
  computed: {
    ...mapState({ currentProfile: state => state.auth.currentProfile, 
                  identity: state => state.auth.identity,
                  parentIdentity: state => state.auth.parentIdentity,
                  isMerchantAccount: state => state.auth.isMerchantAccount,}),
    isMerchant(){
      const isMerchantIdentity = this.identity === 'merchant' || (this.identity === 'manager' && this.parentIdentity === 'merchant');
      return isMerchantIdentity;
    },
    accountTitle(){
      return this.isMerchant ? 'AgentsPage.master_account' : 'global.common.account' ;
    },
    title(){
      return this.isMerchant ? 'MasterAgentList' : 'AgentList';
    },
    columns(){
      return [{
        slots: { title: this.isMerchant ? 'master_account' : 'account' },
        dataIndex: 'account',
        scopedSlots: { customRender: 'account' },
        singleI18n: this.isMerchant
        // 放帳號、代理等級（master）會有特別標示
      }, ...columns];
    }
    // rate_min(){
    //   const fee = this.currentProfile.fee;
    //   const parseFee = parseFloat(fee);
    //   return !isNaN(parseFee) && typeof(parseFee) === 'number' ? parseFee * 100 : 0;
    // },
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      const { status } = this.form;
      getAgents({
        // results: 10,
        status,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.agents.length;
        const { agents } = data;
        agents.forEach(agentItem=>{
          if(agentItem.child_present){
            agentItem.children = [];
          }
        });
        this.agents = data.agents;
        this.pagination = pagination;
        this.editable = data.editable;
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      const { agent_account } = this.form;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.fetch({ agent_account });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      // this.fetch({});
    },
    expandData(expanded, record){
      if(this.loading) return;
      const is_open = expanded;
      if(is_open !== true){
        return;
      }
      this.loading = true;
      const agent_id = record.id;
      // const found = this.agents.find(element => element.id === record.id);
      getAgents({
        agent_id: agent_id
      }).then(({ data }) => {
        const agents = data.agents || [];
        agents.forEach(agentItem=>{
          if(agentItem.child_present){
            agentItem.children = [];
          }
        })
        record.children = agents;
      }).finally(()=>{
        this.loading = false;
      });
    },
  },
  watch: {
    identity: {
      handler(newValue, oldValue){
        if(newValue){
          if(newValue === 'manager'){
            getPagePermissions(this.$route.name)
            .then((data)=>{
              if(data.data.actions !== undefined) {
                this.premission_action.create = data.data.actions.create || false;
                this.premission_action.read = data.data.actions.read || false;
                this.premission_action.update = data.data.actions.update || false;
                this.premission_action.edit = data.data.actions.edit || false;
              }
            })
          }else{
            this.premission_action.create = true;
            this.premission_action.read = true;
            this.premission_action.update = true;
            this.premission_action.edit = true;
          }
        }
      },
      immediate: true,
    },
    // isMerchantAccount: {
    //   handler(newValue,oldValue){
    //     if(newValue === false){
    //     // this is sub account
    //       const remove_item = ['rate']
    //       columns.forEach((item, index, object)=> {
    //         if(remove_item.includes(item.title_name)){
    //           object.splice(index, 1);
    //         }
    //       })
    //     }
    //   },
    //   immediate: true
    // },
  }
};
</script>
