
export default {
  lang: 'Pусский язык(俄文)',
  global: {
    game_type: {
      cockfight: "Петушиные бои",
      spider: "Пауки",
      color_game: "Color_game",
      apple: "Яблоко",
      up_down: "Вверх Вниз"
    },
    fight_result: {
      meron: "Meron",
      wala: "Wala",
      up_down_draw: "Ничья",
      draw: "Ничья",
      cancel: "Отмена",
      red: "Красный",
      blue: "Синий",
      gold: "Золотой",
      white: "Белый",
      white3: "Белый*3",
      red3: "Красный*3",
      up: "Вверх",
      down: "Вниз"
    },
    action: {
      yes: "Да",
      no: "Нет",
      go: "Вперед",
      search: "Поиск",
      reset: "Сброс",
      create: "Создать",
      logout: "Выход",
      submit: "Отправить",
      edit: "Изменить",
      risk_edit: "Исправление рисков",
      lock_earning: "Блокировка дохода",
      decision_on_hold: "Решение в статусе ожидания",
      cancel_on_hold: "Отмена в статусе ожидания",
      not_allow_bet: "Ставки не разрешены",
      allow_bet: "Ставки разрешены",
      next: "Далее",
      prev: "Предыдущий",
      step: "Шаг",
      ok: "OK",
      collapse: "Крах",
      mask_video: "Маскировать видео",
      cancel_mask_video: "Отменить маскировку видео",
      records: "Записи",
      cancel: "Отмена",
      export: "Экспорт",
      select_file: "Выбрать файл",
      upload_image: "Загрузить",
      cancel_upload: "Отмена",
      update: "Обновить",
      update_all: "обновить все",
      upload_all_images: "Загрузить все",
      cancel_update_all: "Отменить все",
      refresh_fights: "Обновить бои",
      open_bet: "Открыть",
      close_bet: "Закрыть",
      re_declare: "Повторно объявить",
      locking: "Блокировка",
      open_arena: "Открыть Арену",
      close_arena: "Закрыть Арену",
      refresh_cache: "Обновить кэш",
      cancel_all: "Отменить все",
      delete: "Удалить",
      monitor: "Монитор",
      monitor_video: "Открыть видео на мониторе",
      retry: "Повторить попытку"
    },
    common: {
      account: "Учетная запись",
      account_id: "№",
      active_status_list: {
        all: "Все",
        active: "Активный",
        close: "Закрыть",
        enable: "Разрешить",
        disable: "Запретить"
      },
      action: "Действие",
      add_settle_bet_info: "Расчет API Добавить информацию о ставке",
      agent: "Агент",
      agent_account: "Учетная запись агента",
      agent_count: "Количество агентов",
      arena: "Арена",
      arena_suspend: "Приостановка Арены",
      all: "Все",
      amount: "Сумма",
      announcement: "Объявление",
      average_percentage: "Среднее значение за бой",
      bet_amount: "Сумма ставки",
      bet_count: "Количество ставок",
      bet_type_list: {
        all: "Все",
        deposit: "Депозит",
        withdraw: "Вывод",
        OrderDeposit: "Депозит",
        OrderWithdraw: "Вывод",
        bet: "Ставка",
        settle: "Оплатить",
        cancel: "Отмена",
        resettle: "Пересчитать",
        fail: "Ошибка",
        init: "Исходный"
      },
      callback_url: "URL обратного вызова",
      change_password: "Сменить пароль",
      confirm: "Подтвердить",
      confirm_password: "Подтверждение пароля",
      create_agent: "Создать агента",
      create_setting: "Создать настройку",
      created_at: "Создан в",
      currency: "Валюта",
      custom_logo: "Пользовательский логотип",
      custom_logo_list: {
        odin: "Odin",
        gleague: "Odin",
        fiesta: "Fiesta",
        fiestaFruit: "FiestaFruit"
      },
      date: "Дата",
      date_type: "Тип даты",
      date_type_list: {
        BetAt: "Ставка сделана в",
        GameDate: "Дата игры",
        SettleAt: "Выплатить в",
        OpenRound: "Открыть раунд"
      },
      declare_fight_winner: "Объявить победителя боя",
      defaultLanguage: "Язык по умолчанию",
      description: "Описание",
      email: "Электронная почта",
      fight_count: "Количество боев",
      fight_id: "Идентификатор боя",
      game_date: "Дата игры",
      game_type: "Тип игры",
      ip: "IP-адрес",
      id: "№",
      kill_rate: "Показатель убийств",
      language: "Язык",
      last_login: "Последний вход в систему",
      link_master: "Мастер ссылок",
      lock_earning: "Блокировка дохода",
      log: "Журнал",
      manager: "Менеджер",
      master: "Мастер",
      max: "Максимум",
      merchant: "Продавец",
      merchant_account: "Идентификатор продавца",
      meron_wala: "Meron Wala",
      min: "Минимум",
      month: "Месяц",
      multi_arena: "Вкладка",
      multi_arena_status: {
        open: "Открыть",
        close: "Закрыть"
      },
      name: "Имя",
      odd: "Коэффициент",
      odds_type: "Тип коэффициента",
      operator: "Оператор",
      options: "Параметры",
      order_num: "Номер заказа",
      order_status_list: {
        init: "Инициализация",
        process: "Процесс",
        success: "Успешно",
        failed: "Ошибка",
        pending: "В ожидании",
        fail: "Ошибка",
        error: "Ошибка"
      },
      password: "Пароль",
      payout: "Выплата",
      platform_profit: "GGR (валовый игорный доход)",
      player_account: "Учетная запись игрока",
      player_name: "Имя игрока",
      player_bet_limit: "Лимит одиночной ставки игрока",
      player_count: "Количество игроков",
      player_round_limit: "Лимит ставок игрока за раунд",
      pool_status: "Статус пула",
      profile: "Профиль",
      provider_name: "Имя провайдера",
      rate: "Ставка (%)",
      rate_amount: "Сумма ставки",
      risk_odd: "Коэффициенты риска",
      role: "Роль",
      risk_control: "Управление рисками",
      risk_type_list: {
        bet_limit: "Лимит ставок снижен",
        no_login: "Нет входа"
      },
      round_id: "Бой #",
      round_limit: "Лимит ставки за раунд",
      round_status: "Статус раунда",
      round_status_list: {
        idle: "Ожидание",
        open: "Открыть",
        close: "Закрыть",
        locked: "Заблокировано",
        last_call: "Последний звонок",
        arena_close: "Закрыть Арену",
        unsettled: "Неоплаченный",
        settled: "Оплаченный"
      },
      status: "Статус",
      setting: "Настройка",
      settled_at: "Выплатить в",
      settle_zero: "Генерировать заявки на выплату, когда сумма равна 0",
      side: "Ставка на",
      skin: "Цвет темы",
      skin_list: {
        default: "По умолчанию",
        brown: "Коричневый",
        white: "Белый",
        black: "Черный",
        green: "Зеленый",
        dark_and_light: "Темный и светлый"
      },
      slug: "Служебное поле",
      theme: "Тема",
      total: "Итого",
      total_bet_amount: "Общая сумма ставок",
      total_bet_count: "Общее количество ставок",
      total_platform_profit: "Общая прибыль платформы",
      total_rate_amount: "Общая сумма ставки",
      total_user_bet: "Всего ставок пользователей",
      total_valid_bet_amount: "Общая действительная сумма ставок",
      type: "Тип",
      updated_at: "Обновлен в",
      updated_setting: "Обновить настройки",
      usable_language: "Используемый язык",
      user: "пользователь",
      user_count: "Количество пользователей",
      video_url: "URL-адрес видео",
      valid_bet_amount: "Действительная сумма",
      winner: "Победитель"
    },
    times: {
      unset: "Возврат к исходному значению",
      today: "Сегодня",
      yesterday: "Вчера",
      this_week: "На этой неделе",
      last_week: "На прошлой неделе",
      this_month: "Этот месяц",
      last_month: "Прошлый месяц",
      custom: "Пользовательский",
      tooltip: "Хранение записей: 3 месяца (сегодняшнее число - 100 дней)"
    },
    menu: {
      group: {
        player: "Игрок",
        record: "Записи",
        manager: "Менеджер",
        control: "Панель управления",
        Agent: "Агент",
        MasterAgent: "Мастер-агент",
        ArenaModerator: "Модератор арены",
        Pool: "Пул"
      },
      SingleModerator: "Управление пулом",
      MultiModerator: "Модератор раунда",
      ControlPanel: "Панель управления",
      CurrentArena: "Текущая арена",
      Players: "Игроки",
      PlayerCredits: "Кредиты игрока",
      BetLog: "Журнал ставок",
      GameResults: "Результаты игры",
      Order: "Порядок",
      AgentList: "Список агентов",
      AgentSource: "Ссылка на мастер-агента",
      AdminAgents: "Агенты",
      AgentShow: "Показ агента",
      AgentRoles: "Роли агента",
      AgentSubAccount: "Менеджер",
      MasterAgentList: "Список мастер-агентов",
      MasterAgentSource: "Ссылка на мастер-агента",
      Merchants: "Продавцы",
      MerchantRoles: "Роль продавца",
      MerchantAdmin: "Администратор продавца",
      MerchantIpList: "Настройки ограничения IP",
      ChangePassword: "Сменить пароль",
      GGR: "GGR (валовый игорный доход)",
      Profile: "Профиль",
      VideoSource: "Источник видео",
      Transfer: "Порядок запроса",
      MonthlyProfit: "Ежемесячная прибыль",
      DailyBettingStatistic: "Ежедневная статистика ставок",
      ExchangeRate: "Обменный курс",
      CreateAgent: "Создать агента",
      PoolSetting: "Настройка пула",
      MalayOddsSetting: "Настройка малайских коэффициентов"
    },
    message: {
      fill_input: "Введите {value}.",
      select_value: "Выберите (n) {value}.",
      must_be_at_4_characters: "{value} должно содержать не менее 4 символов",
      must_be_at_6_characters: "{value} должно содержать не менее 6 символов",
      must_be_between_characters: "{value} должно содержать от {min} до {max} символов",
      must_be_between_number: "{value} должно быть между {min} и {max}",
      must_be_alphanumeric: "{value} должно быть буквенно-цифровым",
      must_be_number: "Должно быть числом",
      must_be_number_value: "{value} должно быть числом",
      two_no_match: "Два введенных значения не совпадают!",
      failed_to_create: "Не удалось создать {value}",
      failed_to_edit: "Не удалось изменить {value}",
      success_to_create: "Успешно создано {value}",
      success_to_edit: "Успешно изменено {value}",
      success_to_change: "{value} успешно изменено",
      failed_to_update: "{value} не удалось обновить",
      decision_on_hold: "После нажатия Арена будет приостановлена.",
      cancel_on_hold: "После нажатия запустится Арена.",
      close_bet: "После нажатия ставка на Арене закроется.",
      open_bet: "После нажатия ставка на Арене откроется.",
      mask_video: "После нажатия GC Video будет замаскировано.",
      cancel_mask_video: "После нажатия запустится GC Video.",
      delete: "Удалить {value}?",
      choose_arena: "Выберите Арену",
      enter_fight_number: "Введите стартовый номер боя",
      select_date: "Выберите дату",
      select_one: "Выберите хотя бы одно {value}",
      browser_not_supported: "Этот браузер не поддерживается. Нажмите кнопку ОК, чтобы открыть Google Chrome или Safari.",
      network_error_title: "СЕТЕВАЯ ОШИБКА",
      network_error_info: "Проверьте Интернет-соединение и обновите страницу",
      must_be_greater_than_zero: "сумма должна быть больше или равна 0",
      must_be_greater_than_min: "максимальная сумма должна быть больше или равна минимальной сумме",
      incorrect_ip: "Неправильный формат IP",
      criteria_be_selected: "Необходимо выбрать критерии поиска",
      success_to_delete: "{value} успешно удалено",
      failed_to_delete: "Не удалось удалить {value}",
      failed_to_delete_with_reason: "{value} не удалось обновить: {reason}",
      account_placeholder: "4–16 символов, только буквенно-цифровые.",
      password_placeholder: "6–20 символов",
      confirm_password_placeholder: "Введите тот же пароль еще раз",
      date_type_hint: "Отчет основан на「{dateType}」. Вчерашние данные будут генерироваться ежедневно в 10:00 (GMT+8), за исключением сегодняшних данных.",
      please_create_role: "Прежде чем создать менеджера, создайте одну роль.",
      max_length: "Максимальная длина {value} – {length}",
      whole_url: "Введите URL полностью.",
      A_greater_tham_B: "{value_a} должно быть больше, чем {value_b}",
      setting_player_round_max: "Установить минимальную и максимальную суммы",
      setting_player_bet_max: "Установить максимальную сумму",
      reset_data: "Сбросить данные?",
      monitor: "После нажатия откройте страницу мониторинга.",
      failed_to_monitor: "Не удалось открыть страницу мониторинга."
    }
  },
  AgentsPage: {
    master_account: "Идентификатор мастер-агента",
    languageList: "Список языков",
    create_master_agent: "Создать мастер-агента",
    rate: "Ставка комиссии (%)",
    rate_note: "Если ставка комиссии нижестоящего агента выше, чем ставка комиссии вышестоящего агента, система автоматически снизит ее до новой ставки комиссии.",
    max: "Максимум: {value}",
    abbr: "Сокращение",
    abbr_placeholder: "1–6 символов",
    edit_agent: "Изменить агента"
  },
  AgentSubAccountPage: {
    authority: "Полномочие",
    authority_list: {
      read: "Читать",
      editor: "Редактор"
    },
    create_sub_account: "Создать менеджера"
  },
  BetLogPage: {
    win_lose: "Выиграть Проиграть",
    bet_return: "Возврат ставки",
    is_settled: "Выплачено",
    device: "Устройство",
    amount_type: "Тип сумм",
    amount_range: "Диапазон сумм"
  },
  ChangePasswordPage: {
    current_password: "Текущий пароль",
    current_password_placeholder: "Введите текущий пароль",
    new_password: "Новый пароль"
  },
  ArenaModeratorPage: {
    video_rate: "Скорость видео",
    online_users: "Онлайн-пользователи"
  },
  ControlPanelPage: {
    arena_on: "ВКЛ.",
    arena_off: "ВЫКЛ.",
    no_announce: "Нет объявлений. Нажмите «Изменить», чтобы обновить.",
    edit_announce: "Изменить объявление",
    open_arena_title: "Открыть Арену?",
    close_arena_title: "Хотите закрыть Арену?",
    start_fight: "Идентификатор начала боя",
    start_time: "Время начала",
    open_betting: "Открыть ставки?",
    close_betting: "Закрыть ставки?",
    re_declare_title: "Повторно объявить?",
    re_declare_content: "Повторно объявить победителя боя с идентификатором {id}",
    lock_earing_title: "Заблокировать полученные средства?",
    lock_earing_content: "Заблокировать полученные средства за бой с идентификатором {id}",
    click_again_to: "Нажмите еще раз, чтобы",
    file_upload: "Файл загружен",
    arena_cover: "Обложка Арены",
    mask_file: "Файл маски",
    schedule: "График",
    edit_provider_url: "URL-адрес провайдера",
    img_path: "Путь к изображению",
    below_px_value: "менее {value} пикселей",
    img_format: "Формат",
    img_recommended_size: "Размер",
    file_size: "Размер файла",
    image: "Изображение",
    file_no_selected: "файл не выбран",
    no_image: "Нет изображения",
    edit_video_url: "Изменить URL-адрес видео",
    pool_fight_number: "Пул/Бой №",
    round_master: "Мастер раунда",
    message: {
      input_fight: "Введите номер боя",
      max_image_size: "Максимальный размер изображения: {value}КБ.",
      error_image_type: "Тип ошибки изображения. Принимаются только: jpg, jpeg, png",
      delete_image_placeholder: "Хотите удалить {value}?",
      success_to_earn_locked: "Удалось заблокировать доход за бой с идентификатором {value}.",
      fail_to_earn_locked: "Не удалось заблокировать доход за бой с идентификатором {value}. Причина: {reason}.",
      success_to_cancel_bet: "Все ставки на бой с идентификатором {value} успешно отменены.",
      fail_to_cancel_bet: "Не удалось отменить все ставки на бой с идентификатором {value}. Причина: {reason}."
    }
  },
  CurrentArenaPage: {
    count: "Счет",
    unsettlement: "Невыплата",
    fight_action: "Действие в бою"
  },
  GameResultsPage: {
    open_bet_at: "Открыть ставки на",
    close_bet_at: "Закрыть ставки на",
    declare_at: "Объявить в",
    reload_at: "Перезагрузить в",
    cancel_at: "Отменить в",
    total_rate: "Общий показатель",
    total_meron: "Итог Meron",
    total_wala: "Итог Wala",
    total_draw: "Итог ничья",
    status_list: {
      initial: "Исходный",
      betting: "Делать ставки",
      fighting: "Бой",
      finished: "Закончен",
      reloaded: "Перезагружен",
      earn_locked: "Оплаченный",
      last_call: "Последний звонок",
      cancel: "Отмена"
    },
    time: "Время",
    reason: "Причина",
    cancel_reasons: {
      3000: "Односторонняя ставка",
      3001: "Коэффициенты недоступны",
      canceled_manual: "Бой отменен – Руководство",
      canceled_ws: "Бой отменен – Веб-хук"
    },
    open_round: "Открыть раунд"
  },
  LoginPage: {
    log_in: "Вход",
    otp: "Одноразовый пароль",
    message: {
      success: "Вход выполнен!",
      failed: "Не удалось ввести учетную запись или пароль."
    }
  },
  MerchantsPage: {
    admin: "Администратор",
    role: "Роль",
    create_merchant: "Создать продавца",
    name: "Имя продавца",
    message: {
      master_agent_only_select_one: "Можно выбрать только один вариант Мастер/Агент."
    }
  },
  MerchantRolesPage: {
    current_arena: "Текущая арена",
    can_permission_pages: "Страницы",
    use_account: "Использовать учетные записи",
    create_role: "Создать роль",
    edit_role: "Изменить роль",
    role_name: "Имя роли",
    color: "Цвет",
    can_use_pages: "Доступные страницы",
    arena_on_off: "Арена вкл./выкл.",
    fight_control: "Управление боем",
    lock_earning: "Блокировка дохода",
    announcement: "Объявление",
    decision_on_hold: "Решение в статусе ожидания",
    monitor: "Видеомонитор",
    no_bets_allowed: "Ставки не разрешены",
    mask_video: "Маскировать видео",
    read: "Читать",
    update: "Обновить",
    create: "Создать",
    delete: "Удалить",
    edit: "Изменить",
    dashboard_card: "Показать итог",
    confirm: "Подтвердить",
    approve: "Утвердить",
    arena_cover: "Обложка Арены",
    mask_file: "Файл маски",
    schedule: "График",
    export: "Экспорт",
    edit_provider_url: "URL-адрес провайдера",
    display_rate: "Отобразить курс",
    account_to_use: "Есть еще учетная запись для использования этой роли",
    view_schedule: "Посмотреть график",
    view_log: "Посмотреть журнал",
    risk_edit: "Исправление рисков"
  },
  MerchantAdminPage: {
    edit_manager: "Изменить менеджера",
    create_manager: "Создать менеджера"
  },
  MerchantIpPage: {
    master_agent: "Мастер или Агент",
    create_ip: "Создать IP-адрес"
  },
  DailyBettingStatisticPage: {
    yesterday_percentage: "TVA вчера %",
    last_week_percentage: "TVA за последнюю неделю %",
    user_count: "Количество пользователей",
    ave_per_fight_percentage: "APF вчера %",
    last_week_ave_per_fight_percentage: "APF за последнюю неделю %"
  },
  OrderPage: {
    after_balance: "Последующий баланс"
  },
  PlayersPage: {
    balance: "Баланс",
    fee: "Комиссия",
    current_sign_in_ip: "Текущий IP-адрес для входа",
    total_deposit: "Общий депозит",
    total_withdraw: "Общий вывод",
    total_win_lose: "Общий выигрыш, проигрыш"
  },
  PlayerCreditsPage: {
    id: "Идентификатор",
    type: {
      deposit: "Депозит",
      withdraw: "Вывод",
      settle: "Порядок расчета",
      cancel: "Порядок отмены",
      bet: "Ставка"
    },
    before_amount: "Сумма до",
    after_amount: "Сумма после"
  },
  ProfilePage: {
    setting_round_draw_max: "Установка общей максимальной суммы за 1 раунд",
    setting_round_draw_max_part1: "Установить максимальную сумму",
    setting_round_draw_max_part2: "в 1 раунде",
    player_limit: "Лимит ставки игрока",
    setting_player_max: "Установить минимальную и максимальную суммы",
    basic_info_title: "Основная информация",
    basic_info_text: "Основная информация о вашем агенте",
    integration_info: "Информация об интеграции",
    auth: "Авторизация",
    secret: "Секретный ключ",
    api_doc_url: "URL-адрес документа API",
    api_url: "URL-адрес API",
    video_traffic: "Видеотрафик",
    remain_video_data: "Оставшиеся видеоданные",
    update_video_data: "Обновить видеоданные"
  },
  VideoSourcePage: {
    source: "Источник видео",
    agent_source: "Источник видео мастер-агента",
    accessable_merchants: "Доступные продавцы"
  },
  TransferPage: {
    slug: "Служебное поле",
    data: "Данные",
    res: "Ответ",
    round_id: "Идентификатор раунда",
    setting_master_id: "Установка идентификатора мастера",
    num: "число",
    resend: "Переслать",
    bet_num: "Ставка №",
    resend_note: "Примечание",
    resend_mark: "Отметка",
    message: {
      success_to_resend: "Успешная повторная отправка, Нет: {value}",
      fail_to_resend: "Не удалось отправить повторно, Нет: {value}",
      success_to_remark: "Успешно отмечено, Нет: {value}",
      fail_to_remark: "Не удалось отметить, Нет: {value}"
    }
  },
  ExchangeRatePage: {
    currency: "Валюта",
    currency_name: "Название валюты",
    last_approved_rate: "Последний курс",
    today_rate: "Сегодняшний курс",
    ref_rate: "Справочный курс",
    ref_rate_time: "Справочное время",
    tbd_confirm_rate: "Подтвердить курс",
    tbd_modify_rate: "Изменить курс",
    record: "Записать",
    current_time: "Текущее время",
    last_approved_time: "Время последнего утверждения",
    base_currency: "Основная валюта",
    fetch_exchange_rate: "Получить обменный курс",
    approve_rate: "Утвердить курс",
    rate_warning: "*Внимание! Обменный курс вступит в силу сразу после утверждения!",
    log: "Журнал курсов валют",
    confirmed_by: "Кем подтверждено",
    confirmed_time: "Время подтверждения",
    approved_by: "Кем утверждено",
    approved_time: "Время утверждения",
    exchange_rate: "Обменный курс"
  },
  CreateAgentPage: {
    parent_agent: "Родительский агент",
    environment: "Среда",
    staging: "Проведение",
    production: "Производство",
    brand: "Бренд",
    trade_mode: "Режим торговли",
    transfer_wallet: "Кошелек для переводов",
    single_wallet: "Один кошелек",
    level_sub_agent_with_value: "Субагент уровня {value}",
    delete_agent_with_value: "Удалить субагента уровня {value}",
    add_agent_with_value: "Добавить субагента уровня {value}",
    agent_info: "Информация об агенте",
    message: {
      select_master_first: "Сначала выберите мастер-агента.",
      agent_same_master: "Родительский агент такой же, как мастер.",
      please_refresh_cache: "Обновите кэш, чтобы получить новый список агентов.",
      log_saved_time: "Каждый журнал может храниться до {value} часов.",
      success_refresh_cache: "Успешное обновление кэша.",
      fail_refresh_cache: "Не удалось обновить кэш."
    }
  },
  PoolSettingPage: {
    pool_name: "Имя пула",
    odds_refresh_frequency: "Частота обновления",
    last_call_percentage: "Последний звонок (%)",
    close_bet_percentage: "Закрыть ставку (%)",
    per_bet: "За ставку",
    per_round: "За раунд",
    risk_threshold: "Порог риска",
    current_schedule: "Текущий график",
    new_schedule: "Новый график",
    update_schedule: "Обновить график",
    schedule: "График",
    view: "Вид",
    start_time: "Время начала",
    end_time: "Время окончания",
    pool_configuration_options: "Параметры конфигурации пула",
    expect_date: "Ожидаемая дата вступления в силу",
    expect_time: "Ожидаемое время вступления в силу",
    add_new_pool_configuration: "Добавить новую конфигурацию пула",
    exceed_time: "Текущее время превысило ожидаемое время вступления в силу",
    effective_time: "Время вступления в силу",
    import_current_schedule: "Импорт текущего графика"
  }
}
