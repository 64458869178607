export default {
  lang: 'English(英文)',
  global: {
    game_type: {
      cockfight: 'Cockfight',
      spider: 'Spider',
      color_game: 'Color Game',
      apple: 'Apple',
      up_down: 'Up Down',
      event: 'Event',
    },
    fight_result: {
      meron: 'Meron',
      wala: 'Wala',
      draw: 'Draw',
      up_down_draw: 'Draw',
      cancel: 'Cancel',
      red: 'Red',
      blue: 'Blue',
      gold: 'Gold',
      white: 'White',
      red3: 'Red*3',
      white3: 'White*3',
      down: 'Down',
      up: 'Up',
    },
    action: {
      yes: 'Yes',
      no: 'No',
      go: 'Go',
      search: 'Search',
      reset: 'Reset',
      create: 'Create',
      logout: 'Logout',
      submit: 'Submit',
      edit: 'Edit',
      risk_edit: 'Risk Edit',
      lock_earning: 'Lock Earning',
      decision_on_hold: 'Decision on Hold',
      cancel_on_hold: 'Cancel on Hold',
      not_allow_bet: 'No Bets Allowed',
      allow_bet: 'Bets Allowed',
      next: 'Next',
      prev: 'Previous',
      step: 'Step',
      ok: 'OK',
      collapse: 'Collapse',
      mask_video: 'Mask Video',
      cancel_mask_video: 'Cancel Mask Video',
      records: 'Records',
      cancel: 'Cancel',
      export: 'Export',
      select_file: 'Select File',
      upload_image: 'Upload',
      cancel_upload: 'Cancel',
      update: 'Update',
      update_all: 'update all',
      upload_all_images: 'Upload All',
      cancel_update_all: 'Cancel All',
      refresh_fights: 'Refresh Fights',
      open_bet: 'Open',
      close_bet: 'Close',
      re_declare: 'RE-Declare',
      locking: 'Locking',
      open_arena: 'Open Arena',
      close_arena: 'Close all Rounds under Pool',
      refresh_cache: 'Refresh Cache',
      cancel_all: 'Cancel All',
      delete: 'Delete',
      monitor: 'Monitor',
      monitor_video: 'Open Monitor Video',
      retry: 'Retry',
    },
    common: {
      account: 'Account',
      account_id: 'No.',
      active_status_list: {
        all: 'All',
        active: 'Active',
        close: 'Close',
        enable: 'Enable',
        disable: 'Disable'
      },
      action: 'Action',
      add_settle_bet_info: 'Settle API Add Bet Info',
      agent: 'Agent',
      agent_account: 'Agent ID',
      agent_count: 'Agent Counts',
      arena: 'Arena',
      arena_suspend: 'Arena Suspend',
      all: 'All',
      amount: 'Amount',
      announcement: 'Announcement',
      average_percentage: 'Avg per Fight',
      bet_amount: 'Bet Amount',
      bet_count: 'Bet Counts',
      bet_type_list: {
        all: 'All',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        OrderDeposit: 'Deposit',
        OrderWithdraw: 'Withdraw',
        bet: 'Bet',
        settle: 'Settle',
        cancel: 'Cancel',
        resettle: 'Resettle',
        fail: "Fail",
        init: "Initial"
      },
      callback_url: 'Callback URL',
      change_password: 'Change Password',
      confirm: 'Confirm',
      confirm_password: 'Confirm password',
      create_agent: 'Create Agent',
      create_setting: 'Create Setting',
      created_at: 'Created at',
      currency: 'Currency',
      custom_logo: 'Custom Logo',
      custom_logo_list: {
        odin: 'Odin',
        gleague: 'Gleague',
        fiesta: 'Fiesta',
        fiestafruit: 'FiestaFruit',
      },
      date: 'Date',
      date_type: 'Date Type',
      date_type_list: {
        BetAt: 'Beted At',
        GameDate: 'Game Date',
        SettleAt: 'Settled At',
        OpenRound: 'Open Round',
      },
      declare_fight_winner: 'Declare Fight Winner',
      defaultLanguage: 'Default Language',
      description: 'Description',
      email: 'E-mail',
      fight_count: 'Fight Counts',
      fight_id: 'Fight ID',
      game_date: 'Game Date',
      game_type: 'Game Type',
      ip: 'IP',
      id: 'No.',
      kill_rate: 'Kill Rate',
      language: 'Language',
      last_login: 'Last Login',
      link_master: "Link Master",
      lock_earning: 'Lock Earning',
      log: 'Log',
      main_arena: 'Main Arena',
      main_pool: 'Main Pool',
      manager: 'Manager',
      master: 'Master',
      max: 'Maximum',
      merchant: 'Merchant',
      merchant_account: 'Merchant ID',
      meron_wala: 'Meron Wala',
      min: 'Minimum',
      month: 'Month',
      multi_arena: 'Tab',
      multi_arena_status: {
        open: "Open",
        close: "Close"
      },
      name: 'Name',
      odd: 'Odds',
      odds_type: "Odds Type",
      odds_type_list: {
        rake: "Rake",
        malay: "Malay",
        static: "Static"
      },
      operator: 'Operator',
      options: 'Options',
      order_num: 'Order No.',
      order_status_list: {
        init: 'Initial',
        process: "Process",
        success: "Success",
        failed: "Failed",
        pending: "Pending",
        fail: 'Failed',
        error: 'Error',
      },
      password: 'Password',
      payout: 'Payout',
      platform_profit: 'GGR',
      player_account: 'Player Account',
      player_name: 'Player Name',
      player_bet_limit: 'Player Single Bet Limit',
      player_count: 'Player Counts',
      player_round_limit: 'Player Round Bet Limit',
      pool_status: 'Pool Status',
      profile: 'Profile',
      provider_name: 'Provider Name',
      rate: 'Rate (%)',
      rate_amount: 'Rate Amount',
      risk_odd: 'Risk Odds',
      role: 'Role',
      risk_control: 'Risk Control',
      risk_type_list: {
        bet_limit: 'Betting Limit Reduced',
        no_login: 'No Login',
      },
      round_id: 'Fight #',
      round_limit: 'Round Bet’s Limit',
      round_status: 'Round Status',
      round_status_list: {
        idle: 'Idle',
        open: 'Open',
        close: 'Close',
        locked: 'Locked',
        last_call: 'Last Call',
        arena_close: 'Arena Close',
        unsettled: 'Unsettled',
        settled: 'Settled',
      },
      status: 'Status',
      setting: 'Setting',
      settled_at: 'Settled at',
      settle_zero: 'Generate Settle orders when the amount is 0',
      side: 'Bet On',
      skin: 'Theme Color',
      skin_list: {
        default: 'Default',
        brown: 'Brown',
        white: 'White',
        black: 'Black',
        green: 'Green',
        dark_and_light: 'Dark and Light',
      },
      slug: 'Slug',
      theme: 'Theme',
      total: 'Total',
      total_bet_amount: 'Total Bet Amount',
      total_bet_count: 'Total Bet Counts',
      total_platform_profit: 'Total GGR',
      total_rate_amount: 'Total Rate Amount',
      total_user_bet: 'Total User Bets',
      total_valid_bet_amount: 'Total Valid Bet Amount',
      type: 'Type',
      update_setting: 'Update Setting',
      updated_at: 'Updated at',
      usable_language: 'Usable Language',
      user: 'user',
      user_count: 'User Count',
      video_url: 'Video URL',
      icp_video_url: "ICP URL",
      valid_bet_amount: 'Valid Amount',
      winner: 'Winner',
    },
    times: {
      unset: 'Unset',
      today: 'Today',
      yesterday: 'Yesterday',
      this_week: 'This Week',
      last_week: 'Last Week',
      this_month: 'This Month',
      last_month: 'Last Month',
      custom: 'Custom',
      tooltip: "Records retention: 3 months (today's date - 100 days)",
    },
    menu: {
      group: {
        player: 'Player',
        record: 'Records',
        manager: 'Manager',
        control: 'Control Panel',
        Agent: 'Agent',
        MasterAgent: 'Master Agent',
        ArenaModerator: 'Arena Moderator',
        Pool: 'Pool'
      },
      SingleModerator: 'Pool Management',
      MultiModerator: 'Round Moderator',
      ControlPanel: 'Pool Management',
      CurrentArena: 'Current Arena',
      Players: 'Players',
      PlayerCredits: 'Player Credits',
      BetLog: 'Bet Log',
      GameResults: 'Fight Results',
      Order: 'Order',
      AgentList: 'Agent List',
      AgentSource: 'Master Agent Link',
      AdminAgents: 'Agents',
      AgentShow: 'Agent Show',
      AgentRoles: 'Agent Roles',
      AgentSubAccount: 'Manager',
      MasterAgentList: 'Master Agent List',
      MasterAgentSource: 'Master Agent Link',
      Merchants: 'Merchants',
      MerchantRoles: 'Merchant Role',
      MerchantAdmin: 'Merchant Admin',
      MerchantIpList: 'IP Restriction Settings',
      ChangePassword: 'Change Password',
      GGR: 'GGR',
      Profile: 'Profile',
      VideoSource: 'Video Source',
      Transfer: 'Order Inquiry',
      MonthlyProfit: 'Monthly Profit',
      ExchangeRate: 'Exchange Rate',
      DailyBettingStatistic: 'Daily Betting Statistics',
      CreateAgent: 'Create Agent',
      PoolSetting: 'Pool Setting',
      MalayOddsSetting: 'Malay Odds Setting'
    },
    message: {
      fill_input: 'Please enter the {value}.',
      select_value: 'Please select a(n) {value}.',
      must_be_at_4_characters: '{value} must be at least 4 characters',
      must_be_at_6_characters: '{value} must be at least 6 characters',
      must_be_between_characters: '{value} must be {min} to {max} characters',
      must_be_between_number: '{value} must be between {min} and {max}',
      must_be_alphanumeric: '{value} must be alphanumeric',
      must_be_number: 'Must be number',
      must_be_number_value: '{value} must be number',
      two_no_match: 'Two inputs don\'t match!',
      failed_to_create: 'Failed to create {value}',
      failed_to_edit: 'Failed to edit {value}',
      success_to_create: 'Success to create {value}',
      success_to_edit: 'Success to edit {value}',
      success_to_change: '{value} successfully modified',
      failed_to_update: '{value} failed to update',
      decision_on_hold: 'Once clicked, the Arena will on hold. ',
      cancel_on_hold: 'Once clicked, the Arena will started. ',
      close_bet: 'Once clicked, the Arena will close bet.',
      open_bet: 'Once clicked, the Arena will open bet.',
      mask_video: 'Once clicked, GC Video will be masked.',
      cancel_mask_video: 'Once clicked, GC Video will be started.',
      delete: 'Delete {value} ?',
      choose_arena: 'Choose the Arena',
      enter_fight_number: 'Enter Start Fight Number',
      select_date: 'Please select a date',
      select_one: 'Please select at least one {value}',
      browser_not_supported: 'This browser is not supported. Please click OK button to open google chrome or safari.',
      network_error_title: 'NETWROK ERROR',
      network_error_info: 'Please Check Your Internet and Refresh the Page',
      must_be_greater_than_zero: 'amount must be greater than or equal to 0',
      must_be_greater_than_min: 'max amount must be greater than or equal to min amount',
      incorrect_ip: 'Incorrect IP format',
      criteria_be_selected: 'A search criteria must be selected',
      success_to_delete: '{value} deleted successfully',
      failed_to_delete: '{value} deletion failed',
      failed_to_update_with_reason: '{value} failed to update: {reason}',
      account_placeholder: '4-16 characters, only alphanumeric.',
      password_placeholder: '6-20 characters',
      confirm_password_placeholder: 'Please enter the same password again',
      date_type_hint: "Report is based on the「{dateType}」. Yesterday's data would be generated at 10:00(GMT+8) every day, excluding today's data.",
      please_create_role: 'Before create Manager, Please creat one role.',
      max_length: '{value}\'s max length is {length}',
      whole_url: 'Please enter whole URL.',
      setting_player_round_max: "Set the maximum Amount ​​of the player's bet limit every round.",
      setting_player_bet_max: 'Set the minimum and maximum Amount ​​of the player’s single bet.',
      A_greater_tham_B: '{value_a} must be greater than {value_b}',
      reset_data: 'Reset data？',
      monitor: 'Once clicked, open the monitoring page.',
      failed_to_monitor: 'Failed to open monitoring page.',
    },
  },
  AgentsPage: {
    master_account: 'Master Agent ID',
    languageList: 'language list',
    create_master_agent: 'Create Master Agent',
    rate: 'Commission Rate (%)',
    rate_note: "If the downline agent's Commission Rate is higher than the upline agent's Commission Rate, the system will automatically reduce it to a new Commission Rate.",
    max_with_value: 'Maximum: {value}',
    abbr: 'Abbreviation',
    abbr_placeholder: '1-6 characters',
    edit_agent: 'Edit Agent',
  },
  AgentSubAccountPage: {
    authority: 'Authority',
    authority_list: {
      read: 'Read',
      edit: 'Edit',
    },
    create_sub_account: 'Create Manager',
  },
  BetLogPage: {
    win_lose: 'Win Lose',
    bet_return: 'Bet Return',
    is_settled: 'Is Settled',
    device: 'Device',
    amount_type: 'Amount Type',
    amount_range: 'Amount Range',
  },
  ChangePasswordPage: {
    current_password: 'Current Password',
    current_password_placeholder: 'Please enter current password',
    new_password: 'New Password',
  },
  ArenaModeratorPage: {
    video_rate: 'Rate(%)',
    online_users: 'Online Users',
  },
  ControlPanelPage: {
    arena_on: 'ON',
    arena_off: 'OFF',
    no_announce: 'No Announcement, please click "edit" to update',
    edit_announce: 'Edit Announcement',
    open_arena_title: 'Open Arena?',
    close_arena_title: 'Are you sure to close Arena?',
    start_fight: 'Start Fight ID',
    start_time: 'Start time',
    open_betting: 'Open Betting ?',
    close_betting: 'Close Betting ?',
    re_declare_title: 'RE-Declare?',
    re_declare_content: 'RE-Declare Fight ID {id} winner',
    lock_earing_title: 'Lock Earning?',
    lock_earing_content: 'Lock Earning Fight ID {id}',
    click_again_to: 'Click again to',
    file_upload: 'File Upload',
    arena_cover: 'Arena Cover',
    mask_file: 'Mask File',
    schedule: 'Schedule',
    edit_provider_url: 'Provider Url',
    img_path: 'Image Path',
    below_px_value: 'below h{value}px',
    img_format: 'Format',
    img_recommended_size: 'Size',
    file_size: 'File Size',
    image: 'Image',
    file_no_selected: 'no file selected',
    no_image: 'No Image',
    edit_video_url: 'Edit Video URL',
    pool_fight_number: 'Pool/Fight #',
    round_master: 'Round Master',
    message: {
      input_fight: 'Please enter Fight number',
      max_image_size: 'Image max size is {value}kb.',
      error_image_type: 'Error Image Type. Only Accept: jpg, jpeg, png',
      delete_image_placeholder: 'Are you sure to delete {value}?',
      success_to_earn_locked: "Success to Lock Earning for Fight ID {value}.",
      fail_to_earn_locked: "Fail to Lock Earning for Fight ID {value}. The reason is {reason}.",
      success_to_cancel_bet: "Successfully canceled all bets for Fight ID {value}.",
      fail_to_cancel_bet: "Failed to cancel all bets for Fight ID {value}. The reason is {reason}.",
      affect_all_round: "It will affect all Rounds under {value}."
    }
  },
  CurrentArenaPage: {
    count: 'Count',
    unsettlement: 'Unsettlement',
    fight_action: "Fight Action",
  },
  GameResultsPage: {
    open_bet_at: 'Open bet at',
    close_bet_at: 'Close bet at',
    declare_at: 'Declare at',
    reload_at: 'Reload at',
    cancel_at: 'Cancel at',
    total_rate: 'Total rate',
    total_meron: 'Total Meron',
    total_wala: 'Total Wala',
    total_draw: 'Total Draw',
    status_list: {
      initial: 'Initial',
      betting: 'Betting',
      fighting: 'Fighting',
      finished: 'Finished',
      reloaded: 'Reloaded',
      earn_locked: 'Settled',
      last_call: 'Last Call',
      cancel: 'Cancel',
    },
    time: 'Time',
    reason: 'Reason',
    cancel_reasons: {
      canceled_manual: 'Fight canceled-Manual',
      canceled_ws: 'Fight canceled-Webhook',
      '3001': 'Odds unavailable',
      '3000': 'One-sided bet',
    },
    open_round: 'Open round',
  },
  GGRPage: {},
  LoginPage: {
    log_in: 'Log In',
    otp: 'OTP',
    message: {
      success: 'Login Success！',
      failed: 'Account or Password enter failed.',
    }
  },
  MerchantsPage: {
    admin: 'Admin',
    role: 'Role',
    create_merchant: 'Create Merchant',
    merchant_name: 'Merchant Name',
    message: {
      master_agent_only_select_one: "Only one option can be selected from Master / Agent."
    }
  },
  MerchantRolesPage: {
    current_arena: 'Current Arena',
    can_permission_pages: 'Pages',
    use_account: 'Use Accounts',
    create_role: 'Create role',
    edit_role: 'Edit Role',
    role_name: 'Role Name',
    color: 'Color',
    can_use_pages: 'Accessable Pages',
    arena_on_off: 'Arena on/off',
    fight_control: 'Fight control',
    lock_earning: 'Lock earning',
    announcement: 'Announcement',
    decision_on_hold: 'Decision on hold',
    monitor: 'Video Monitor',
    no_bets_allowed: 'No Bets Allowed',
    mask_video: 'Mask Video',
    read: 'Read',
    update: 'Update',
    create: 'Create',
    delete: 'Delete',
    edit: 'Edit',
    dashboard_card: 'Display Total',
    confirm: 'Confirm',
    approve: 'Approve',
    arena_cover: 'Arena Cover',
    mask_file: 'Mask File',
    schedule: 'Schedule',
    export: 'Export',
    edit_provider_url: 'Provider Url',
    display_rate: 'Display Rate',
    view_schedule: 'View Schedule',
    view_log: 'View Log',
    account_to_use: 'There is still an account to use this role',
    risk_edit: 'Risk Edit',
    monitor_video: 'Monitor Video',
  },
  MerchantAdminPage: {
    edit_manager: 'Edit Manager',
    create_manager: 'Create Manager',
  },
  MerchantIpPage: {
    master_agent: "Master or Agent",
    create_ip: 'Create IP',
  },
  MonthlyProfitPage: {},
  DailyBettingStatisticPage: {
    yesterday_percentage: 'TVA Yesterday %',
    last_week_percentage: 'TVA Last Week %',
    player_count: 'User Count',
    ave_per_fight_percentage: 'APF Yesterday %',
    last_week_ave_per_fight_percentage: 'APF Last Week %',
  },
  OrderPage: {
    after_balance: 'After Balance',
  },
  PlayersPage: {
    balance: 'Balance',
    fee: 'Fee',
    current_sign_in_ip: 'Current Sign In IP',
    total_deposit: 'Total Deposit',
    total_withdraw: 'Total Withdraw',
    total_win_lose: 'Total Win Lose',
  },
  PlayerCreditsPage: {
    id: 'ID', //交易流水號
    type_list: {
      deposit: 'Deposit',
      withdraw: 'Withdraw',
      settle: 'Order Settle',
      cancel: 'Order Cancel',
      bet: 'Bet',
    },
    before_amount: 'Before Amount',
    after_amount: 'After Amount',
  },
  ProfilePage: {
    setting_round_total_max: 'Setting 1 round Total Maximum Amount',
    setting_round_draw_max_part1: 'Set Maximum Amount of ',
    setting_round_draw_max_part2: ' in 1 round',
    player_limit: 'Player Bet’s Limit',
    setting_player_max: 'Set the minimum and maximum Amount ​​of the player’s 1 round betting limit.',
    basic_info_title: 'Basic information',
    basic_info_text: 'Basic information about your Agent',
    integration_info: 'Integration Information',
    auth: 'Auth',
    secret: 'Secret Key',
    api_doc_url: 'API Document Url',
    api_url: 'API Url',
    video_traffic: 'Video Traffic',
    remain_video_data: 'Remaining Video Data',
    update_video_data: 'Update Video Data',
  },
  VideoSourcePage: {
    source: 'Video Source',
    agent_source: 'Master Agent Video Source',
    accessable_merchants: 'Accessable Merchants',
  },
  AgentShowPage: {},
  TransferPage: {
    slug: 'Slug',
    data: 'Data',
    res: 'Response',
    round_id: 'Round ID',
    setting_master_id: 'Setting Master ID',
    num: 'num',
    resend: 'Resend',
    bet_num: 'Bet No',
    resend_note: 'Note',
    resend_mark: "Mark",
    message: {
      success_to_resend: 'Success to Resend, No:{value}',
      fail_to_resend: 'Fail to Resend, No:{value}',
      success_to_remark: 'Success to Remark, No:{value}',
      fail_to_remark: 'Fail to Remark, No:{value}',
    }
  },
  CurrencySelection: {},
  ExchangeRatePage: {
    currency: 'Currency',
    currency_name: 'Currency Name',
    last_approved_rate: 'Last Rate',
    today_rate: 'Today\'s Rate',
    ref_rate: 'Ref. Rate',
    ref_rate_time: 'Ref. Time',
    tbd_confirm_rate: 'Confirm Rate',
    tbd_modify_rate: 'Modify Rate',
    record: 'Record',
    current_time: 'Current Time',
    last_approved_time: 'Last Approved Time',
    base_currency: 'Base Currency',
    fetch_exchange_rate: 'Fetch Exchange Rate',
    approve_rate: 'Approve Rate',
    rate_warning: '＊Attention: The exchange rate will be effective immediately after approved!',
    log: 'Exchange Rate Log',
    confirmed_by: 'Confirmed By',
    confirmed_time: 'Confirmed Time',
    approved_by: 'Approved By',
    approved_time: 'Approved Time',
    exchange_rate: 'Exchange Rate',
  },
  CreateAgentPage: {
    parent_agent: 'Parent Agent',
    environment: 'Environment',
    staging: 'Staging',
    production: 'Production',
    brand: 'Brand',
    trade_mode: 'Trade Mode',
    transfer_wallet: 'Transfer Wallet',
    single_wallet: 'Single Wallet',
    options: 'Options',
    level_sub_agent_with_value: 'Level {value} sub-agent',
    delete_agent_with_value: 'Delete level {value} sub-agent',
    add_agent_with_value: 'Add level {value} sub-agent',
    agent_info: 'Agent Information',
    message: {
      select_master_first: 'Please select a master agent first.',
      agent_same_master: 'The parent agent is the same as the master',
      please_refresh_cache: 'Please refresh the cache to get new agent list.',
      log_saved_time: 'Each log be saved for up to {value} hours.',
      success_refresh_cache: 'Success to Refresh the Cache',
      fail_refresh_cache: 'Fail to Refresh the Cache',
    }
  },
  PoolSettingPage: {
    pool_name: 'Pool Name',
    odds_refresh_frequency: 'Refresh Frequency (s)',
    last_call_percentage: 'Last Call (%)',
    close_bet_percentage: 'Close Bet (%)',
    per_bet: 'Per Bet',
    per_round: 'Per Round',
    risk_threshold: 'Risk Threshold',
    current_schedule: 'Current Schedule',
    new_schedule: 'New Schedule',
    update_schedule: 'Update Schedule',
    schedule: 'Schedule',
    view: 'View',
    start_time: 'Start Time',
    end_time: 'End Time',
    pool_configuration_options: 'Pool Configuration Options',
    expect_date: 'Expected Effective Date',
    expect_time: 'Expected Effective Time',
    add_new_pool_configuration: 'Add a New Pool Configuration',
    exceed_time: 'The current time has exceeded the expected effective time',
    effective_time: 'Effective Time',
    import_current_schedule: 'Import current schedule',

  }
}
