<template>
  <div class="frame daily-bets-page">
    <a-page-header
      :title="$t('global.menu.DailyBettingStatistic')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <div class="search-group">
            <Currency :inputCurrency.sync="form.currency"
                      :inputMaster.sync="form.master"
                      :inputAgent.sync="form.agent"
                      :multipleMaster="true"
                      :multipleAgent="true"
                      :currencyRequired="true"/>
            <a-form-model-item ref="arena_no" :label="$t('global.common.arena')" prop="arena_no">
              <a-select
                  mode="multiple"
                  show-search
                  :showArrow="true"
                  :filter-option="filterOption"
                  :default-value="[]"
                  :value="form.arena_no"
                  @change="changeArenaNo"

              >
                <a-select-option v-for="arenaListItem in arenaList" :value="arenaListItem.id" :key="arenaListItem.id">
                  {{ arenaListItem.arena_no }}({{ arenaListItem.id }})
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="odds_type" :label="$t('global.common.odds_type')" prop="odds_type">
              <a-select
                mode="multiple"
                :size="size"
                :showArrow="true"
                placeholder=""
                :default-value="[]"
                v-model="form.odds_types"
              >
                <a-select-option v-for="typeItem in oddsTypes" :value="typeItem.value" :key="typeItem.value">
                  {{ $t(`global.common.odds_type_list.${typeItem.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="search-group">
            <Datetime
              :input.sync="form.date"
              :periodDefault.sync="periodDefault"
              :customPeriods="['last_month', 'this_month', 'custom']"
              :preciseTime="form.preciseTime"
              :limitTimeSearch="false"
            />

            <div class="search-btns">
              <a-button
                type="primary"
                icon="search"
                :loading="loading"
                @click="onSearch"
                >{{ $t("global.action.search") }}</a-button
              >
              <a-button
                v-if="premission_action.export"
                type="primary"
                icon="export"
                :loading="loading"
                @click="onExport"
                >{{ $t("global.action.export") }}</a-button
              >
              <a-button :disabled="loading" @click="resetForm">{{
                $t("global.action.reset")
              }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>

      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control table-control">
            <p class="report-placeholder">
              {{$t('global.message.date_type_hint', { dateType: $t(`global.common.date_type_list.${dateTypeLabal}`) })}}
            </p>
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox
                    class="table-filter-check"
                    v-for="(item, index) in columns"
                    :key="item.dataIndex"
                    :defaultChecked="item.filterType"
                    :disabled="item.disabledFilter"
                    @click="columnsChange(index, item)"
                    :value="item.dataIndex"
                  >
                    {{ item.singleI18n ? $t(`DailyBettingStatisticPage.${item.slots.title}`) : $t(`global.common.${item.slots.title}`) }}
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>

          <a-table
            bordered
            size="small"
            :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
            :data-source="dailyBets"
            class="month-profit-table"
            :loading="loading"
            :pagination="pagination"
            @change="handleTableChange"
            :expandedRowKeys="expandedRowKeys"
            rowKey="row_key_id"
          >
            <template v-for="column in columns">
              <span :slot="column.slots.title" :key="column.slots.title">
                {{ column.singleI18n ? $t(`DailyBettingStatisticPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}
              </span>
            </template>

            <template slot="date" slot-scope="date">
              <template v-if="showDateType === 'month'">{{ date | formatMonthlyToUTC8 }}</template>
              <template v-else>{{ date }}</template>
            </template>

            <template slot="odds_type" slot-scope="odds_type">
              <div class="odds_type-column">
                <span>{{ odds_type }}</span>
              </div>
            </template>

            <template slot="valid_bet" slot-scope="valid_bet">
              <div class="valid_bet-column">
                <span>{{ valid_bet }}</span>
              </div>
            </template>

            <template slot="total_ggr" slot-scope="total_ggr">
              <div class="total_ggr-column">
                {{ total_ggr }}
              </div>
            </template>

            <template slot="yesterday_percentage" slot-scope="yesterday_percentage">
              <div class="yesterday_percentage-column">
                <span 
                  :class="[yesterday_percentage < 0 && 'notice-text']">{{ yesterday_percentage }}%</span>
              </div>
            </template>

            <template slot="last_week_percentage" slot-scope="last_week_percentage">
              <div class="last_week_percentage-column">
                <span 
                  :class="[last_week_percentage < 0 && 'notice-text']">{{ last_week_percentage }}%</span>
              </div>
            </template>

            <template slot="bet_count" slot-scope="bet_count">
              <div class="bet_count-column">
                {{ bet_count }}
              </div>
            </template>

            <template slot="player_count" slot-scope="player_count">
              <div class="player_count-column">
                {{ player_count }}
              </div>
            </template>

            <template slot="fight_count" slot-scope="fight_count">
              <div class="fight_count-column">
                {{ fight_count }}
              </div>
            </template>

            <template slot="ave_per_fight" slot-scope="ave_per_fight">
              <div class="ave_per_fight-column">
                {{ ave_per_fight }}
              </div>
            </template>

            <template slot="ave_per_fight_percentage" slot-scope="ave_per_fight_percentage">
              <div class="ave_per_fight_percentage-column">
                <span 
                  :class="[ave_per_fight_percentage < 0 && 'notice-text']">{{ ave_per_fight_percentage }}%</span>
              </div>
            </template>

            <template slot="last_week_ave_per_fight_percentage" slot-scope="last_week_ave_per_fight_percentage">
              <div class="last_week_ave_per_fight_percentage-column">
                <span 
                  :class="[last_week_ave_per_fight_percentage < 0 && 'notice-text']">{{ last_week_ave_per_fight_percentage }}%</span>
              </div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import { getMonthlyProfit, getArenaList } from '@/../api';
import { getDailyBettingStatistic, getArenaList, exportDailyBettingStatistic, getCurrentManageProfile, getPagePermissions } from '@/../api';
import Datetime from "@/../src/components/datetime_picker";
import storage, { IDENTITY } from '@/../storage';
import { cloneDeep } from 'lodash';
import Currency from '@/../src/components/currency_selection';
import { filterDuplicateData } from '@/../tools/common';

const columns = [
  {
    slots: { title: "date" },
    id: "date",
    dataIndex: "date",
    // dataIndex: "showDate",
    scopedSlots: { customRender: "date" },
    filterType: true,
    disabledFilter: true,
    width: 120,
  },
  {
    slots: { title: "odds_type" },
    id: "odds_type",
    dataIndex: "odds_type",
    scopedSlots: { customRender: "odds_type" },
    filterType: true,
    disabledFilter: true,
    align: 'center'
  },
  {
    slots: { title: "total_valid_bet_amount" },
    scopedSlots: { customRender: "valid_bet" },
    id: "valid_bet",
    dataIndex: "valid_bet",
    filterType: true,
    align: 'center'
  },
  {
    slots: { title: "total_platform_profit" },
    scopedSlots: { customRender: "total_ggr" },
    id: "total_ggr",
    dataIndex: "total_ggr",
    filterType: true,
    align: 'center'
  },
  {
    slots: { title: "yesterday_percentage" },
    scopedSlots: { customRender: "yesterday_percentage" },
    id: "yesterday_percentage",
    dataIndex: "yesterday_percentage",
    filterType: true,
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: "last_week_percentage" },
    scopedSlots: { customRender: "last_week_percentage" },
    id: "last_week_percentage",
    dataIndex: "last_week_percentage",
    filterType: true,
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: "bet_count" },
    scopedSlots: { customRender: "bet_count" },
    id: "bet_count",
    dataIndex: "bet_count",
    filterType: true,
    align: 'center'
  },
  {
    slots: { title: "player_count" },
    scopedSlots: { customRender: "player_count" },
    id: "player_count",
    dataIndex: "player_count",
    filterType: true,
    align: 'center'
  },
  {
    slots: { title: "fight_count" },
    scopedSlots: { customRender: "fight_count" },
    id: "fight_count",
    dataIndex: "fight_count",
    filterType: true,
    align: 'center'
  },
  {
    slots: { title: "average_percentage" },
    scopedSlots: { customRender: "ave_per_fight" },
    id: "ave_per_fight",
    dataIndex: "ave_per_fight",
    filterType: true,
    align: 'center',
  },
  {
    slots: { title: "ave_per_fight_percentage" },
    scopedSlots: { customRender: "ave_per_fight_percentage" },
    id: "ave_per_fight_percentage",
    dataIndex: "ave_per_fight_percentage",
    filterType: true,
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: "last_week_ave_per_fight_percentage" },
    scopedSlots: { customRender: "last_week_ave_per_fight_percentage" },
    id: "last_week_ave_per_fight_percentage",
    dataIndex: "last_week_APF_percentage",
    filterType: true,
    align: 'center',
    singleI18n: true,
  },
];

export default {
  components: {
    Datetime,
    Currency,
  },
  data() {
    return {
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      expand: false,
      loading: false,
      formLoading: false,
      auth_role: storage.getItem(IDENTITY), //取得當前角色
      columns: cloneDeep(columns),
      form: {
        date: [new Date(), new Date()],
        agent_id: 0,
        merchant_id: 0,
        // date_type: 'settled_at',
        date_type: 'settled_at',
        preciseTime: 'date',  // 是否啟用 YYYY-MM
        currency: '',
        master: null,
        agent: null,
        arena_no: [],
        odds_types: [],
        valid_bet: '',
        total_ggr: '',
        yesterday_percentage: '',
        last_week_percentage: '',
        bet_count: '',
        player_count: '',
        fight_count: '',
        ave_per_fight: '',
        ave_per_fight_percentage: '',
        last_week_ave_per_fight_percentage: '',
      },
      showDateType: '',
      DateTypes: [
        {
          value: 'settled_at',
          label: 'SettleAt'
        },
        {
          value: 'bet_at',
          label: 'BetAt'
        },
      ],
      oddsTypes: [
        {
          value: 0,
          label: 'rake'
        },
        {
          value: 1,
          label: 'malay'
        },
        {
          value: 2,
          label: 'static'
        },
      ],
      periodDefault: "this_month",
      rules: {},
      defaultExpandedRowKeys: [],
      expandedRowKeys: [],
      monthlyProfit: [],
      dailyBets: [],
      activeAgent: [],
      activeAgentParent: "",
      arenaList: [],
      unchecked_columns: [],
      premission_action: {
        create: false,
        export: false,
      },
      checkCurrency: '',
      // showArenaNo: false
    };
  },
  mounted() {
    this.setDynamicColumns();
    // this.fetchArenaList();
  },
  computed: {
    ...mapState({ identity: state => state.auth.identity,
                  parentIdentity: state => state.auth.parentIdentity,}),
    isMerchant(){
      const isMerchantIdentity = this.identity === 'merchant' || (this.identity === 'manager' && this.parentIdentity === 'merchant');
      return isMerchantIdentity;
    },
    currency(){
      return this.form.currency;
    },
    dateTypeLabal(){
      const currentDateType = this.DateTypes.find(dateType=>dateType.value === this.form.date_type);
      return currentDateType.label;
    }
  },
  watch:{
    currency:{
      handler(newValue, oldValue){
        if(newValue !== ''){
          if(newValue !== this.checkCurrency){
            this.checkCurrency = newValue;
            this.form.arena_no = [];
            this.arenaList = [];
            this.fetchArenaList(newValue);
          } else {
            if(this.arenaList.length > 0){
              this.form.arena_no = [this.arenaList[0].id];
            }
          }
        }
      }
    },
    identity: {
      handler(newValue, oldValue){
        if(newValue){
          if(newValue === 'manager'){
            getPagePermissions(this.$route.name)
            .then((data)=>{
              if(data.data.actions !== undefined) {
                this.premission_action.read = data.data.actions.read || false;
                this.premission_action.export = data.data.actions.export || false;
              }
            })
          }else{
            this.premission_action.read = true;
            this.premission_action.export = true;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;
      const { date, date_type, preciseTime, currency, master, agent, arena_no } = this.form;
      this.fetch({
        results: pagination.pageSize,
        page: currentPage,
        date: date,
        date_type: date_type,
        preciseTime: preciseTime,
        currency: currency,
        master: master,
        agent: agent,
        arena_no: arena_no,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.fetchDailyBettingStatistic({...params});
    },
    fetchDailyBettingStatistic(params = {}) {
      this.loading = true;
      getDailyBettingStatistic({
        ...params,
      }).then(({ data }) => {
        this.dailyBets = data.daily_bets;
        this.pagination.total = data.total;

      }).finally(()=>{
        this.loading = false;
      });
    },
    downloadFile(data) {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'DailyBettingStatistic.xlsx'; // 可以設定要下載的檔案名稱
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      this.form.agent = null;
      this.form.master = null;
      this.form.slug = "";
      this.periodDefault = "this_month";
    },
    onSearch() {
      if(this.loading) return;
      const { pageSize } = this.pagination;
      this.pagination.current = 1;
      const { date, master, agent, date_type, preciseTime, currency, arena_no, odds_types} = this.form;
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          // this.setDynamicColumns();
          this.expandedRowKeys = [];
          // arena_no ? this.showArenaNo = true : this.showArenaNo = false;
          this.fetch({ date, date_type, preciseTime, master: master, agent: agent, currency, results: pageSize, page: 1, arena_no: arena_no, odds_types: odds_types});
          this.showDateType = preciseTime;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onExport() {
      if(this.loading) return;
       const { date, master, agent, date_type, preciseTime, currency, arena_no} = this.form;
      exportDailyBettingStatistic({ date, date_type, preciseTime, master: master, agent: agent, currency, arena_no: arena_no, unchecked_fields: this.unchecked_columns})
      .then(({ data }) => {
        this.downloadFile(data);
      }).finally(()=>{
        this.loading = false;
      });
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;

      if(!item.filterType){
        this.unchecked_columns.push(item.id);
      }else{
        this.unchecked_columns = this.unchecked_columns.filter(column => column !== item.id);
      }
      // console.log("unchecked_columns", this.unchecked_columns);
    },
    setDynamicColumns(){
      const newColumn = cloneDeep(columns);

      // if(this.form.arena_no){
      //   newColumn.splice(1, 0, arenanoColumns);
      // }

      // if(this.auth_role === 'merchant'){
        //如果登入角色是merchant時，table動態添加「master」欄位
        // newColumn.splice(2, 0, merchantColumns);
      // };
      this.columns = newColumn;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    fetchArenaList(currency) {
      getArenaList({ currency }).then((data) => {
        this.arenaList = filterDuplicateData(data.data.arena,'id');
        if(data.data.arena.length > 0){
          this.form.arena_no = [this.arenaList[0].id];
        }
      });
    },
    changeArenaNo(value){
      if(value.length >= 1){
        this.form.arena_no = value;
      }
    }
  }
};
</script>
