<template>
  <div class="frame monthly-profit-page">
    <a-page-header
      :title="$t('global.menu.MonthlyProfit')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <!-- 日期搜尋 -->
          <!-- <div class="search-group">
            <a-form-model-item ref="date_type" :label="$t('MonthlyProfitPage.date_type')" prop="date_type">
              <a-select v-model="form.date_type">
                <a-select-option v-for="type in DateTypes" :value="type" :key="type">{{ $t(`MonthlyProfitPage.type_list.${type}`) }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </div> -->
          <div class="search-group">
            <Currency :inputCurrency.sync="form.currency"
                      :inputMaster.sync="form.master"
                      :inputAgent.sync="form.agent"
                      :showAgent="auth_role === 'agent' ? false : true"
                      :currencyRequired="true"/>
            <a-form-model-item ref="arena_no" :label="$t('global.common.arena')" prop="arena_no">
              <a-select
                  show-search
                  :filter-option="filterOption"
                  v-model="form.arena_no"
              >
                <a-select-option v-for="(arena_no, i) in arenaList" :value="arena_no.arena_no" :key="i">
                  {{ arena_no.arena_no }}({{ arena_no.id }})
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="preciseTime" :label="$t('global.common.date_type')" prop="preciseTime">
              <a-radio-group v-model="form.preciseTime">
                <a-radio value="month">{{$t('global.common.month')}}</a-radio>
                <a-radio value="date">{{$t('global.common.date')}}</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </div>
          <div class="search-group">
            <Datetime
              :input.sync="form.date"
              :periodDefault.sync="periodDefault"
              :customPeriods="['last_month', 'this_month', 'custom']"
              :preciseTime="form.preciseTime"
              :limitTimeSearch="false"
            />

            <div class="search-btns">
              <a-button
                type="primary"
                icon="search"
                :loading="loading"
                @click="onSearch"
                >{{ $t("global.action.search") }}</a-button
              >
              <a-button :disabled="loading" @click="resetForm">{{
                $t("global.action.reset")
              }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>

      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control table-control">
<!--            <a-button @click="collapse" icon="fullscreen-exit">{{-->
<!--              $t("global.action.collapse")-->
<!--            }}</a-button>-->
            <p class="report-placeholder">
              {{$t('global.message.date_type_hint', { dateType: $t(`global.common.date_type_list.${dateTypeLabal}`) })}}
            </p>
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox
                    class="table-filter-check"
                    v-for="(item, index) in columns"
                    :key="item.dataIndex"
                    :defaultChecked="item.filterType"
                    :disabled="item.disabledFilter"
                    @click="columnsChange(index, item)"
                    :value="item.dataIndex"
                  >
                    <template v-if="['meron_wala'].includes(item.slots.title)">
                        {{ $t(`global.common.${item.slots.title}`) }}({{$t(`global.color_game_fight_result.meron`)}} {{$t(`global.color_game_fight_result.wala`)}})
                    </template>
                    <template v-else-if="['draw'].includes(item.slots.title)">
                        {{ $t(`global.fight_result.${item.slots.title}`) }}({{$t(`global.color_game_fight_result.draw`)}})
                    </template>
                    <template v-else>
                        {{ $t(`global.common.${item.slots.title}`) }}
                    </template>
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>

          <a-table
            size="small"
            :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
            :data-source="monthlyProfit"
            class="month-profit-table"
            rowKey="expandedRowKey"
            :loading="loading"
            :pagination="pagination"
            @change="handleTableChange"
            childrenColumnName="has_child"
            @expand="expandData"
            :expandedRowKeys="expandedRowKeys"
          >
            <template v-for="column in columns">
              <span :slot="column.slots.title" :key="column.slots.dataIndex">
                <template v-if="['meron_wala'].includes(column.slots.title)">
                    {{ $t(`global.common.${column.slots.title}`) }}({{$t(`global.color_game_fight_result.meron`)}} {{$t(`global.color_game_fight_result.wala`)}})
                </template>
                <template v-else-if="['draw'].includes(column.slots.title)">
                    {{ $t(`global.fight_result.${column.slots.title}`) }}({{$t(`global.color_game_fight_result.draw`)}})
                </template>
                <template v-else>
                    {{ $t(`global.common.${column.slots.title}`) }}
                </template>
              </span>

              <template v-if="column.children">
                <template v-for="columnChild in column.children">
                  <span class="sub-title" :slot="columnChild.slots.title" :key="columnChild.slots.title">
                    {{ columnChild.title_name == 'platform_profit' ? `${ $t(`global.common.platform_profit`)} / ${$t(`global.common.kill_rate`) }` : $t(`global.common.${columnChild.title_name}`) }}
                  </span>
                </template>
              </template>
            </template>

            <template slot="date" slot-scope="date">
              <template v-if="showDateType === 'month'">{{ date | formatMonthlyToUTC8 }}</template>
              <template v-else>{{ date }}</template>
            </template>

            <template slot="arena_no" slot-scope="arena_no">
              <div class="arena-no-column">
                <span>{{ arena_no }}</span>
              </div>
            </template>

            <template slot="master" slot-scope="master">
              <div class="master-column">
                {{ master }}
              </div>
            </template>

            <!-- <template slot="agent" slot-scope="agent, data">
              <div class="agent agent-column" :class="{'is-sub-agent':data.isSubAgent}">
                {{ agent }}
              </div>
            </template> -->

            <template slot="agent" slot-scope="agent">
              <div class="agent agent-column">
                {{ agent }}
              </div>
            </template>

            <!-- meron_wala/draw/total -->
            <template slot="bets" slot-scope="bets">
              <div class="table-bet">
                <span class="table-bet-title"
                  >{{ $t("global.common.user_count") }}:</span
                >
                <span class="table-bet-num">
                  {{ bets.bet_users | formatNumber }}
                </span>
              </div>
              <div class="table-bet">
                <span class="table-bet-title">
                  {{ $t("global.common.bet_count") }}:
                  </span>
                <span class="table-bet-num">
                  {{ bets.bet_records | formatNumber }}
                </span>
              </div>
              <div class="table-bet">
                <span class="table-bet-title">
                  {{ $t("global.common.fight_count") }}:
                  </span>
                <span class="table-bet-num">
                  {{ bets.fight_counts | formatNumber }}
                </span>
              </div>
            </template>
            <template
              slot="vaild_bet"
              slot-scope="vaild_bet"
            >
              <div class="table-bet">
                <span class="table-bet-title">
                  {{ $t("global.common.total_bet_amount") }}:
                </span>
                <span class="table-bet-num">
                  {{ vaild_bet.bet_amount | formatNumberWithPoint }}
                </span>

              </div>
              <div class="table-bet">
                <span class="table-bet-title">
                  {{ $t("global.common.valid_bet_amount") }}:
                </span>
                <span class="table-bet-num">
                  {{ vaild_bet.valid_amount | formatNumberWithPoint }}
                </span>
              </div>
              <div class="table-bet">
                <span class="table-bet-title">
                  {{ $t("global.common.average_percentage") }}:
                </span>
                <span class="table-bet-num">
                  {{ vaild_bet.avg_per_fight | formatNumberWithPoint }}
                </span>
              </div>
            </template>
            <!-- meron_wala/draw/total 結束 -->

            <!-- GGR  -->
            <template slot="platform_profit" slot-scope="platform">
              <div>
                {{ platform.profit | formatNumberWithPoint }}
              </div>
              {{ platform.kill_rate | formatNumberWithPoint }}%
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMonthlyProfit, getArenaList } from '@/../api';
import Datetime from "@/../src/components/datetime_picker";
import storage, { IDENTITY } from '@/../storage';
import { cloneDeep } from 'lodash';
import Currency from '@/../src/components/currency_selection';

// const currencyColumns = {
//     slots: { title: "currency" },
//     // scopedSlots: { customRender: "currency" },
//     id: "currency",
//     title_name: "currency",
//     dataIndex: "currency",
//     filterType: true,
//     align: 'center'
// }
//
const merchantColumns = {
    slots: { title: "master" },
    scopedSlots: { customRender: "master" },
    id: "master",
    dataIndex: "showMaster",
    filterType: true,
    align: 'center'
}

const columns = [
  {
    slots: { title: "date" },
    id: "date",
    dataIndex: "showDate",
    scopedSlots: { customRender: "date" },
    filterType: true,
    disabledFilter: true,
    width: 120,
  },
  {
    slots: { title: "arena" },
    scopedSlots: { customRender: "arena_no" },
    id: "arena_no",
    dataIndex: "showArenaNo",
    filterType: true,
    align: 'center'
  },
  // {
  //   slots: { title: "master" },
  //   id: "master",
  //   title_name: "master",
  //   dataIndex: "master",
  //   filterType: true,
  //   disabledFilter: true,
  // },
  {
    slots: { title: "agent" },
    scopedSlots: { customRender: "agent" },
    id: "agent",
    dataIndex: "agent",
    filterType: true,
    align: 'center'
  },
  {
    slots: { title: "meron_wala" },
    filterType: true,
    scopedSlots: { customRender: "wala_meron" },
    children: [
      {
        slots: { title: "wala_meron_total_bets" },
        scopedSlots: { customRender: "bets" },
        title_name: "bet_count",
        dataIndex: "wala_meron_total_bets",
      },
      {
        slots: { title: "wala_meron_total_vaild_bet" },
        scopedSlots: { customRender: "vaild_bet" },
        title_name: "bet_amount",
        dataIndex: "wala_meron_total_vaild_bet",
      },
      {
        slots: { title: "wala_meron_total_platform_profit" },
        scopedSlots: { customRender: "platform_profit" },
        title_name: "platform_profit",
        dataIndex: "wala_meron_total_platform_profit",
        align: "right",
      },
    ],
  },
  {
    slots: { title: "draw" },
    title_name: "draw",
    filterType: true,
    scopedSlots: { customRender: "draw" },
    children: [
      {
        slots: { title: "draw_total_bets" },
        scopedSlots: { customRender: "bets" },
        title_name: "bet_count",
        dataIndex: "draw_total_bets",
      },
      {
        slots: { title: "draw_total_vaild_bet" },
        scopedSlots: { customRender: "vaild_bet" },
        title_name: "bet_amount",
        dataIndex: "draw_total_vaild_bet",
      },
      {
        slots: { title: "draw_total_platform_profit" },
        scopedSlots: { customRender: "platform_profit" },
        title_name: "platform_profit",
        dataIndex: "draw_total_platform_profit",
        align: "right",
      },
    ],
  },
  {
    slots: { title: "total" },
    filterType: true,
    scopedSlots: { customRender: "total" },
    children: [
      {
        slots: { title: "total_bet_count" },
        scopedSlots: { customRender: "bets" },
        title_name: "bet_count",
        dataIndex: "total_bets",
      },
      {
        slots: { title: "total_bet_amount" },
        scopedSlots: { customRender: "vaild_bet" },
        title_name: "bet_amount",
        dataIndex: "total_valid_bets",
      },
      {
        slots: { title: "total_platform_profit" },
        scopedSlots: { customRender: "platform_profit" },
        title_name: "platform_profit",
        dataIndex: "total_platform_profit",
        align: "right",
      },
    ],
  },
];

export default {
  components: {
    Datetime,
    Currency,
  },
  data() {
    return {
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      expand: false,
      loading: false,
      formLoading: false,
      auth_role: storage.getItem(IDENTITY), //取得當前角色
      columns: cloneDeep(columns),
      form: {
        date: [new Date(), new Date()],
        agent_id: 0,
        merchant_id: 0,
        // date_type: 'settled_at',
        date_type: 'settled_at',
        preciseTime: 'month',  // 是否啟用 YYYY-MM
        currency: '',
        master: null,
        agent: null,
        arena_no: '',
        search_arena_no: '',
      },
      showDateType: '',
      DateTypes: [
        {
          value: 'settled_at',
          label: 'SettleAt'
        },
        {
          value: 'game_date',
          label: 'GameDate'
        },
        {
          value: 'open_round',
          label: 'OpenRound'
        },
      ],
      periodDefault: "this_month",
      rules: {},
      defaultExpandedRowKeys: [],
      expandedRowKeys: [],
      monthlyProfit: [],
      activeAgent: [],
      activeAgentParent: "",
      arenaList: [
        // {id: 1, account: 'cockfight'}
      ],
      // showArenaNo: false,
      checkCurrency: ''
    };
  },
  mounted() {
    this.setDynamicColumns();
    // this.fetchArenaList();
  },
  computed: {
    currency(){
      return this.form.currency;
    },
    dateTypeLabal(){
      const currentDateType = this.DateTypes.find(dateType=>dateType.value === this.form.date_type);
      return currentDateType.label;
    }
  },
  watch:{
    currency:{
      handler(newValue, oldValue){
        if(newValue !== ''){
          if(newValue !== this.checkCurrency){
            this.checkCurrency = newValue;
            this.form.arena_no = '';
            this.arenaList = [];
            this.fetchArenaList(newValue);
          }
        }
      }
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      // return;
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;
      const { date, date_type, preciseTime, currency, master, agent, arena_no } = this.form;
      this.fetch({
        results: pagination.pageSize,
        page: currentPage,
        date: date,
        date_type: date_type,
        preciseTime: preciseTime,
        currency: currency,
        master: master,
        agent: agent,
        arena_no: arena_no,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.fetchMonthProfit({...params});
    },
    fetchMonthProfit(params = {}) {
      this.loading = true;
      getMonthlyProfit({
        ...params,
      }).then(({ data }) => {
        const parentMonthlyProfit = data.monthly_profit;
        parentMonthlyProfit.forEach((item,columnIndex)=>{

          const layer = 1;
          item.expandedRowKey = `${item.date}_/${item.arena_no}_/${item.master}_/${layer}`; //添加每一項的key
          item.columnIndex = columnIndex; //添加每一項的index
          item.layer = layer; //添加每一項的層級
          item.showDate = item.date;
          // delete item.master; //刪除第一層的master欄位

            item.draw_total_platform_profit = {
              profit: item.draw_total_platform_profit,
              kill_rate: item.draw_kill_rate,
            }

            item.wala_meron_total_platform_profit = {
              profit: item.wala_meron_total_platform_profit,
              kill_rate: item.wala_meron_kill_rate,
            }

            item.total_platform_profit = {
              profit: item.total_platform_profit,
              kill_rate: item.total_kill_rate,
            }

        })
        // console.log(parentMonthlyProfit);
        this.pagination.total = data.total;
        this.monthlyProfit = parentMonthlyProfit;
      }).finally(()=>{
        this.loading = false;
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      this.form.slug = "";
      this.periodDefault = "this_month";
      // setTimeout(() => {
      //   this.onSearch();
      // }, 100);
    },
    onSearch() {
      if(this.loading) return;
      const { pageSize } = this.pagination;
      this.pagination.current = 1;
      const { date, master, agent, date_type, preciseTime, currency, arena_no} = this.form;
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          // this.setDynamicColumns();
          this.expandedRowKeys = [];
          // arena_no ? this.showArenaNo = true : this.showArenaNo = false;
          this.fetch({ date, date_type, preciseTime, master: master, agent: agent, currency, results: pageSize, page: 1, search_arena_no: arena_no});
          this.showDateType = preciseTime;
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
    // 收合所有列表
    // collapse() {
    //   this.defaultExpandedRowKeys = [];
    // },
    expandData(expanded, record){
      if(this.loading) return;
      const is_open = expanded;
      if(!is_open){
        record.has_child = true;

        //移除展開行的key
        if(record.layer <= 1){
          const expandedRowKeys = this.expandedRowKeys.filter(rowKey => !rowKey.includes(record.date));
          this.expandedRowKeys = expandedRowKeys;
        }else{
          // const expandedRowKeys = this.expandedRowKeys.filter(rowKey => rowKey !== record.expandedRowKey);
          const expandedRowKeys = this.expandedRowKeys.filter(rowKey => {
            const rowKeyInfoArr = rowKey.split('_/');
            const rowKeyInfoStr = `${rowKeyInfoArr[0]}_/${rowKeyInfoArr[1]}`;
            const rowKeyMaster = rowKeyInfoArr[2];
            const rowKeyLayer = Number(rowKeyInfoArr[rowKeyInfoArr.length - 1]);
            const expandedKeyInfoStr = `${record.date}_/${record.arena_no}`;
            const expandedKeyLayer = record.layer;
            const expandedKeyMaster = record.master;
            return rowKeyInfoStr !== expandedKeyInfoStr || rowKeyLayer < expandedKeyLayer || (rowKeyLayer === expandedKeyLayer && rowKeyMaster !== expandedKeyMaster);
            // rowKey !== record.expandedRowKey
            });
          this.expandedRowKeys = expandedRowKeys;
        }
      }else{
        this.loading = true;
        this.expandedRowKeys.push(record.expandedRowKey); //添加展開行的key
        const agent_id = record.id;
        const { master, agent, arena_no } = this.form;
        const arenaList = this.arenaList.map(listItem=>listItem.arena_no);
        // const found = this.agents.find(element => element.id === record.id);
        getMonthlyProfit({
          date: [record.date, record.date],
          date_type: this.form.date_type,
          master: master,
          agent: agent,
          agent_id: agent_id,
          currency: this.form.currency,
          preciseTime: this.form.preciseTime,
          record_level: record.layer === 3 ? 'agent_layer' : (record.layer >= 2 ? 'master_layer' : 'arena_layer'),
          // arena: record.layer >= 2 ? record.arena_no : "",
          arena_no: record.layer >= 2 ? record.arena_no : arena_no ? arena_no : arenaList,
        }).then(({ data }) => {
          // console.log(data)
          const childMonthlyProfit = data.monthly_profit;
          const layer = record.layer + 1; //添加每一項的層級，層級數為父層+1
          childMonthlyProfit.forEach(item=> {
            item.expandedRowKey = `${item.date}_/${item.arena_no}_/${item.master}_/${layer}`; //添加每一項的key
            item.columnIndex = record.columnIndex; //子層繼承父層的columnIndex
            item.layer = layer;
            // delete item.date; //取得資料的時候刪除date資料
            delete item.currency; //取得資料的時候刪除currency資料

            item.draw_total_platform_profit = {
              profit: item.draw_total_platform_profit,
              kill_rate: item.draw_kill_rate,
            }

            item.wala_meron_total_platform_profit = {
              profit: item.wala_meron_total_platform_profit,
              kill_rate: item.wala_meron_kill_rate,
            }

            item.total_platform_profit = {
              profit: item.total_platform_profit,
              kill_rate: item.total_kill_rate,
            }

            // 第二層：刪除master資料、顯示arena_no
            // 第三層：如果登入角色是agent時：將master資料移到agent，並刪除master、及不出現子節點
            // 第四層：如果登入角色是merchant時：將master資料移到agent，並刪除master、及不出現子節點
            switch(item.layer){
              case 2:
                // delete item.master;
                item.showArenaNo = item.arena_no;
                break;
              case 3:
                if(this.auth_role === 'master' || this.auth_role === 'agent'){
                item.has_child = false;
                item.agent = item.master;
                // delete item.master;
                // delete item.arena_no;
                }
                if(this.auth_role === 'merchant') {
                  item.showMaster = item.master;
                }
                break;
              case 4:
                if(this.auth_role === 'merchant') {
                  item.has_child = false;
                  item.agent = item.master;
                  // delete item.master;
                  // delete item.arena_no;
                }
                break;
              default:
                break;
            }
            

            // //如果登入角色是agent或月營利報表的第一層子層是陣列時，將master為agent並刪除master
            // if(Array.isArray(this.monthlyProfit[record.columnIndex].has_child) || this.auth_role === 'agent' || this.auth_role === 'master'){
            //   item.has_child = false;
            //   item.agent = item.master;
            //   delete item.master;
            //   delete item.arena_no;
            // }

            // //如果登入角色是agent及月營利報表的第一層子層是陣列時，添加isSubAgent參數(更動scss用)
            // if(Array.isArray(this.monthlyProfit[record.columnIndex].has_child) && (this.auth_role === 'agent' || this.auth_role === 'master' )){
            //   item.isSubAgent = true;
            // }
          })
          record.has_child = childMonthlyProfit;
        }).finally(()=>{
          this.loading = false;
        });
      }
    },
    setDynamicColumns(){
      const newColumn = cloneDeep(columns);

      // if(this.form.arena_no){
      //   newColumn.splice(1, 0, arenanoColumns);
      // }

      if(this.auth_role === 'merchant'){
        //如果登入角色是merchant時，table動態添加「master」欄位
        newColumn.splice(2, 0, merchantColumns);
      };
      //
      // if(this.form.preciseTime === 'month'){
      //   //如果時間搜尋是month時，table動態添加「currency」欄位至「master」之前
      //   // newColumn.splice(1, 0, currencyColumns);
      //   // 2023-01-10 JPIT-210
      //   newColumn.splice(1, 0, arenanoColumns);
      // };
      //
      // if(this.form.preciseTime === 'date'){
      //   //如果時間搜尋是date時，table動態添加「currency」欄位至 date 之後
      //   // newColumn.splice(1, 0, currencyColumns);
      //   // 2023-01-10 JPIT-210
      //   newColumn.splice(1, 0, arenanoColumns);
      // };

      this.columns = newColumn;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    fetchArenaList(currency) {
      getArenaList({ currency }).then((data) => {
        this.arenaList = data.data.arena;
      });
    },
  }
};
</script>
