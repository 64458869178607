<template>
  <div class="frame current-arena-page">
    <div class="page-breadcrumb">
      <a-breadcrumb>
        <a-breadcrumb-item><router-link :to="{name: 'MultiModerator'}">{{ $t('global.menu.MultiModerator') }}</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>
          <span>{{ $t('global.menu.CurrentArena') }}</span>
          <span v-if="master_name"> - {{master_name}}</span>
          <span v-if="master_name && master_account">(</span>
          <span v-if="master_account">{{master_account}}</span>
          <span v-if="master_name && master_account">)[</span>
          <span v-if="arena_no">{{arena_no}}({{arena_id}})</span>
          <span v-if="arena_no && arena_no">]</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <!-- <a-page-header
      :title="$t('global.menu.CurrentArena')"
      @back="() => $router.go(-1)"
    >
    <template slot="extra">
      <div class="arena-history-header">
        <div></div>
        <div class="unsettlement-container">
          <span>{{ $t('CurrentArenaPage.unsettlement') }}</span>
          <span class="unsettlement-num">{{unsettlement}}</span>
        </div>
      </div>
    </template>
    </a-page-header> -->
    <div class="current-arena-content">
      <div class="current-arena-info">
        <div class="current-arena-top">
          <a-page-header
            :title="$t('global.menu.CurrentArena')"
            @back="() => $router.go(-1)"
          >
            <template slot="extra">
                <div>
                  <a-icon
                    class="button-reload"
                    @click="reloadIframe"
                    type="reload"
                    :spin="isLoading"
                  />
                </div>
            </template>
          </a-page-header>
        </div>
        <div class="current-arena-video">
          <iframe v-show="iframeVideo" id="current-video-iframe" width="100%" allowfullscreen="true" name="ninjastreamers_player" border="0" scrolling="no" frameborder="0" marginheight="0" marginwidth="0" :src="iFrameVideoUrl"></iframe>
        </div>
        <div class="current-arena-betting">
          <template v-if="gameType">
            <div class="betting-item" v-for="(bet,key) in bets" :key="GAME_TYPE_SIDE[gameType][key]" :data-betting="GAME_TYPE_SIDE[gameType][key]">
              <h3 class="item-title">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[gameType][key]}`) }}</h3>
              <div class="item-list">
                <span class="list-title">{{ $t('global.common.amount') }}</span>
                <span class="list-num">{{ bet.amount | formatNumberWithPoint }}</span>
              </div>
              <div class="item-list">
                <span class="list-title">{{ $t('CurrentArenaPage.count') }}</span>
                <span class="list-num">{{ bet.count | formatNumber }}</span>
              </div>
              <div class="item-list" v-if="key !== 'draw' && oddsType === 'malay'">
                <span class="list-title">{{ $t('global.common.platform_profit') }}</span>
                <span class="list-num">{{ bet.ggr | formatNumber }}</span>
              </div>
              <div class="item-list">
                <span class="list-title">{{ $t('global.common.odd') }}</span>
                <span class="list-num">{{ bet.payout }}</span>
              </div>
            </div>
          </template>
          <div class="decision_on_hold_cover" v-show="on_hold">
            {{ $t('global.common.arena_suspend') }}
          </div>
        </div>
        <div class="current-arena-other">
          <div class="other-item">
            <p class="title">{{ $t('global.common.total_bet_amount') }}</p>
            <p class="num">{{ totalBetAmount | formatNumberWithPoint }}</p>
          </div>
          <div class="other-item">
            <p class="title">{{ $t('global.common.total_bet_count') }}</p>
            <p class="num">{{ totalBetCount | formatNumber }}</p>
          </div>
          <!-- <div class="btns">
            <template v-if="is_main_control">
              <template>
                <a-button type="primary" class="btn-on-hold" @click="onHold('hold')" v-if="!on_hold">{{ $t('global.action.decision_on_hold') }}</a-button>
                <a-button type="primary" class="" @click="onHold('cancel')" v-else>{{ $t('global.action.cancel_on_hold') }}</a-button>
              </template>
              <template>
                <a-button type="primary" class="btn-close-bet" @click="closeBet(1)" v-if="!close_bet">{{ $t('global.action.close_bet') }}</a-button>
                <a-button type="primary" class="" @click="closeBet(0)" v-else>{{ $t('global.action.open_bet') }}</a-button>
              </template>
            </template>
          </div> -->
        </div>
      </div>
      <div class="current-arena-fights">
        <div class="current-arena-top">
          <a-row type="flex" justify="space-between">
            <a-col></a-col>
            <a-col>
              <span class="currency">{{ $t('global.common.currency') }} :</span>
              <span>{{ exchange_currency }}</span>
            </a-col>
          </a-row>
          <div class="arena-history-header">
            <div class="unsettlement-container">
              <span>{{ $t('CurrentArenaPage.unsettlement') }}</span>
              <span class="unsettlement-num">{{unsettlement}}</span>
            </div>
            <div class="refresh">
                <a-button type="primary" icon="reload" :loading="reloadLoading" @click="reloadFight">{{ $t('global.action.refresh_fights') }}</a-button>
            </div>
          </div>
        </div>
        <div class="arena-fights">
          <div class="arena-fights-title">
            <div class="item-title" data-right-title="fight">{{ $t('global.common.fight_id') }}</div>
            <div class="item-title" data-right-title="fight">{{ $t('global.common.round_id') }}</div>
            <div class="item-title" data-right-title="winner">{{ $t('global.common.winner') }}</div>
            <div class="item-title" data-right-title="bet_amount">{{ $t('global.common.bet_amount') }}</div>
            <div class="item-title" data-right-title="bet_count">{{ $t('global.common.bet_count') }}</div>
            <div class="item-title" data-right-title="status">{{ $t('global.common.status') }}</div>
            <div class="item-title" data-right-title="action">{{ $t('global.common.action') }}</div>
          </div>
          <div class="arena-fights-content"
               ref="arenaFights"
               v-infinite-scroll="loadMoreData"
               :infinite-scroll-disabled="disableLoadMore"
               infinite-scroll-immediate-check="false"
               infinite-scroll-distance="10">
            <div class="fight-items"
                 v-for="(fight,index) in fights"
                 :key="fight.round_id" >
              <HistoryFightItem
                v-if="index !== 0"
                :data="fight"
                :canNextStep="canNextStep"
                :disableNextStep="disableNextStep"
                :result_modal="result_modal"
                :check_fight="check_fight"
                :check_result="check_result"
                :sentResult="sentResult"
                :merchant_provider_id="$route.query.merchant_provider_id"
                :lockEarning="lockEarning"
                :bettingReadyToLastCallClose="bettingReadyToLastCallClose"
                :checkResult="checkResult"
                :reDeclare="reDeclare"
                :confirmBettingStatus="confirmBettingStatus"
                :currentDate="currentDate"
                :gameType="gameType"
                :closeModal="closeModal"
                :enableDraw="enable_draw"
                @lockEarning="lockEarning"
              />
              <CurrentFightItem
                v-else
                :data="fight"
                :totalBetAmount="totalBetAmount"
                :totalBetCount="totalBetCount"
                :gameType="gameType"
                @lockEarning="lockEarning"
              />
            </div>
            <div class="loading" v-if="dataCount < total"><a-spin /></div>
          </div>
        </div>
      </div>
    </div>
    <NetworkError v-if="networkErrorStatus" />
  </div>
</template>

<script>
import qs from 'query-string';
import { mapState } from 'vuex';
import { 
  // getDecisionOnHold, 
  getTodayFightHistoryCount, 
  fightLock, 
  // getCloseBet, 
  fightClose, 
  fightReload, 
  // fightCancel, 
  // getMerchantRole, 
  // fightOpen 
  } from '@/../api';
import CurrentFightItem from '@/../src/components/arena/current_fight_item';
import HistoryFightItem from '@/../src/components/arena/history_fight_item';
import NetworkError from '@/../src/components/network_error';
import { encryptData } from '@/../tools/common';
import { GAME_TYPE_SIDE, getHostnameInfo } from '@/../tools/config';
import storage, { AUTH_TOKEN } from '@/../storage';

let timer;
let dateTimer;

const clearTimer = () => {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }
};

const clearDateTimer = () => {
  if (dateTimer) {
    clearTimeout(dateTimer);
    dateTimer = undefined;
  }
};

const bets = {
  default: ['meron', 'draw', 'wala'],
  apple: ['meron', 'red3', 'wala', 'white3']
}

export default {
  components: {
    CurrentFightItem,
    HistoryFightItem,
    NetworkError
  },
  data() {
    return {
      GAME_TYPE_SIDE,
      iframeVideo: "",
      tempIframeVideo: "",
      isLoading: false,
      arena_opt: false,
      on_hold: false,
      close_bet: 0,
      unsettlement: 0, // 目前取消等於已經結算的
      bets: {
        // 改成動態載入
        // meron:{
        //   amount: 0,
        //   count: 0,
        //   payout: 0,
        // },
        // draw:{
        //   amount: 0,
        //   count: 0,
        //   payout: 0,
        // },
        // wala:{
        //   amount: 0,
        //   count: 0,
        //   payout: 0,
        // }
      },
      result_modal: false,
      check_fight: '',
      check_result: '',
      fights: [],
      canNextStep: true,
      channel: '',
      sub_ch: '',
      ws_url: '',
      cableActive: null, // Detect action cable. null is initial, other property is true or false.
      networkErrorStatus: false,
      reloadLoading: false,
      is_main_control: false,
      disableLoadMore: false,
      enable_draw: false,
      page: 1,
      dataCount: 20,
      moreDataCount: 20,
      total: 0,
      // currentArenaPermission: {
      //   action: false,
      //   lock_earning: false,
      //   read: false,
      // },
      currentDate: this.moment(new Date()).format('YYYY-MM-DD'),
      master_name: '',
      master_account: '',
      arena_no: '',
      arena_id: '',
      exchange_currency: '',
      gameType: '',
      oddsType: ''
    };
  },
  channels: {
    CockfightChannel: {
      connected() {
        console.log('connected');
        this.cableActive = true;
      },
      rejected() {
        console.log('rejected');
      },
      received(message) {
        // fight_status_update

        const dynamicBets = this.setDynamicBets();
        const {data, method} = message;
        const item = this.fights.find(i => i.round_id === (data.round_id));
        if(item && item.fight_no !== data.fight_no){
          item.fight_no = data.fight_no;
        }
        if(method === 'fight_status_update'){
          switch(data.bo_status) {
          case 'initial':
              if(item === undefined){

                // const cloneFights = cloneDeep(this.fights);

                this.fights.unshift({
                  round_id: data.round_id,
                  fight_no: data.fight_no,
                  open_bet: false,
                  opened: false,
                  result: '',
                  locked: 0,
                  sent_result: false,
                  loading: 6,
                  last_call: false,
                  double_confirm: false,
                  ready_to_last_call: false,
                  bet_amount: 0,
                  bet_count: 0,
                });

                // if(cloneFights.length > this.dataCount){
                //   this.fights = cloneFights.slice(0, this.dataCount);
                // } else {
                //   this.fights = cloneFights;
                // }

                dynamicBets.forEach(betItem=>{
                  this.bets[betItem] = {
                    amount: 0,
                    count: 0,
                    payout: 0,
                  }
                })
              }

              this.unsettlement += 1;
              this.total += 1;
              this.dataCount += 1;

              this.$refs.arenaFights.scrollTop = 0;
            break;
          case 'betting':
              this.confirmBettingStatus(data.round_id, false);
              item.ready_to_last_call = true;
              item.open_bet = true;
            break;
          case 'last_call':
              this.confirmBettingStatus(data.round_id, false);
              item.ready_to_last_call = false;
              item.last_call = true;
            break;
          case 'fighting':
              this.confirmBettingStatus(data.round_id, false);
              item.opened = true,
              item.open_bet = false;
              item.last_call = false;
            break;
          case 'finished':
              item.result = data.winner;
              item.sent_result = true;
              // if(data.winner === 'cancel') {
              //   if(!item.opened) {
              //     item.opened = true;
              //   }
              //   if(item.open_bet) {
              //     item.open_bet = false;
              //   }
              //   item.locked = 2;
              // }
              if(item.locked === 0 && data.locking) {
                item.locked = 1;
              }
            break;
          case 'reloaded':
              item.result = data.winner;
              // if(data.winner === 'cancel') {
              //   item.locked = 2;
              // }
            break;
          case 'earn_locked':
              if(data.locking) {
                item.locked = 2;
                this.unsettlement -= 1;
              }
            break;
          case 'cancel':
              if(item){
                item.opened = true;
                item.result = data.winner;
                item.sent_result = true;
                item.locked = 2;
                this.unsettlement -= 1;
              }
            break;
          default:
              console.log(`out of ${item.status}`);
              break;
          }
        } else if(method === 'betting_status_update'){
          if (item !== undefined) {

            item.bet_amount = dynamicBets.map(betItem=> data[`${betItem}_bets`] || 0).reduce((a, b)=> a+b );
            item.bet_count = dynamicBets.map(betItem=> data[`${betItem}_count`] || 0).reduce((a, b)=> a+b );
          }

          dynamicBets.forEach(betItem=>{
            this.bets[betItem] = {
              amount: data[`${betItem}_bets`] || 0,
              count: data[`${betItem}_count`] || 0,
              ggr: data[`${betItem}_ggr`] || 0,
              payout: data[`${betItem}_odd`] || 0,
            }
          })
          
        } else if(method === 'arena_status_update'){
          this.on_hold = data.decision_on_hold;
          this.close_bet = data.close_bet;
          if(data.opened !== this.arena_opt){
            this.arena_opt = data.opened;
            // if(data.opened){
            //   this.reloadFight();
            // }else{
            //   this.total = 0;
            //   this.fights = [];
            // }
          }
        }
      },
      disconnected() {
        console.info('disconnected');
        // this.$cable.connection.disconnect();
        // this.$cable.unsubscribe({ channel: this.channel, sub_ch: this.sub_ch, connectionUrl: this.ws_url });
        this.cableActive = false;
      },
    },
  },
  async mounted() {
    // await getMerchantRole()
    //   .then((data)=>{
    //     this.currentArenaPermission = {...this.currentArenaPermission, ...data.data.role.CurrentArena};
    //   })
    await this.getFightHistory();
    const ws_url = `${this.ws_url}?${storage.getItem(AUTH_TOKEN)}`;
    this.$cable.connection.connect(ws_url);
    this.$cable.subscribe({ channel: this.channel, sub_ch: this.sub_ch, connectionUrl: ws_url });
    dateTimer = setInterval(this.getCurrentDate,1000)
    // setTimeout(() => {
    //   this.$cable.subscribe({ channel: this.channel, sub_ch: this.sub_ch, connectionUrl: this.ws_url });
    // }, 1500);
  },
  destroyed() {
    clearDateTimer();
  },
  computed: {
    ...mapState({ currentProfile: state => state.auth.currentProfile }),
    // routes() {
    //   return [
    //     {
    //       path: 'arena_moderator',
    //       breadcrumbName: this.$t('global.menu.ArenaModerator'),
    //     },
    //     {
    //       path: 'arena_moderator/current_arena',
    //       breadcrumbName: this.$t('global.menu.CurrentArena'),
    //     },
    //   ];
    // },
    total_count(){
      const {total, dataCount} = this;
      return {total,dataCount};
    },
    totalBetAmount() {
      return Object.keys(this.bets).length > 0 
        ? Object.keys(this.bets).map(key => this.bets[key].amount).reduce((a,b) => a+b) 
        : 0;
    },
    totalBetCount() {
      return Object.keys(this.bets).length > 0 
        ? Object.keys(this.bets).map(key => this.bets[key].count).reduce((a,b) => a+b)
        : 0;
    },
    status() {
      if(this.open_bet) {
        return 'open';
      }else {
        if (this.opened) {
          if (this.locked === 2) {
            return 'locked';
          }else {
            return 'close';
          }
        }
        return 'idle';
      }
    },
    iFrameVideoUrl(){
      if(!this.$route.query.merchant_provider_id || !this.arena_id || !this.currentProfile.id){
        return '';
      }
      const apiDomain = getHostnameInfo().api_url.split('//')[1].split('/api')[0];
      const token = storage.getItem(AUTH_TOKEN);
      const ws_url = this.ws_url;
      // this.$route.query.merchant_provider_id才是正確的arena_id（跟前台一樣）
      const arena_id = this.$route.query.merchant_provider_id;
      // this.arena_id才是database的provider_id
      const provider_id = this.arena_id;
      const user_id = this.currentProfile.id;
      const data = {token, url: apiDomain, provider_id, arena_id, ws_url, userId: user_id, isDebug: this.$route.query.dm};
      const encryptedData = encryptData(data);
      const url = qs.stringifyUrl({url: this.iframeVideo, query: {token: encryptedData}});
      return url;
    },
  },
  methods: {
    async reloadFight(){
      this.reloadLoading = true;
      this.page = 1;
      this.dataCount = 20;
      await this.getFightHistory();
    },
    disableNextStep(){
      this.canNextStep = false;
    },
    confirmBettingStatus(id, betting_status){
      const item = this.fights.find(i => i.round_id === id);
      item.double_confirm = betting_status;
      this.disableNextStep();
    },
    bettingReadyToLastCallClose(id) {
      const item = this.fights.find(i => i.round_id === id);
      this.confirmBettingStatus(id, false);
      item.ready_to_last_call = false;
      item.last_call = true;
    },
    checkResult(fight, result) {
      this.result_modal = true;
      this.check_fight = fight;
      this.check_result = result;
    },
    reDeclare(id, fight_no) {
      let item = this.fights.find(i => i.round_id === id);
      this.$confirm({
        title: this.$t('ControlPanelPage.re_declare_title'),
        content: this.$t('ControlPanelPage.re_declare_content', { id: fight_no }),
        okText: this.$t('global.action.yes'),
        cancelText: this.$t('global.action.no'),
        onOk() {
          item.result = "";
        },
        onCancel() {},
      });
    },
    sentResult(id, result) {
      var item = this.fights.find(i => i.round_id === id);
      item.result = result;
      this.result_modal = false;
      if (!item.sent_result) {
        item.sent_result = true;
        // if(item.result === 'cancel'){
        //   fightCancel({round_id: id, merchant_provider_id: this.$route.query.merchant_provider_id})
        //     .then(({data}) => {
        //       // fightOpen({merchant_provider_id: this.$route.query.merchant_provider_id})
        //       //   .then(({ data }) => {
        //       //     // this.fights.push({
        //       //     //   round_id: data.round_id,
        //       //     //   fight_no: data.fight_no,
        //       //     //   open_bet: false,
        //       //     //   opened: false,
        //       //     //   result: '',
        //       //     //   locked: false,
        //       //     //   sent_result: false,
        //       //     // });
        //       //   }).catch((err) => {
        //       //     console.log(err)
        //       //   });
        //     })
        //     .catch((err)=>{
        //       console.log(err)
        //     })
        // } else {
          fightClose({ round_id: id, winner: result, merchant_provider_id: this.$route.query.merchant_provider_id })
            .then(({ data }) => {
              // fightOpen({merchant_provider_id: this.$route.query.merchant_provider_id})
              //   .then(({ data }) => {
              //     // this.fights.push({
              //     //   round_id: data.round_id,
              //     //   fight_no: data.fight_no,
              //     //   open_bet: false,
              //     //   opened: false,
              //     //   result: '',
              //     //   locked: false,
              //     //   sent_result: false,
              //     // });
              //   }).catch(() => {
              //   });
            }).catch((err) => {
              this.$message.error(err.response.data.message);
              console.log(err);
            });
        // }
      } else {
        // if(result === 'cancel'){
        //   fightCancel({round_id: id, merchant_provider_id: this.$route.query.merchant_provider_id})
        //     .then(({ }) => {
        //       }).catch((err) => {
        //         console.log(err)
        //       });
        // }else{
          fightReload({ round_id: id, winner: result, merchant_provider_id: this.$route.query.merchant_provider_id })
            .then(({ }) => {
              }).catch((err) => {
                this.$message.error(err.response.data.message);
                console.log(err);
              });
        // }
      };
    },
    // onHold(type) {
    //   let self = this;
    //   if(type == 'hold') {
    //     this.$confirm({
    //       title: this.$t('global.action.decision_on_hold') + '?',
    //       okText: this.$t('global.action.yes'),
    //       cancelText: this.$t('global.action.no'),
    //       onOk() {
    //         getDecisionOnHold({hold: 1,merchant_provider_id: self.$route.query.merchant_provider_id})
    //           .then(() => {
    //             self.on_hold = true;
    //           })
    //           .catch((err)=>{
    //             console.log(err);
    //           });
    //       },
    //       onCancel() {},
    //     });
    //   }else {
    //     this.$confirm({
    //       title: this.$t('global.action.cancel_on_hold') + '?',
    //       okText: this.$t('global.action.yes'),
    //       cancelText: this.$t('global.action.no'),
    //       onOk() {
    //         getDecisionOnHold({hold: 0,merchant_provider_id: self.$route.query.merchant_provider_id})
    //           .then(() => {
    //             self.on_hold = false;
    //           })
    //           .catch((err)=>{
    //             console.log(err);
    //           });
    //       },
    //       onCancel() {},
    //     });
    //   }
    // },
    // closeBet(value) {
    //   let self = this;
    //   if(value) {
    //     this.$confirm({
    //       title: this.$t('global.action.close_bet') + '?',
    //       okText: this.$t('global.action.yes'),
    //       cancelText: this.$t('global.action.no'),
    //       onOk() {
    //         getCloseBet({close_bet: value, merchant_provider_id: self.$route.query.merchant_provider_id})
    //           .then(() => {
    //             self.close_bet = value;
    //           })
    //           .catch((err)=>{
    //             console.log(err);
    //           });
    //       },
    //       onCancel() {},
    //     });
    //   }else {
    //     this.$confirm({
    //       title: this.$t('global.action.open_bet') + '?',
    //       okText: this.$t('global.action.yes'),
    //       cancelText: this.$t('global.action.no'),
    //       onOk() {
    //         getCloseBet({close_bet: value, merchant_provider_id: self.$route.query.merchant_provider_id})
    //           .then(() => {
    //             self.close_bet = value;
    //           })
    //           .catch((err)=>{
    //             console.log(err);
    //           });
    //       },
    //       onCancel() {},
    //     });
    //   }
    // },
    lockEarning(id) {
      const self = this;
      let item = this.fights.find(i => i.round_id === id);
      this.$confirm({
        title: this.$t('ControlPanelPage.lock_earing_title'),
        content: this.$t('ControlPanelPage.lock_earing_content', { id: id }),
        okText: this.$t('global.action.yes'),
        cancelText: this.$t('global.action.no'),
        onOk() {
          item.locked = 1;
          fightLock({ round_id: id, merchant_provider_id: self.$route.query.merchant_provider_id })
            .then(({ data }) => {
            }).catch(() => {
              this.$message.error(err.response.data.message);
              console.log(err);
            });
        },
        onCancel() {},
      });
    },
    async getFightHistory(){
      const count = this.dataCount + this.moreDataCount * (this.page - 1);
      const result = this.total !== 0 && this.total <= count ? this.total : count;
      await getTodayFightHistoryCount({merchant_provider_id: this.$route.query.merchant_provider_id, result: result})
        .then((data) =>{
          this.master_account = data.data.arena.master_account;
          this.master_name = data.data.arena.master_name;
          this.ws_url = data.data.arena.ws_url;
          this.channel = data.data.arena.channel;
          this.sub_ch = data.data.arena.sub_ch;
          this.iframeVideo = data.data.arena.video_url;
          this.unsettlement = data.data.arena.unsettle_count;
          this.on_hold = data.data.arena.decision_on_hold;
          this.close_bet = data.data.arena.close_bet ? 1 : 0;
          this.is_main_control = data.data.arena.is_main_control;
          this.arena_no = data.data.arena.arena_no;
          this.arena_id = data.data.arena.id;
          this.exchange_currency = data.data.arena.exchange_currency;
          this.enable_draw = data.data.arena.enable_draw;
          this.gameType = data.data.arena.game_type;
          this.oddsType = data.data.arena.odds_type;

          const dynamicBets = this.setDynamicBets();
          const initialBets = {};

          if(data.data.rounds.length !== 0){
            this.total = data.data.total_rounds;
            const fights = data.data.rounds;
            const latestFight = fights[0];
            this.arena_opt = true;
            latestFight.double_confirm = false;
            if(latestFight.last_call){
              latestFight.ready_to_last_call = false;
            }else{
              latestFight.ready_to_last_call = true;
            }
            
            dynamicBets.forEach(betItem=>{
              initialBets[betItem] = {
                amount: latestFight[`${betItem}_bets`] || 0,
                count: latestFight[`${betItem}_count`] || 0,
                ggr: latestFight[`${betItem}_ggr`] || 0,
                payout: latestFight[`${betItem}_odd`] || 0,
              }
            })
            this.fights = fights;
            this.dataCount = result;
          }else{
            dynamicBets.forEach(betItem=>{
              initialBets[betItem] = {
                amount: 0,
                count: 0,
                ggr: 0,
                payout: 0,
              }
            })
          }

          this.$set(this, 'bets', initialBets)

          if(this.reloadLoading){
            this.$refs.arenaFights.scrollTop = 0;
            this.reloadLoading = false;
          }
        })
        .catch((err)=>{
          this.$message.error(err.response.data.message);
          console.log(err);
        });
    },
    reloadIframe() {
      if( this.isLoading ){
        return;
      }else{
        this.isLoading = true;
        this.tempIframeVideo = this.iframeVideo;
        this.iframeVideo = "";
      }
    },
    loadMoreData() {
      if(this.disableLoadMore) return;
      this.disableLoadMore = true;
        setTimeout(async()=>{
          this.page += 1;
          await this.getFightHistory();
          // this.disableLoadMore = false;
        },1000);
    },
    getCurrentDate() {
      const currentDate = this.moment(new Date()).format('YYYY-MM-DD');
      if(currentDate !== this.currentDate) {
        this.currentDate = currentDate;
      }
    },
    closeModal(){
      this.result_modal = false;
    },
    setDynamicBets(){
      switch(this.gameType){
        case 'apple':
          return bets['apple'];
        default:
          return bets['default'];
      }
    }
  },
  watch: {
    iframeVideo: {
      handler(newValue, oldValue) {
        if (newValue == "") {
          setTimeout(() => {
            this.iframeVideo = this.tempIframeVideo;
            this.tempIframeVideo = "";
            this.isLoading = false;
          },890);
        }
      },
    },
    total_count: {
      handler(newValue, oldValue) {
        if(newValue.total <= newValue.dataCount){
          this.disableLoadMore = true;
        }else{
          this.disableLoadMore = false;
        }
      },
    },
    canNextStep: {
      handler(newValue, oldValue) {
        if (newValue === false) {
          setTimeout(()=>{
            this.canNextStep = true;
          },1000)
        }
      },
    },
    // fights: {
    //   handler(newValue, oldValue) {
    //     let unsettlement_count = 0;
    //     newValue.forEach((value)=>{
    //       if(value.locked !== 2) {
    //         unsettlement_count += 1;
    //       }
    //     })
    //     this.unsettlement = unsettlement_count;
    //   },
    //   deep: true,
    // },
    cableActive: {
      async handler(newValue, oldValue) {
        if(newValue === true){
          if(timer){
            clearTimer();
            await this.reloadFight();
          }
        }else if(newValue === false){
          timer = setTimeout(()=>{
            this.networkErrorStatus = true;
            this.$cable.connection.disconnect();
          },15000);
        }
      },
      immediate: false,
    }
  },
};
</script>
