<template>
  <div class="frame">
    <div class="multi-moderator-page">
      <div class="multi-moderator-arena-video">
        <div class="select-pool-arena-wrap">
          <div class="pool-select-container">
            <span>{{ $t('global.common.main_pool') }}</span>
            <a-select
                v-model="currentPoolId"
                class="arena-select">
              <a-select-option v-for="item in mainPools" :value="item.id" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div> 
          <div class="arena-select-container">
            <span>{{ $t('global.common.arena') }}</span>
            <a-select
                v-model="currentProviderId"
                class="arena-select">
              <a-select-option v-for="item in providers" :value="item.id" :key="item.id">
                {{ item.arena_no }}({{ item.id }})
              </a-select-option>
            </a-select>
          </div> 
        </div>
        <div class="video-content">
          <iframe v-show="showVideoUrl" id="video-iframe" width="100%" allowfullscreen="true" name="ninjastreamers_player" border="0" scrolling="no" frameborder="0" marginheight="0" marginwidth="0" :src="iFrameVideoUrl"></iframe>
          <a-icon
            class="multi-moderator-button-reload button-reload"
            @click="reloadVideo"
            type="reload"
            :spin="isVideoLoading"
          />
        </div>
      </div>
      <div class="multi-moderator-fights">
        <div class="fights-control">
          <div class="video-url-container">
            <p class="url-title">
              <a-icon type="notification" />
              <span>{{ $t('global.common.video_url') }}</span>
            </p>
            <div class="url-content">
              <p class="url-text" @click="changeVideoUrl('video')">{{ currentVideo }}</p>
              <div class="edit-url" v-if="multiModeratorPermission.edit_provider_url">
                <EditVideoUrl :video_url="currentVideo" @edited="updateVideoUrl" :showTitle="false" />
              </div>
            </div>
          </div>
          <div class="refresh">
            <a-button v-if="rounds.length > 0" type="primary" icon="reload" :loading="reloadLoading" @click="reloadFight">{{ $t('global.action.refresh_fights') }}</a-button>
          </div>
        </div>

        <!-- icp video -->
        <div class="fights-control">
          <div class="video-url-container">
            <p class="url-title">
              <a-icon type="notification" />
              <span>{{ $t('global.common.icp_video_url') }}</span>
            </p>
            <div class="url-content">
              <p class="url-text" @click="changeVideoUrl('icp')">{{ currentIcpVideo }}</p>
              <div class="edit-url" v-if="multiModeratorPermission.edit_provider_url">
                <EditVideoUrl :video_url="currentIcpVideo" @edited="updateIcpVideoUrl" :showTitle="false" />
              </div>
            </div>
          </div>
          <!-- <div class="refresh">
            <a-button v-if="rounds.length > 0" type="primary" icon="reload" :loading="reloadIcpLoading" @click="reloadFight">{{ $t('global.action.refresh_fights') }}</a-button>
          </div>  -->
        </div>

        <div class="arenas-fights scroll"
              ref="arenaFights"
              v-infinite-scroll="loadMoreData"
              :infinite-scroll-disabled="disableLoadMore"
              infinite-scroll-immediate-check="false"
              infinite-scroll-distance="10">
          <div class="arenas-fights-title">
            <div class="item-title" data-right-title="fight">{{ $t('global.common.fight_id') }}</div>
            <div class="item-title" data-right-title="fight_no">{{ $t('ControlPanelPage.pool_fight_number') }}</div>
            <div class="item-title" data-right-title="link_master">{{ $t('ControlPanelPage.round_master') }}</div>
            <div class="item-title" data-right-title="winner">{{ $t('global.common.winner') }}</div>
            <div class="item-title" data-right-title="status">{{ $t('global.common.pool_status') }}</div>
            <div class="item-title" data-right-title="status">{{ $t('global.common.round_status') }}</div>
          </div>
          <div class="arenas-fights-content">
            <!-- <div class="fight-items"
                  v-for="arena in filterArenas"
                  :key="arena.id" >
              <MultiFightItem :data="arena" 
                              :gameType="gameType"
                              :currentTableIndex="currentTableIndex"
                              :tableIndex="`${arena.master_account}_${arena.id}`"
                              :setCurrentRoundAndInfo="setCurrentRoundAndInfo"/>
            </div> -->
            <div class="fight-items"
                  v-for="round in rounds"
                  :key="round.round_id" >
              <MultiFightItem :data="round" 
                              :gameType="gameType"
                              :currentTableIndex="currentTableIndex"
                              :tableIndex="round.round_id"
                              :setCurrentRoundAndInfo="setCurrentRoundAndInfo"/>
            </div>
          </div>
          <div class="loading" v-if="rounds.length < total"><a-spin /></div>
        </div>
      </div>

      <div class="multi-moderator-controls scroll" :class="{'disabled-multi-moderator-controls': !currentArenaInfo.id}">
        <div class="main-controls controls-item">
          <div class="toggle-arena contorls-item-border">
            <div class="toggle-arena-item">
              <p class="item-title">{{ $t('global.common.link_master') }}</p>
              <p class="link_master">{{ currentArenaInfo.master_name | formatString }}({{currentArenaInfo.master_account | formatString}})</p>
            </div>
            <div class="toggle-arena-item">
              <p class="item-title">{{ $t('ControlPanelPage.start_time') }}</p>
              <p>{{ now_fight.begin_at || '' | formatTimesToUTC8 }}</p>
            </div>
            <div class="toggle-arena-item" v-if="multiModeratorPermission.current_arena">
              <router-link class="current-arena-btn" :to="{ name: 'CurrentArena' , query: { merchant_provider_id: currentArenaInfo.id }}">{{
                    $t("global.menu.CurrentArena")
              }}</router-link>
            </div>
          </div>

          
          <div v-if="multiModeratorPermission.lock_earning" class="now-fight-control contorls-item-border">
            <SettleItem :now_fight="now_fight" :currentPoolId="currentPoolId" :updateRoundLocked="updateRoundLocked"/>
          </div>
        </div>

        <div class="sub-controls controls-item sub-controls-content contorls-item-border"
         v-if="multiModeratorPermission.decision_on_hold || multiModeratorPermission.no_bets_allowed || multiModeratorPermission.mask_video || multiModeratorPermission.monitor_video">
          <!-- <template v-if="multiModeratorPermission.decision_on_hold">
            <a-button type="primary" class="btn-on-hold" @click="onHold('hold')" v-if="!currentArenaInfo.is_hold">{{ $t('global.action.decision_on_hold') }}</a-button>
            <a-button type="primary" class="" @click="onHold('cancel')" v-else>{{ $t('global.action.cancel_on_hold') }}</a-button>
          </template> -->
          <template v-if="multiModeratorPermission.no_bets_allowed">
            <a-button type="primary" class="btn-close-bet" @click="closeBet(1)" v-if="!currentArenaInfo.is_close_bet">{{ $t('global.action.not_allow_bet') }}</a-button>
            <a-button type="primary" class="" @click="closeBet(0)" v-else>{{ $t('global.action.allow_bet') }}</a-button>
          </template>
          <template v-if="multiModeratorPermission.mask_video">
            <a-button type="primary" class="btn-mask" @click="maskVideo(1)" v-if="!currentArenaInfo.mask_video">{{ $t('global.action.mask_video') }}</a-button>
            <a-button type="primary" class="" @click="maskVideo(0)" v-else>{{ $t('global.action.cancel_mask_video') }}</a-button>
          </template>
          <template v-if="multiModeratorPermission.monitor_video">
            <a-button type="primary" class="btn-mask" @click="monitorVideo(1)">{{ $t("global.action.monitor") }}</a-button>
          </template>
        </div>

        <div class="sub-controls controls-item contorls-item-border upload" 
        v-if="multiModeratorPermission.mask_file || multiModeratorPermission.schedule">
          <p class="upload-title">
            <img :src="require('images/CloudUpload.svg')" alt="upload">
            <span>{{ $t('ControlPanelPage.file_upload') }}</span>
          </p>
          <div class="sub-controls-content upload-content">
            <template v-for="(btn, index) in uploadBtn" >
              <a-button v-if="multiModeratorPermission[btn.title]"
                        :key="index" 
                        type="primary" 
                        class="btn-upload" 
                        @click="showUploadModal(btn.imgName, btn.title, btn.maxSize, btn.recommendedSize)">
                        {{ $t(`ControlPanelPage.${btn.title}`) }}
              </a-button>
            </template>
          </div>
        </div>

        <div class="sub-controls controls-item contorls-item-border announce" 
        v-if="multiModeratorPermission.announcement">
          <p class="announce-title">
            <a-icon type="notification" />
            <span>{{ $t('global.common.announcement') }}</span>
          </p>
          <div class="announce-content">
            <p class="announce-text">{{ currentArenaInfo.announcement }}</p>
            <div class="edit-announce">
              <Announce :announce="currentArenaInfo.announcement" @edited="announceUpdate" :showTitle="false" />
            </div>
          </div>
        </div>
      </div>

      <UploadFile
        v-if="uploadImg.type && uploadImg.type !== 'Schedule'"
        :show="uploadModal"
        :closeModal="closeUploadModal"
        :clearUploadData="clearUploadData"
        :uploadImg="uploadImg"/>
      <UploadMultiFile
        v-else-if="uploadImg.type && uploadImg.type === 'Schedule'"
        :show="uploadModal"
        :closeModal="closeUploadModal"
        :clearUploadData="clearUploadData"
        :uploadImg="uploadImg"/>
    </div>
    <NetworkError v-if="networkErrorStatus" />
  </div>
</template>
<script>
import qs from 'query-string';
import { mapState } from 'vuex';
import { fightAnnounce, 
         getDecisionOnHold, 
         getMerchantRole, 
         getCloseBet, 
         getMaskVideo, 
         getMonitorVideo,
         getTodayProviders, 
         updateProviderUrl, 
         getModeratorByProvider, 
         getTodayRoundByProvider,
         getTodayMainPools,
        } from '@/../api';
import SettleItem from '@/../src/components/arena/settle_item';
import FightResultImg from '@/../src/components/arena/fight_result_img';
import Announce from '@/../src/components/edit_announce';
import EditVideoUrl from '@/../src/components/edit_video_url';
import MultiFightItem from '@/../src/components/arena/multi_fight_item';
import UploadFile from '@/../src/components/arena/upload_file';
import UploadMultiFile from '@/../src/components/arena/upload_multi_file';
import NetworkError from '@/../src/components/network_error';
import { encryptData } from '@/../tools/common';
import { GAME_TYPE_SIDE, getHostnameInfo } from '@/../tools/config';
import storage, { AUTH_TOKEN } from '@/../storage';

let timer;

const clearTimer = () => {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }
};

export default {
  components: {
    Announce,
    NetworkError,
    FightResultImg,
    EditVideoUrl,
    MultiFightItem,
    UploadFile,
    UploadMultiFile,
    SettleItem
  },
  data() {
    return {
      GAME_TYPE_SIDE,
      arena_open_modal: false,
      arena_date: '',
      start_fight: '',
      road_map_id: null,
      arena_info: [],
      current_arena_index: null,
      gameType: '',
      tempGameType: '',
      currentVideo: '',
      currentIcpVideo: '',
      isVideoLoading: false,
      isIcpVideoLoading: false,
      scrollBtn: false,
      channel: '',
      sub_ch: '',
      ws_url: '',
      networkErrorStatus: false,
      canNextStep: true,
      multiModeratorPermission: {
        announcement: false,
        decision_on_hold: false,
        lock_earning: false,
        read: false,
        no_bets_allowed: false,
        mask_video: false,
        monitor_video: false,
        current_arena: false,
      },
      cableActive: null, // Detect action cable. null is initial, other property is true or false.
      is_main_control: false,
      reloadLoading: false,
      reloadIcpLoading: false,
      moreDataLoading: false,
      page: 1,
      total: 0,
      initDataCount: 0,
      moreDataCount: 12,
      mainPools: [],
      providers:[],
      currentPoolId: null,
      currentProviderId: null,
      currentArenaInfo: {
        // id: 8,
        // announcement: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        // opened: true,
        // is_hold: false,
        // is_close_bet: false,
        // mask_video: false,
        // main_arena: true,
        // multi_mp_start_time: "2023-07-24T14:50:36.192+08:00",
        // arena_info: [{
        //   time: "2023-07-24",
        //   fight_no: 5,
        //   road_map_id: 2190,
        //   today: true
        // }],
        // master_name: "DS88-PHP01",
        // master_account: "ds88_php01"
      },
      now_fight: {
        // round_id: 245007,
        // fight_no: "2",
        // open_bet: false,
        // is_opened: true,
        // winner: "cancel",
        // locked: 2,
        // sent_result: true,
        // status: "cancel",
        // last_call: false,
        // main_arena: false,
        // master_name: "DS88-KVND01",
        // master_account: "ds88_kvnd01",
        // merchant_provider_id: 24
      },
      rounds: [],
      arenas: [],
      currentTableIndex: null,
      uploadImg: {
        title: '',
        maxSize: 0,
        imgFormat: 'png, jp(e)g',
        recommendedSize: '',
        merchant_provider_id: null,
        type: '',
      },
      uploadBtn: [
        // {
        //   imgName: 'ArenaCover', 
        //   title: 'arena_cover', 
        //   maxSize: 50, 
        //   recommendedSize: 'w220px * h130px',
        // },
        {
          imgName: 'MaskFile', 
          title: 'mask_file', 
          maxSize: 120, 
          recommendedSize: 'w646px * h363px',
        },
        {
          imgName: 'Schedule', 
          title: 'schedule', 
          maxSize: 150, 
          recommendedSize: `w560px, ${this.$t('ControlPanelPage.below_px_value',{ value: 2300 })}`,
        },
      ],
      videoUrlType: 'video',
    };
  },
  channels: {
    ProviderChannel: {
      connected() {
        // console.log('connected');
        this.cableActive = true;
      },
      rejected() {
        // console.log('rejected');
      },
      received(message) {
        // console.log('received', data);
        const {data, method} = message;
        switch(method){
          case 'fight_status_update':
            const item = this.rounds.find(i => i.round_id === data.round_id);
            if(item && item.fight_no !== data.fight_no){
              item.fight_no = data.fight_no;
            }

            if(data.pool_status === 'initial'){
              if(!item){
                const currentArena = this.arenas.find(arenaItem=>arenaItem.id === data.arena_id);
                const addItem = {
                  merchant_provider_id: data.arena_id,
                  pool_id: data.pool_id,
                  fight_no: data.fight_no,
                  round_id: data.round_id,
                  winner: '',
                  master_account: currentArena.master_account,
                  master_name: currentArena.master_name,
                  pool_status: data.pool_status,
                  round_locked: data.round_locked,
                  begin_at: data.begin_at,
                }
                this.rounds.unshift(addItem);
              }
            }else{
              if(item && item.pool_status !== 'earn_locked'){
                item.pool_status = data.pool_status;
                if(data.winner){
                  item.winner = data.winner;
                }
              }
            }
            break;
          case 'arena_status_update':
            const currentArena = this.arenas.find(arenaItem=>arenaItem.id === data.arena_id);
            currentArena.is_hold = data.decision_on_hold;
            currentArena.is_close_bet = data.close_bet;
            currentArena.mask_video = data.mask_video;
            currentArena.opened = data.opened;
            if(this.currentArenaInfo.id === data.arena_id && !data.opened){
              this.currentTableIndex = `${this.currentArenaInfo.master_account}_${this.currentArenaInfo.id}`
            }
            break;
          case 'multi_moderator_round_status_update': 
            const currentRound = this.rounds.find(roundItem=>roundItem.round_id === data.round_id);
            if(currentRound){
              currentRound.round_locked = data.round_locked;
              if(data.is_canceled){
                currentRound.winner = 'cancel';
              }
            }
            break;
          default:
            break;
        }
      },
      disconnected() {
        console.log('disconnected');
        this.cableActive = false;
      },
    },
  },
  async mounted() {
    await getMerchantRole()
      .then((data)=>{
        this.multiModeratorPermission = {...this.multiModeratorPermission, ...data.data.role.MultiModerator};
      })

    await this.getMainPools();
  },
  computed: {
    ...mapState({ currentProfile: state => state.auth.currentProfile }),
    // filterArenas() {
    //   return this.arenas.filter(arena=>!arena.opened)
    // },
    disableLoadMore() {
      return this.total <= this.rounds.length || this.moreDataLoading;
    },
    showVideoUrl() {
      return this.isVideoLoading ? '' : this.currentVideo;
    },
    showIcpVideoUrl() {
      return this.isIcpVideoLoading ? '' : this.currentIcpVideo;
    },
    showNowFightWinner() {
      return this.now_fight.is_reloading ? '' : this.now_fight.winner;
    },
    iFrameVideoUrl(){
      if(this.currentProviderId === null || !this.currentArenaInfo.id || !this.currentProfile.id){
        return '';
      }
      const apiDomain = getHostnameInfo().api_url.split('//')[1].split('/api')[0];
      const token = storage.getItem(AUTH_TOKEN);
      const ws_url = this.ws_url;
      const arena_id = this.currentArenaInfo.id;
      const user_id = this.currentProfile.id;
      const data = {token, url: apiDomain, provider_id: this.currentProviderId, arena_id, ws_url, userId: user_id, isDebug: this.$route.query.dm};
      const encryptedData = encryptData(data);
      const urlLink = this.videoUrlType === 'icp' && this.currentIcpVideo ? this.currentIcpVideo : this.currentVideo;
      const url = qs.stringifyUrl({url: urlLink, query: {token: encryptedData}});
      return url;
    },
  },
  watch: {
    currentPoolId: {
      async handler(newValue, oldValue) {
        if(newValue){
          this.currentVideo = '';
          this.currentIcpVideo = '';
          this.tempGameType = '';
          this.gameType = '';
          this.rounds = [];
          this.arenas = [];
          this.page = 1;
          this.total = 0;
          this.initDataCount = 0;
          this.currentArenaInfo = {};
          this.now_fight = {};
          this.arena_info = [];
          if(this.cableActive){
            this.$cable.unsubscribe(this.channel);
          }
          await this.getProviders();
        }
      },
    },
    currentProviderId: {
      async handler(newValue, oldValue) {
        if(newValue) {
          const currentProvider = this.providers.find(item=>item.id === newValue);
          if(currentProvider){
            this.tempGameType = currentProvider.game_type;
            await this.getCurrentModerator();
          }
        }
      },
      immediate: true,
    },
    currentArenaInfo: {
      handler(newValue, oldValue) {
        if(newValue.id){
          this.uploadImg.merchant_provider_id = newValue.id;
        }
      },
      immediate: false,
    },
    canNextStep: {
      handler(newValue, oldValue) {
        if (newValue === false) {
          const waitTime = process.env.NODE_ENV === 'development' ? 1 : 1000;
          setTimeout(()=>{
            this.canNextStep = true;
          },waitTime)
        }
      },
    },
    cableActive: {
      async handler(newValue, oldValue) {
        if(newValue === true){
          if(timer){
            clearTimer();
            await this.getFightHistory();
          }
        }else if(newValue === false){
          timer = setTimeout(()=>{
            this.networkErrorStatus = true;
            this.$cable.connection.disconnect();
          },15000);
        }
      },
      immediate: false,
    },
  },
  methods: {
    getHostnameInfo,
    async reloadFight(){
      this.reloadLoading = true;
      this.page = 1;
      await this.getCurrentTodayRound();
      this.reloadLoading = false;
      this.$refs.arenaFights.scrollTop = 0;
    },
    async getMainPools(){
      try{
        const res = await getTodayMainPools();
        const { main_pools } = res.data;
        this.mainPools = main_pools;
        if(main_pools.length !== 0){
          this.currentPoolId = main_pools[0].id;
        }
      }catch(err){
        this.$message.error(err.response.data.message);
      }finally{

      }
    },
    async getProviders(){
      this.currentProviderId = null;
      try{
        const res = await getTodayProviders(this.currentPoolId);
        this.providers = res.data.providers;
        if(this.providers.length !== 0){
          this.currentProviderId = this.providers[0].id;
        }
      }catch(err){
        this.$message.error(err.response.data.message);
      }finally{

      }
    },
    async getCurrentModerator() {
      try {
        const res = await getModeratorByProvider(this.currentProviderId);
        const { rounds, bo_ws_url, channel, merchant_arena, total_rounds, provider_info } = res.data;
        this.rounds = rounds;
        this.gameType = this.tempGameType;
        this.ws_url = bo_ws_url;
        this.channel = channel;
        this.arenas = merchant_arena;
        this.total = total_rounds ? total_rounds : 0;
        this.initDataCount = rounds.length;
        this.setInitRoundAndInfo();
        this.currentIcpVideo = provider_info.icp_video_url;
        this.currentVideo = provider_info.video_url;

        const ws_url = `${bo_ws_url}?${storage.getItem(AUTH_TOKEN)}`
        if(!this.cableActive){
          this.$cable.connection.connect(ws_url);
        }
        this.$cable.subscribe({ channel: channel, sub_ch: this.currentProviderId, connectionUrl: ws_url });
      }catch(err){
        this.$message.error(err.response.data.message);
      }
    },
    async getCurrentTodayRound() {
      this.moreDataLoading = true;
      try{
        const count = this.initDataCount + this.moreDataCount * (this.page - 1);
        const result = count > this.total ? this.total : count;
        const res = await getTodayRoundByProvider({provider_id: this.currentProviderId, result});
        this.rounds = res.data.rounds;
        if(this.reloadLoading){
          this.setInitRoundAndInfo();
        }
      }catch(err){
        this.$message.error(err.response.data.message);
      }finally{
        this.moreDataLoading = false;
      }
    },
    async updateVideoUrl(url){
      try {
        const form = { id: this.currentProviderId, url};
        const res = await updateProviderUrl(form);
        if(res){
          this.$message.success(this.$t('global.message.success_to_edit', {value: this.$t('global.common.video_url')}));
          this.currentVideo = url;
          this.videoUrlType = 'video';
        }
      }catch(err){
        this.$message.error(this.$t('global.message.failed_to_update', {value: err.response.data.message}));
      }
    },
    async updateIcpVideoUrl(icpUrl){
      try {
        const form = { id: this.currentProviderId, icpUrl: icpUrl };
        const res = await updateProviderUrl(form);
        if(res){
          this.$message.success(this.$t('global.message.success_to_edit', {value: this.$t('global.common.icp_video_url')}));
          this.currentIcpVideo = icpUrl;
          this.videoUrlType = 'icp';
        }
      }catch(err){
        this.$message.error(this.$t('global.message.failed_to_update', {value: err.response.data.message}));
      }
    },
    setInitRoundAndInfo(){
      if(this.rounds.length !== 0){
        this.initDataCount = this.rounds.length;

        const filterRound = this.rounds.filter((item, index, array)=>{
          const maxIndex = array.length > 15 ? 15 : array.length;
          return index < maxIndex;
        })

        const mainArena = filterRound.find(roundItem => {
          return roundItem.main_arena;
        });

        const setNowFight = mainArena ? mainArena : this.rounds[0];

        this.currentArenaInfo = this.arenas.find(arenaItem=>arenaItem.id === setNowFight.merchant_provider_id);
        this.now_fight = setNowFight;

        this.currentTableIndex = setNowFight.round_id;

      }
    },
    loadMoreData() {
      if(this.disableLoadMore) return;
        setTimeout(async ()=>{
          this.page += 1;
          await this.getCurrentTodayRound();
        },1000);
    },
    clearCurrentArenaInfo(){
      this.current_arena_index = null;
    },
    changeCurrentArenaInfo(index){
      this.current_arena_index = index;
      const currentArenaInfo = this.currentArenaInfo.arena_info[index];
      this.arena_date = currentArenaInfo.time;
      this.start_fight = currentArenaInfo.fight_no;
      this.road_map_id = currentArenaInfo.road_map_id;
    },
    fightScrolltoBottom() {
      let controlItemWidth = this.$refs.fight_control_items.clientWidth;
      this.$refs.fight_control.scrollTo({top: controlItemWidth, behavior: "smooth"});
    },
    handleScrollBtn() {
      const controlScroll = this.$refs.fight_control.scrollTop;
      const controlHeight = this.$refs.fight_control.clientHeight;
      const controlItemHeight = this.$refs.fight_control_items.clientHeight;
      if ((controlItemHeight - controlHeight) > controlScroll){
        this.scrollBtn = true;
      }else {
        this.scrollBtn = false;
      }
    },
    announceUpdate(announce_input) {
      fightAnnounce({announce: announce_input, merchant_provider_id: this.currentArenaInfo.id})
        .then((data) => {
          this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.announcement')}));
          this.currentArenaInfo.announcement = announce_input;
        })
        .catch((err)=>{
          this.$message.error(this.$t('global.message.failed_to_update', {value: this.$t('global.common.announcement')}));
          console.log(err);
        });
    },
    // onHold(type) {
    //   const self = this;
    //   if(type == 'hold') {
    //     this.$confirm({
    //       title: this.$t('global.action.decision_on_hold') + '?',
    //       okText: this.$t('global.action.yes'),
    //       cancelText: this.$t('global.action.no'),
    //       onOk() {
    //         getDecisionOnHold({hold: 1, merchant_provider_id: self.currentArenaInfo.id})
    //           .then(() => {})
    //           .catch((err)=>{
    //             self.$message.error(err.response.data.message);
    //             console.log(err);
    //           });
    //       },
    //       onCancel() {},
    //     });
    //   }else {
    //     this.$confirm({
    //       title: this.$t('global.action.cancel_on_hold') + '?',
    //       okText: this.$t('global.action.yes'),
    //       cancelText: this.$t('global.action.no'),
    //       onOk() {
    //         getDecisionOnHold({hold: 0, merchant_provider_id: self.currentArenaInfo.id})
    //           .then(() => {})
    //           .catch((err)=>{
    //             self.$message.error(err.response.data.message);
    //             console.log(err);
    //           });
    //       },
    //       onCancel() {},
    //     });
    //   }
    // },
    closeBet(value) {
      const self = this;
      if(value) {
        this.$confirm({
          title: this.$t('global.action.not_allow_bet') + '?',
          okText: this.$t('global.action.yes'),
          cancelText: this.$t('global.action.no'),
          onOk() {
            getCloseBet({close_bet: value, merchant_provider_id: self.currentArenaInfo.id})
              .then(() => {})
              .catch((err)=>{
                self.$message.error(err.response.data.message);
                console.log(err);
              });
          },
          onCancel() {},
        });
      }else {
        this.$confirm({
          title: this.$t('global.action.not_allow_bet') + '?',
          okText: this.$t('global.action.yes'),
          cancelText: this.$t('global.action.no'),
          onOk() {
            getCloseBet({close_bet: value, merchant_provider_id: self.currentArenaInfo.id})
              .then(() => {})
              .catch((err)=>{
                self.$message.error(err.response.data.message);
                console.log(err);
              });
          },
          onCancel() {},
        });
      }
    },
    maskVideo(value){
      const self = this;
      if(value) {
        this.$confirm({
          title: this.$t('global.action.mask_video') + '?',
          okText: this.$t('global.action.yes'),
          cancelText: this.$t('global.action.no'),
          onOk() {
            getMaskVideo({mask_video: value, merchant_provider_id: self.currentArenaInfo.id})
                .then(() => {})
                .catch((err)=>{
                  self.$message.error(err.response.data.message);
                  console.log(err);
                });
          },
          onCancel() {},
        });
      }else {
        this.$confirm({
          title: this.$t('global.action.cancel_mask_video') + '?',
          okText: this.$t('global.action.yes'),
          cancelText: this.$t('global.action.no'),
          onOk() {
            getMaskVideo({mask_video: value, merchant_provider_id: self.currentArenaInfo.id})
                .then(() => {})
                .catch((err)=>{
                  self.$message.error(err.response.data.message);
                  console.log(err);
                });
          },
          onCancel() {},
        });
      }
    },
    monitorVideo(value) { 
      const self = this;
      if (value) {
        this.$confirm({
          title: this.$t("global.action.monitor_video") + "?",
          okText: this.$t("global.action.yes"),
          cancelText: this.$t("global.action.no"),
          onOk() {
            getMonitorVideo({
              merchant_provider_id: self.currentArenaInfo.id,
            }).then((data) => {
              if (data.data.game_link_url) {
                window.open(data.data.game_link_url, "_blank");
              } else {
                self.$message.error(self.$t("global.message.failed_to_monitor"));
              }
            }).catch((err) => {
              self.$message.error(err.response.data.message);
            });
          },
          onCancel() {},
        });
      }
    },
    reloadVideo() {
      if( this.isVideoLoading ){
        return;
      }else{
        this.isVideoLoading = true;
        setTimeout(() => {
          this.isVideoLoading = false;
        },890);
      }
    },
    showUploadModal(imgPath, title, fileSize, recommendedSize) {
      this.uploadModal = true;
      this.uploadImg.type = imgPath;
      this.uploadImg.title = title;
      this.uploadImg.maxSize = fileSize;
      this.uploadImg.recommendedSize = recommendedSize;
    },
    closeUploadModal() {
      this.uploadModal = false;
    },
    clearUploadData(){
      this.uploadImg.type = '';
      this.uploadImg.title = '';
      this.uploadImg.maxSize = 0;
      this.uploadImg.recommendedSize = '';
    },
    setCurrentRoundAndInfo(tableIndex, data){
      if(this.currentTableIndex !== tableIndex){
        this.currentTableIndex = tableIndex;
        let currentInfo;

        if(data.round_id){
          const currentRound = this.rounds.find(roundItem=>roundItem.round_id === data.round_id);
          if(currentRound.round_id !== this.now_fight.round_id){
            this.now_fight = currentRound;
          }
          currentInfo = this.arenas.find(arenaItem=>arenaItem.id === data.merchant_provider_id);
        }else{
          currentInfo = this.arenas.find(arenaItem=>arenaItem.id === data.id);
        }

        if(currentInfo.id !== this.currentArenaInfo.id){
          this.currentArenaInfo = currentInfo;
        }
      }
    },
    updateRoundLocked({ round_locked, winner }){
      this.now_fight.round_locked = round_locked;
      if(winner) {
        this.now_fight.winner = winner;
      }
    },
    changeVideoUrl(type){
      this.videoUrlType = type;
    },
  },
};
</script>
