<template>
  <div class="fight-items-inner">
    <div class="fight-item" data-right-content="fight">{{ data.round_id }}</div>
    <div class="fight-item" data-right-content="fight">{{ data.fight_no }}</div>
    <div class="fight-item" data-right-content="winner" :data-result="GAME_TYPE_SIDE[gameType][data.result]">{{ data.result ? $t(`global.fight_result.${GAME_TYPE_SIDE[gameType][data.result]}`) : '' | formatString }}</div>
    <div class="fight-item" data-right-content="bet_amount">{{ data.bet_amount | formatNumberWithPoint }}</div>
    <div class="fight-item" data-right-content="bet_count">{{ data.bet_count | formatNumber }}</div>
    <div class="fight-item" data-right-content="status">
      <a-tag class="status-item" :data-status="status">{{ $t(`global.common.round_status_list.${status}`) }}</a-tag>
    </div>
    <div class="fight-item" data-right-content="action">
      <fragment v-if="showActionBtn">
          <a-button class="btn control-item-btn" 
                    data-btn="action" 
                    @click="showModal" 
                    v-if="data.opened && data.locked !== 2">
            <!-- <span class="text">{{ $t('ControlPanelPage.lock_earning') }}</span> -->
            <span class="text">{{ $t('CurrentArenaPage.fight_action') }}</span>
            <a-modal v-model="visibleModal" 
                    :centered="true"
                    :closable="false"
                    :footer="null"
                    :bodyStyle="{'padding': '0'}">
              <Fight
                :round_id="data.round_id"
                :fight_no="data.fight_no"
                :open_bet="data.open_bet"
                :opened="data.opened"
                :result="data.result"
                :locked="data.locked"
                :loading="data.loading"
                :ready_to_last_call="data.ready_to_last_call"
                :last_call="data.last_call"
                :double_confirm="data.double_confirm"
                :can_lock="true"
                :canNextStep="canNextStep"
                :disableNextStep="disableNextStep"
                :merchant_provider_id="merchant_provider_id"
                :game_type="gameType"
                :enableDraw="enableDraw"
                @bettingReadyToLastCallClose="bettingReadyToLastCallClose"
                @checkResult="checkResult"
                @reDeclare="reDeclare"
                @lockEarning="lockEarning"
                @confirmBettingStatus="confirmBettingStatus"
              />
            </a-modal>
          </a-button>
          <div v-else>-</div>
      </fragment>
      <span v-else>-</span>
    </div>
    <a-modal :width="350" v-model="showResultModal" class="fight-result-modal" :data-result="GAME_TYPE_SIDE[gameType][check_result]">
      <template slot="footer">
        <a-button key="back" @click="closeModal">{{ $t('global.action.no') }}</a-button>
        <a-button key="submit" type="primary" @click="sentResult(check_fight, check_result)">{{ $t('global.action.yes') }}</a-button>
      </template>
        <div class="fight-result-img">
          <FightResultImg 
            :result="check_result" 
            :game_type="gameType" />
        </div>
      <div class="fight-result-title">{{ $t('global.common.declare_fight_winner') }}</div>
      <div class="fight-result-winner" :data-result="GAME_TYPE_SIDE[gameType][check_result]">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[gameType][check_result]}`) }}</div>
    </a-modal>
  </div>
</template>
<script>
import Fight from '@/../src/components/arena/fight_item';
import FightResultImg from '@/../src/components/arena/fight_result_img';
import { GAME_TYPE_SIDE } from '@/../tools/config';

export default {
  components:{
    Fight,
    FightResultImg,
  },
  props: {
    data: Object,
    canNextStep: Boolean,
    disableNextStep: Function,
    result_modal: Boolean,
    check_fight: null,
    check_result: null,
    sentResult: Function,
    lockEarning: Function,
    bettingReadyToLastCallClose: Function,
    checkResult: Function,
    reDeclare: Function,
    confirmBettingStatus: Function,
    merchant_provider_id: [String, Number],
    editable: Boolean,
    totalBetAmount: [String, Number],
    totalBetCount: [String, Number],
    currentDate: String,
    gameType: String,
    closeModal: Function,
    enableDraw: Boolean,
  },
  data() {
    return {
      visibleModal: false,
      GAME_TYPE_SIDE
    };
  },
  methods:{
    showModal(){
      this.visibleModal = true;
    },
  },
  computed: {
    status() {
      if(this.data.open_bet) {
        return 'open';
      }else {
        if (this.data.opened) {
          if (this.data.locked) {
            return 'locked';
          }else {
            return 'close';
          }
        }
        return 'idle';
      }
    },
    showActionBtn() {
      const created_at = this.moment(this.data.created_at).format('YYYY-MM-DD');
      const yesterday = this.moment(this.currentDate).subtract(1, 'days').format('YYYY-MM-DD');
      return created_at === yesterday;
    },
    showResultModal(){
      return this.result_modal && this.check_fight === this.data.round_id
    },
  },
};
</script>
